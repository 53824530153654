import { NewMakerCallConditionPopupF } from '../../NewMaker/CallConditionPopup/F/Function/module/NewMakerCallConditionPopupF.js';
import { getBaseProductInMaker, getItsBpf } from './getSth.js';
import { find, go, selEq } from 'fxjs/es';
import { $hide, $qs, $setHTML, $show } from 'fxdom/es';
import { BPF_WANRINGS } from '../S/constant.js';
export async function showSpecialMessage(fcanvas) {
  const warning_type = getSpecialMessageType(fcanvas);
  if (!warning_type) return;
  const warning = find(selEq('type', warning_type))(BPF_WANRINGS);
  if (!warning) return;
  const base_product = getBaseProductInMaker();
  const is_condition_mapping_bp = NewMakerCallConditionPopupF.is_mapping_confirm_popup();
  if (![7444, 7445, 7446, 7365, 7366, 7367, 7368].includes(base_product?.id) && is_condition_mapping_bp)
    return;

  const el = $qs('#bpf_special_message');
  if (el) {
    go(el, $setHTML(warning.text()), $show);
  }
}
export function hideSpecialMessage() {
  const el = $qs('#bpf_special_message');
  if (el) $hide(el);
}

export function getSpecialMessageType(fcanvas) {
  const bpf = getItsBpf(fcanvas);
  return bpf.warning_type;
}
