import { find, go } from 'fxjs/es';
import { GlobalPortOneConstantS } from '../Constant/module/GlobalPortOneConstantS.js';
import zenginCode from 'zengin-code';

export const getPaymentMethodInfo = (method_key) => {
  return go(
    GlobalPortOneConstantS.ALL_PAYMENT_METHODS,
    find((payment_method) => payment_method.method_key === method_key),
  );
};

export const getKomojuRefundInfo = ({ bank_code, branch_code, bank_account, bank_user_name }) => {
  if (!bank_code) throw new Error('은행 정보가 없습니다.');
  if (!branch_code) throw new Error('은행 브랜치 정보가 없습니다.');
  const bank = zenginCode[bank_code];

  const branch = bank.branches[branch_code];
  return {
    komoju_refund_payload: {
      account_type: 'normal',
      include_payment_method_fee: true,
      customer_name: bank_user_name,
      bank_name: bank.name,
      bank_code,
      branch_name: branch.name,
      branch_number: branch_code,
      account_number: Number(bank_account),
    },
  };
};

function isInternalError(input) {
  const regex = /^1\d{3}(\s*\|.*)?$/;
  return regex.test(input);
}
export const makeErrorMessage = (status_channel_reason) => {
  if (isInternalError(status_channel_reason)) {
    /* 한국 카드이거나 사용할수 없는 카드 */
    return T('global_port_one::error_01');
  }
  if (status_channel_reason.includes('3016')) {
    /* Transaction was declined due to failed 3DS check 도난 카드??? */
    /* 사용할 수 없는 카드 이거나 지원하지 않는 카드입니다. */
    return T('global_port_one::error_02');
  }
  if (status_channel_reason.includes('3101')) {
    /* Please verify the credit card number and retry the transaction. */
    return T('global_port_one::error_03');
  }
  if (status_channel_reason.includes('3013')) {
    /* Transaction was declined due to issues with the card. */
    /* 해당 발행사의 카드는 현재 지원하고 있지 않습니다. */
    return T('global_port_one::error_04');
  }
  if (status_channel_reason.includes('3102')) {
    /* You submitted an expired credit card number. */
    return T('global_port_one::error_05');
  }
  return status_channel_reason;
};
