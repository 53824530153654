import {
  CREATOR_CHECKOUT_FRAME,
  CREATOR_CHECKOUT_PAGE,
  CREATOR_CHECKOUT_TAB,
} from '../../../../../modules/Creator/Checkout/S/constant.js';

import {
  MPAY_CHECKOUT_FRAME,
  MPAY_CHECKOUT_PAGE,
  MPAY_CHECKOUT_TAB,
} from '../../../../../modules/MPay/Checkout/S/constant.js';

import { MPayLegacyCheckoutF } from '../../../../../modules/MPayLegacy/Checkout/F/Function/module/MPayLegacyCheckoutF.js';
import './mp.checkout.js';
import { OMPCoreUtilF } from '../../../../../modules/OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { ConfigSentryF } from '../../../../../modules/Config/Sentry/F/Function/module/ConfigSentryF.js';
import { GlobalPortOneS } from '../../../../../modules/GlobalPortOne/S/Function/module/GlobalPortOneS.js';

const [CHECKOUT_FRAME, CHECKOUT_PAGE, CHECKOUT_TAB] =
  collabo_type == 'creator'
    ? [CREATOR_CHECKOUT_FRAME, CREATOR_CHECKOUT_PAGE, CREATOR_CHECKOUT_TAB]
    : [MPAY_CHECKOUT_FRAME, MPAY_CHECKOUT_PAGE, MPAY_CHECKOUT_TAB];

!(async function () {
  $.frame.defn_frame({
    frame_name: CHECKOUT_FRAME,
    page_name: CHECKOUT_PAGE,
    ...MPayLegacyCheckoutF.frame_opt,
  });

  $.frame.defn_page({
    page_name: CHECKOUT_PAGE,
    tabs: [{ tab_name: CHECKOUT_TAB }],
    ...MPayLegacyCheckoutF.page_opt,
  });
  try {
    if (!window.location.pathname?.includes('/checkout')) return;
    if (G.collabo_type === '') {
      const url = new URL(window.location);
      if (
        url.searchParams.get('redirect') === 'true' ||
        url.searchParams.get('message') ||
        url.searchParams.get('status_channel_reason') ||
        url.searchParams.get('status_reason')
      ) {
        const msg =
          (await (url.searchParams.get('status_channel_reason') &&
            GlobalPortOneS.makeErrorMessage(url.searchParams.get('status_channel_reason')))) ||
          url.searchParams.get('status_reason') ||
          url.searchParams.get('message') ||
          url.searchParams.get('msg');
        // if (url.searchParams.get('merchant_order_ref')) {
        //   const data = await $.get('/@api/global_port_one/transaction', {
        //     order_ref: url.searchParams.get('order_ref'),
        //   });
        // }
        OMPCoreUtilF.alert({
          content: msg ? msg.replace(/__br__/g, '<br>') : TT('error::common::error_01'),
          msg_width: 355,
        });
        url.searchParams.delete('redirect');
        url.searchParams.delete('msg');
        url.searchParams.delete('message');
        url.searchParams.delete('status_channel_reason');
        url.searchParams.delete('status_reason');
        $.frame.extend_state(
          void 0,
          null,
          `${url.pathname.toString()}?${url.searchParams.toString()}`,
          'replace',
        );
      }
    }
  } catch (e) {
    ConfigSentryF.error(e);
  }
})({});
