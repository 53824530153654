import axios from 'axios';
import {
  $addClass,
  $attr,
  $closest,
  $data,
  $el,
  $find,
  $findAll,
  $hasClass,
  $hide,
  $qs,
  $qsa,
  $removeAttr,
  $removeClass,
  $replaceWith,
  $setAttr,
  $setText,
  $setVal,
  $toggleClass,
  $trigger,
  $val,
} from 'fxdom/es';

import {
  compact,
  deepFlat,
  delay,
  each,
  every,
  filter,
  filterL,
  find,
  flatMap,
  go,
  head,
  join,
  map,
  not,
  pluck,
  reject,
  sel,
  some,
  sortBy,
  sumBy,
  take,
  tap,
} from 'fxjs/es';
import UAParser from 'ua-parser-js';
import { GoodsTypeS } from '../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { LinePrivacyTmplS } from '../../../../Line/Privacy/S/Tmpl/module/LinePrivacyTmplS.js';
import { LineTerms2MuiF } from '../../../../Line/Terms2/F/Mui/module/LineTerms2MuiF.js';
import { LineTerms2TmplS } from '../../../../Line/Terms2/S/Tmpl/module/LineTerms2TmplS.js';
import { MarppleShinhanConstantS } from '../../../../Marpple/Shinhan/S/Constant/module/MarppleShinhanConstantS.js';
import { ModuleInfoF } from '../../../../ModuleInfo/F/Function/module/ModuleInfoF.js';
import { loadIamport } from '../../../../MPay/Checkout/F/fs.js';
import { MShopUtilF } from '../../../../MShop/Util/F/Function/module/MShopUtilF.js';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { PBErrorConstantS } from '../../../../PB/Error/S/Constant/module/PBErrorConstantS.js';
import { OMPCoreUtilS } from '../../../../OMP/Core/Util/S/Function/module/OMPCoreUtilS.js';
import { PriceS } from '../../../../Price/S/Function/module/PriceS.js';
import { PrivacyMainMuiF } from '../../../../Privacy/Main/F/Mui/module/PrivacyMainMuiF.js';
import { PrivacyPopUpTmplS } from '../../../../Privacy/PopUp/S/Tmpl/module/PrivacyPopUpTmplS.js';
import { computeShippingCostF } from '../../../../Shipping/Cost/F/fs.js';
import { TermsConditionsMuiF } from '../../../../Terms/Conditions/F/Mui/module/TermsConditionsMuiF.js';
import { TermsConditionsTmplS } from '../../../../Terms/Conditions/S/Tmpl/module/TermsConditionsTmplS.js';
import { UserJwtF } from '../../../../User/Jwt/F/Function/module/UserJwtF.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { MPayLegacyCartF } from '../../../Cart/F/Function/module/MPayLegacyCartF.js';
import { MarppleCustomDetailConstantS } from '../../../../Marpple/CustomDetail/S/Constant/module/MarppleCustomDetailConstantS.js';
import { OMPAuthChangeEmailF } from '../../../../OMP/Auth/ChangeEmail/F/Function/module/OMPAuthChangeEmailF.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { OMPCoreUtilF } from '../../../../OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { IamportS } from '../../../../Iamport/S/Function/module/IamportS.js';
import { OMPCoreUserProductS } from '../../../../OMP/Core/UserProduct/S/Function/module/OMPCoreUserProductS.js';
import { OMPCheckoutTmplS } from '../../../../OMP/Checkout/S/Tmpl/module/OMPCheckoutTmplS.js';
import { ShippingF } from '../../../../Shipping/F/Function/module/ShippingF.js';
import { ShippingCostS } from '../../../../Shipping/Cost/S/Function/module/ShippingCostS.js';
import $dataStr from 'fxdom/es/dataStr.js';
import { SamsungS } from '../../../../Samsung/S/Function/module/SamsungS.js';
import { ShippingConstantS } from '../../../../Shipping/S/Constant/module/ShippingConstantS.js';
import { ShippingS } from '../../../../Shipping/S/Function/module/ShippingS.js';
import { ConfigSentryF } from '../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { GlobalPortOneF } from '../../../../GlobalPortOne/F/Function/module/GlobalPortOneF.js';
import { makeApiUrl } from '../../../../Util/S/Function/util.js';
import { PaymentLogConstantS } from '../../../../PaymentLog/S/Constant/module/PaymentLogConstantS.js';
import { GlobalPortOneConstantS } from '../../../../GlobalPortOne/S/Constant/module/GlobalPortOneConstantS.js';
import { OMPPromptModalF } from '../../../../OMP/PromptModal/F/Function/module/OMPPromptModalF.js';

const fbSessionLogin = (accessToken) =>
  go(
    axios.post('/@api/mp/fb/login', {
      accessToken,
    }),
    async function ({ data: { user, user_dormant_url } }) {
      if (user_dormant_url && T.lang === 'kr') {
        const dormant_go = await $.confirm(
          '이전에 마플 계정이 휴면 상태입니다. 휴면을 해제 후 로그인 하시겠습니까?',
        );
        if (dormant_go) {
          if (MShopUtilF.isCreatorApp()) {
            MShopUtilF.postMessage({
              screen: {
                type: 'empty',
                uri: `${T.lang}/${user.user_dormant_url}?url=${location.pathname}${location.search}`,
              },
            });
            return;
          }
          location.href = `/${T.lang}${user.user_dormant_url}?url=${location.pathname}${location.search}`;
          return;
        }
      }
      if (!MShopUtilF.isApp()) return location.reload();
      $.don_loader_start();
      await UserJwtF.loginMessage(user);
      await delay(600)();
      await MShopUtilF.postMessage({ resetOrderProcess: true });
    },
  );

export const frame_opt = {
  appended: () => {
    loadIamport();
    const appListener = function (e) {
      try {
        if (!MShopUtilF.isCreatorApp()) return;
        const { facebookLogin, orderCancel } = JSON.parse(e.data);
        if (facebookLogin) {
          fbSessionLogin(facebookLogin.accessToken);
        } else if (orderCancel && box.sel('sign_up')) {
          MShopUtilF.postMessage({ resetOrderProcess: true });
        }
      } catch (err) {}
    };
    window.addEventListener('message', appListener);
    document.addEventListener('message', appListener);
  },
  appending: function () {
    _p.each($('.up_c_item'), function (e) {
      G.mp.maker.draw_product_face2(e, box.sel(e)._.product_color);
    });
  },
  showing: function (tab_el) {
    // shinhan event code
    try {
      go(void 0, () => {
        const is_shinhan = !!go(
          box.sel('user_products'),
          find((up) => {
            return sel('_.product_color.product_faces2.ev.coupon', up) == MarppleShinhanConstantS.info.coupon;
          }),
        );
        if (is_shinhan) {
          const user_id = box.sel('is_user->id');
          if (
            go(
              MarppleCustomDetailConstantS.shinhan_issue.prjs,
              some((prj) => prj.user_id === user_id),
            )
          ) {
            $setVal(MarppleCustomDetailConstantS.shinhan_issue.coupon, $find('input.promotion_code', tab_el));
          } else {
            $setVal(MarppleShinhanConstantS.info.coupon, $find('input.promotion_code', tab_el));
          }
          $trigger('click', $find('button.apply', tab_el));
          go($qsa('.coupon_point'), each($hide));
        }
      });
    } catch (e) {}
    // end shinhan event code
  },
  showed: function () {
    const user = box.sel('___updated_user');
    if (!user) return;
    if (G.collabo_type === '') {
      $.text_to(user.email, $1('.email_text span.value'));
    } else {
      $.text_to(user.email, $1('.email_text'));
    }
    box.unset('___updated_user');
  },
};

function roundCouponPrice(coupon_price) {
  return T.lang == 'kr'
    ? PriceS.round_down10(coupon_price)
    : T.lang == 'jp'
    ? parseInt(coupon_price)
    : coupon_price;
}

function toggle_section(type) {
  return __(_p.v('currentTarget'), $.closest('.section.' + type), $.toggle_class('show hide'));
}

async function shippingCompanyChanged(e) {
  const shipping_company = $val($qs('select[name="express"] option:checked'));
  $setAttr(['data-current', shipping_company], $qs('.section.shipping_companies'));
  resetCouponInCheckout();
  await changeShippingType(e);
}

async function setAdditionalShippingPrice(postcode) {
  await go($.get('/@api/shipping_cost/is_far', { postcode }), (is_far) => {
    box.set('projection_price_info->additional_shipping_price', is_far ? 3000 : 0);
  });
}

function resetCouponInCheckout() {
  const coupon_cancel_el = $qs('.coupon_point button.apply[applied="true"]');
  coupon_cancel_el && $trigger('click', coupon_cancel_el);
}

async function changeShippingType(e) {
  try {
    $.don_loader_start();
    const country_id = $.attr($1('#shipping_info select[name="country"] option:checked'), '_id');
    const shipping_type = T.lang == 'kr' ? $.val($1('.shipping_type input[name="type"]:checked')) : 'parcel';
    const projection_price_info = box.sel('projection_price_info');
    const user_products = box.sel('user_products');

    const upcs_ids = OMPCoreUserProductS.getUpcsIdsFromUserProducts({ user_products });
    const selected_shipping_company_id = UtilF.getSelectBoxOptionValue('select[name="express"]');
    const selected_shipping_company_service_id = UtilF.getSelectBoxOptionValue(
      'select[name="express_service"]',
    );

    const shipping_cost = await computeShippingCostF({
      collabo_type: G.collabo_type,
      shipping_type,
      order_amount: projection_price_info.product_price,
      ordered_at: moment().format('YYYY-MM-DD'),
      lang: T.lang,
      country_id,
      shipping_company_id: selected_shipping_company_id,
      shipping_company_service_id: selected_shipping_company_service_id,
      upcs_ids,
      is_samsung: SamsungS.hasSamsungBp(box.sel('user_products')),
    });

    const {
      is_ok,
      delivery_fee,
      reason: delivery_fee_error_reason,
    } = ShippingCostS.parseShippingCost(shipping_cost);

    const is_delivery_fee_error = !is_ok;
    is_delivery_fee_error && ShippingF.alertShippingCalculationError({ reason: delivery_fee_error_reason });

    projection_price_info.shipping_price = delivery_fee;

    if (T.lang !== 'kr') {
      const checkout_body_el = $qs('.checkout_body');
      checkout_body_el &&
        $setAttr(
          {
            is_hide_shipping_cost: is_delivery_fee_error,
            'data-is_delivery_fee_error': is_delivery_fee_error,
            'data-fx-json': $dataStr({ delivery_fee_error_reason }),
          },
          checkout_body_el,
        );

      const delivery_fee_err_el = $qs('.delivery_fee_error');
      delivery_fee_err_el && $setText(delivery_fee_error_reason.message, delivery_fee_err_el);
    }

    if (G.collabo_type === '' && T.lang === 'kr') {
      if (shipping_type === 'parcel') {
        const postcode = go($qs('input[name="postcode"]'), $val);
        if (postcode) await setAdditionalShippingPrice(postcode);
      } else {
        box.set('projection_price_info->additional_shipping_price', 0);
      }
    }
    _p.contains(['parcel', 'quick'], shipping_type)
      ? $.show($1('#shipping_info'))
      : $.hide($1('#shipping_info'));

    const coupon_price = box.sel('projection_price_info->coupon_price');
    const point_price = box.sel('projection_price_info->point_price');
    const original_coupon_price = box.sel('projection_price_info->original_coupon_price');
    box.set(
      'projection_price_info->coupon_price',
      roundCouponPrice(-Math.min(get_amount() - coupon_price - point_price, Math.abs(original_coupon_price))),
    );

    const input = _p.go(e.delegateTarget, $.find1('input.point'));
    if (box.sel('projection_price_info->original_point_price') < 0)
      reset_point_price(input, best_point_price(-box.sel('projection_price_info->original_point_price')));

    reset_price_summary();
    $.don_loader_end();

    return !is_delivery_fee_error;
  } catch (err) {
    $.don_loader_end();
    $.alert(UtilF.getErrMsg(err));
    return false;
  }
}

function get_amount() {
  const projection_price_info = box.sel('projection_price_info');
  return PriceS.add(
    projection_price_info.product_price,
    projection_price_info.shipping_price,
    projection_price_info.coupon_price,
    projection_price_info.point_price,
    projection_price_info.additional_shipping_price,
  );
}

function get_only_marpple_amount() {
  const projection_price_info = box.sel('projection_price_info');
  return PriceS.add(
    projection_price_info.product_price,
    projection_price_info.shipping_price,
    projection_price_info.coupon_price,
    projection_price_info.point_price,
    projection_price_info.additional_shipping_price,
  );
}

function simplize_ups(user_products) {
  return _map(user_products, function (up) {
    up = _p.im.unset(up, '_->product_color');
    up._.up_cs = _map(up._.up_cs, function (up_c) {
      return _p.im.unset(up_c, '_->product_color');
    });
    return up;
  });
}

function best_point_price(point_price) {
  const projection_price_info = box.sel('projection_price_info');

  point_price = Math.min(
    Math.abs(parseInt(point_price, 10)),
    box.sel('user->point'),
    PriceS.add(
      projection_price_info.product_price,
      projection_price_info.shipping_price,
      projection_price_info.additional_shipping_price,
      projection_price_info.coupon_price,
    ),
  );

  return !_p.is_number(point_price) || isNaN(point_price) ? 0 : point_price;
}

function reset_point_price(input, point_price) {
  $.val(input, point_price);
  box.set('projection_price_info->point_price', -point_price);
  reset_price_summary();
}

function reset_price_summary() {
  const projection_price_info = box.sel('projection_price_info');
  const amount = get_amount();

  $[amount === 0 ? 'hide' : 'show']($1('#body .payment_type_container'));

  _p.each($('#body .mp_checkout .price_summary'), function (parent) {
    reset_coupon_point(parent, 'point_price');
    reset_coupon_point(parent, 'coupon_price');

    _p.go(
      $qsa('.shipping_company_info tr.price td'),
      each($.text(PriceS.pricify(projection_price_info.shipping_price))),
    );

    _p.go(
      parent,
      $.find('.shipping_price .info'),
      $.text(PriceS.pricify(projection_price_info.shipping_price)),
    );
    const additional_shipping_price_els = go(parent, $findAll('.additional_shipping_price'));
    if (additional_shipping_price_els?.length && G.collabo_type === '') {
      go(
        additional_shipping_price_els,
        each((additional_shipping_price_el) => {
          additional_shipping_price_el.dataset.has_additional_shipping_price =
            projection_price_info.additional_shipping_price > 0;
        }),
      );
      _p.go(
        parent,
        $.find('.additional_shipping_price .info'),
        $.text(PriceS.pricify(projection_price_info.additional_shipping_price)),
      );
    }

    _p.go(parent, $.find1('.total_price .info'), $.text(PriceS.pricify(amount)));
  });

  _p.go($qs('.right_bar .header_summary'), $.text(PriceS.pricify(amount)));

  function reset_coupon_point(parent, key, plus_price = 0) {
    const price = box.sel('projection_price_info->' + key);
    _p.go(
      parent,
      $.find1('.price_detail .detail.' + key),
      _p.tap($.find1('.info'), $.text(PriceS.pricify(PriceS.add(price, plus_price)))),
      $.css({ display: price + plus_price < 0 ? 'flex' : 'none' }),
    );
  }
}

function expressPromo() {
  try {
    const promotion_code_el = $qs('.promotion_code');
    const point_el = $qs('.point');

    const promos = go(
      [promotion_code_el ? $val(promotion_code_el) : null, point_el ? parseInt($val(point_el)) : null],
      compact,
    );
    $qs('html').dataset.applied_promo_length = promos?.length;
  } catch (e) {
    console.error(e);
  }
}

const ALL_POSSIBLE_TEXT_REG =
  /[\sa-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣ぁ-ゔァ-ヴー々〆〤一-龥。\u0400-\u04FF[{}[\]/?.ᆢㆍᆞ,;:|)*~_`!^\-+<>@#$%&\\=('"ABCČĆDĐEFGHIJKLMNOPQRSŠTUVWXYZŽabcčćdđefghijklmnopqrsštuvwxyzžกขคฆงจฉชซฌญฎฏฐฑฒณดตถทธนบปผฝพฟภมยรลวศษสหฬอฮะาเใไโฤๅĂÂÊÔƠƯăâêôơư1234567890๐๑๒๓๔๕๖๗๘๙‘’“”÷×®©€£¥¢ๆฯ฿АБВГҐДЂЕЁЄЖЗЅИІЇЙЈКЛЉМНЊОПРСТЋУЎФХЦЧЏШЩЪЫЬЭЮЯабвгґдђеёєжзѕиіїйјклљмнњопрстћуўфхцчџшщъыьэюяΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩαβγδεζηθικλμνξοπρστυφχψωάΆέΈΉίϊΐΊόΌύΰϋΎΫΏ]/g;
const not_good_spaces =
  // eslint-disable-next-line no-control-regex,no-misleading-character-class
  /[\v\f\b\u200B\u200C\u200D\u200E\u200F\u000b\u2028\u2029\uFEFF\u202D\xA0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u3000]/g;

export const validateEnglishAddress = (address) => {
  if (typeof address !== 'string') return false;
  const regex =
    /([a-zA-Z0-9\s`!@#$%^&*()-_+=[\];:{}'"|\\,.<>/?ëABCČĆDĐEFGHIJKLMNOPQRSŠTUVWXYZŽabcčćdđefghijklmnopqrsštuvwxyzžกขคฆงจฉชซฌญฎฏฐฑฒณดตถทธนบปผฝพฟภมยรลวศษสหฬอฮะาเใไโฤๅĂÂÊÔƠƯăâêôơư1234567890๐๑๒๓๔๕๖๗๘๙‘’“”÷×®©€£¥¢ๆฯ฿АБВГҐДЂЕЁЄЖЗЅИІЇЙЈКЛЉМНЊОПРСТЋУЎФХЦЧЏШЩЪЫЬЭЮЯабвгґдђеёєжзѕиіїйјклљмнњопрстћуўфхцчџшщъыьэюяΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩαβγδεζηθικλμνξοπρστυφχψωάΆέΈΉίϊΐΊόΌύΰϋΎΫΏ])+/;
  const res = regex.exec(address);

  return Boolean(res && res[0] === address);
};

export const getOnlyPossibleText = (text) => {
  if (!text) return '';
  const _text = go(
    text.replace(not_good_spaces, ''),
    (text) => text.matchAll(ALL_POSSIBLE_TEXT_REG),
    map((v) => v[0]),
    join(''),
  );
  return _text || '';
};

export const page_opt = {
  showing: function () {
    if (T.lang != 'kr') {
      const checkout_el = $qs('.checkout_body');
      if (checkout_el) {
        const { delivery_fee_error_reason } = $data(checkout_el);
        const { is_delivery_fee_error } = checkout_el.dataset;

        if (is_delivery_fee_error === 'true') {
          ShippingF.alertShippingCalculationError({ reason: delivery_fee_error_reason });
        }
      }
    }
  },
  appended: (page_el) => {
    return go(
      page_el,
      MPayLegacyCartF.storeNameAndProductAppEvent,
      $.on('focusout', '.make_request', (e) => {
        const ct = e.currentTarget;
        const _text = ct.value;
        const text = getOnlyPossibleText(_text);
        if (_text.length !== text.length)
          $.alert(T('maker::Please notice that unsupportive characters are going to be excluded.'));
        $setVal(text, ct);
      }),
      $.on('click', '.go_terms', function (e) {
        e.preventDefault();

        if (G.collabo_type == 'line') {
          MuiF.openFrame(LineTerms2MuiF.frame, (frame, page, [tab]) => {
            frame.el_class = 'terms_modal_style terms_style';
            frame.hide_frame_button_position = 'RIGHT';
            frame.hide_frame_button_type = 'X';
            frame.is_modal = !UtilF.isLegacyMobile();
            tab.template = () =>
              T.lang != 'kr' ? LineTerms2TmplS.en_terms_220307() : LineTerms2TmplS.kr_terms_220307();
          });
        } else {
          MuiF.openFrame(TermsConditionsMuiF.frame, (frame, page, [tab]) => {
            frame.header_height = 0;
            frame.el_class = 'terms_modal_style terms_style';
            frame.header_height = 0;
            frame.hide_frame_button_type = 'X';
            frame.is_modal = !UtilF.isLegacyMobile();
            tab.template = () => TermsConditionsTmplS.termsHtml({ lang: T.lang });
          });
        }
      }),
      $.on('click', '.go_privacy', function (e) {
        e.preventDefault();
        if (G.collabo_type == 'line') {
          MuiF.openFrame(PrivacyMainMuiF.frame, (frame, page, [tab]) => {
            frame.el_class = 'terms_modal_style terms_style';
            frame.hide_frame_button_type = 'X';
            frame.is_modal = !UtilF.isLegacyMobile();
            tab.template = () => {
              if (T.lang != 'kr') return LinePrivacyTmplS.en_privacy_220307();
              return LinePrivacyTmplS.kr_privacy_login();
            };
          });
        } else {
          MuiF.openFrame(PrivacyMainMuiF.frame, (frame, page, [tab]) => {
            frame.el_class = 'terms_modal_style terms_style';
            frame.header_height = 0;
            frame.hide_frame_button_type = 'X';
            frame.is_modal = !MShopUtilF.isMobile();
            tab.template = () =>
              box.sel('is_user->type') == 'TEMP'
                ? PrivacyPopUpTmplS.signUpCheckout()
                : PrivacyPopUpTmplS.loginCheckout();
          });
        }
      }),
      $.on2('change', '.file_company_license', function (e) {
        if ($.valid_files(e.currentTarget)) return $.alert(T(`maker::Accepted File Types: JPEG, PNG, AI`));
        $.don_loader_start();
        $.remove_class($.closest(e.currentTarget, '.input_wrap'), 'done_upload');
        return _p.go(
          $.upload_exif(e.currentTarget, {
            max_resize: 1200,
          }),
          function (file) {
            $.add_class($.closest(e.currentTarget, '.input_wrap'), 'done_upload');
            $.don_loader_end();
            if (file == 'cancel') return;
            return _p.go(e.currentTarget, $.siblings('input.hidden'), _p.first, $.val(file.url));
          },
          _p.catch(function (err) {
            $.don_loader_end();
            $.alert(err.msg);
          }),
        );
      }),
      $.on('input', '#shipping_info input', async function (e) {
        if (T.lang !== 'kr') {
          const el = e.target;
          const name = el.name;
          if (['city', 'detail', 'name', 'postcode', 'street', 'state'].includes(name)) {
            const selected_shipping_company_id = $qs('select[name="express"]')?.value;

            const is_jp_allowed =
              selected_shipping_company_id === ShippingConstantS.DEFAULT_SHIPPING_COMPANY_FOR_JP;
            const { has_invalid_characters, cleaned_characters } =
              ShippingS.checkInvalidShippingCharsForOversea(el.value, is_jp_allowed);

            if (has_invalid_characters) {
              await $.alert(T('maker::Please notice that unsupportive characters are going to be excluded.'));
              setTimeout(() => {
                el.value = cleaned_characters;
              }, 250);
            }
          }
        }
      }),
      $.on('change', '.same_as_user', function (e) {
        const is_checked = e.currentTarget.checked;
        const order_name = $.val($1('.orderer_info input[name="orderer_name"]'));
        const order_mobile = $.val($1('.orderer_info input[name="orderer_mobile"]'));
        $.val(
          $1('#shipping_info input[name="name"]'),
          is_checked ? order_name : box.sel('shipping->name') || '',
        );
        $.val(
          $1('#shipping_info input[name="mobile1"]'),
          is_checked ? order_mobile : box.sel('shipping->mobile1') || '',
        );
      }),
      $.on('click', '.postcode_close', function (e) {
        const input_wrap = $.closest(e.currentTarget, '.input_wrap');
        const layer = $.find1(input_wrap, '.postcode_layer');
        $.scroll_top2(window, $.offset(input_wrap).top - 64, 500);
        $.hide(layer);
      }),
      $.on('click', 'button.search_postcode', function (e) {
        // alert('카카오 장애로 인해 정상동작하지 않을 수 있습니다. 직접 입력 부탁드립니다.');
        const input_wrap = $.closest(e.currentTarget, '.input_wrap');
        const layer = $.find1(input_wrap, '.postcode_layer');
        $.show(layer);
        $.scroll_top2(window, $.offset(input_wrap).top - 64, 500);
        new daum.Postcode({
          oncomplete: async function (data) {
            $.don_loader_start();
            try {
              let fullAddr = data.userSelectedType === 'R' ? data.roadAddress : data.jibunAddress;
              let extraAddr = '';
              if (data.userSelectedType === 'R') {
                if (data.bname !== '') extraAddr += data.bname;
                if (data.buildingName !== '')
                  extraAddr += extraAddr !== '' ? ', ' + data.buildingName : data.buildingName;
                fullAddr += extraAddr !== '' ? ' (' + extraAddr + ')' : '';
              }
              $.val($.find1(input_wrap, 'input.postcode'), data.zonecode);
              $.val($.find1(input_wrap, 'input.address'), fullAddr);
              $.val($.find1(input_wrap, 'input.address2'), '').focus();
              $.scroll_top2(window, $.offset(input_wrap).top - 64, 500);
              $.hide(layer);
              // 마플 도서산간 대비할 수 있음
              if (T.lang === 'kr' && G.collabo_type === '') {
                await setAdditionalShippingPrice(data.zonecode);
                reset_price_summary();
              }
              $.don_loader_end();
            } finally {
              $.don_loader_end();
            }
          },
          ...(window.is_ie ? {} : { submitMode: false }),
          width: '100%',
          height: '100%',
        }).embed(layer);
      }),
      $.on('click', '.quick_table', function (e) {
        e.preventDefault();
        $.frame.open({ frame_name: 'mp.quick_shipping_price' });
      }),
      $.on('change', '.agreement .check_item input', function (e) {
        if (!e.currentTarget.checked) {
          each((el) => (el.checked = false), $qsa('.agreement .check_all input'));
        }
      }),
      $.on('change', '.agreement .check_all input', function (e) {
        const check_inputs = _go(
          e.currentTarget,
          $.closest('.agreement'),
          $.find('.mp_input.check_box input'),
        );
        _p.go(e.currentTarget.checked, function (bool) {
          _p.each(check_inputs, function (el) {
            el.checked = bool;
          });
        });
      }),
      $.on('click', '.shipping_type.radio_box_wrapper .radio_box input[type="radio"]', function (e) {
        const section_body = $.closest(e.currentTarget, '.radio_box_wrapper');
        _p.go(e.currentTarget, $.val, function (val) {
          $.hide($.find(section_body, '.radio_target'));
          $.show($.find1(section_body, '.radio_target.' + val));
        });
      }),
      $.on('click', '.receipt_info.radio_box_wrapper .radio_box input[type="radio"]', function (e) {
        const receipt_info_el = $.closest(e.currentTarget, '.receipt_info');
        if (receipt_info_el) {
          const selected = _p.go(e.currentTarget, $.val);
          _p.go(receipt_info_el, $.attr({ receipt_type: selected }));
        }
      }),
      $.on('keydown', 'input.name[name="name"]', function (e) {
        const obj = {
          name: 'marpple dev',
          street: 'Unjung-ro 233beon-gil',
          detail: 'MARPPLE LAB',
          city: 'Seongnam-si',
          state: 'Bundang-gu, Gyeonggi-do',
          postcode: 13477,
          country: 'Korea, Republic of',
          dialing_code: 82,
          mobile_en: 123123123,
        };

        if (e.currentTarget.value == 'test.test') {
          e.currentTarget.value = 'test@test.com';
          $('.section.shipping [name]').forEach(function (e) {
            e.value = obj[e.name];
          });
        }
      }),
      $.on('click', '.section.show .section_header', toggle_section('show')),
      $.on('click', '.section.hide .section_header', toggle_section('hide')),

      $.on(
        'click',
        '.checkbox_wrap',
        __(_p.v('currentTarget'), $.find('.checkbox'), $.toggle_class('normal active')),
      ),

      tap(() => {
        if (typeof pca == 'undefined') return;
        pca &&
          pca.on(
            'load',
            _p.once(function (type, id, control) {
              $.trigger($1('#shipping_info select.country'), 'change');
              control.listen('populate', function (address) {
                $setAttr(['searched', !!address.Id], $qs('#shipping_info'));
              });
            }),
          );
      }),

      $.on('mousedown', '#shipping_info select.country', (e) => {
        if (T.lang === 'jp') {
          e.preventDefault();
          $.alert(
            'このサイトは日本専用です。日本以外への配送をご希望の場合は、ENウェブサイトをご利用ください。',
          );
        }
      }),
      $.on('click', '.accordion button.toggle-btn', ({ currentTarget: ct }) => {
        go(ct, $closest('.accordion'), $toggleClass('expand'));
      }),
      $.on2('change', '#shipping_info select.country', async (e) => {
        const { currentTarget: ct } = e;
        const value = $val(ct);
        const option_country = go(ct, $find(`option[value="${value}"]`));
        const country_code = go(option_country, $attr('code'));
        const selected_country_id = go(option_country, $attr('_id'));
        const can_loqate = go(option_country, $attr('can_loqate'));
        const countries = go($qs('#shipping_info select[name="country"]'), $data);
        const { express, express_services } = go($qs('.section_body.shipping_provider'), $data) || {};

        go(
          $qs('#shipping_info'),
          $setAttr({
            can_loqate,
            searched: false,
            has_shipping: !!country_code,
          }),
          $findAll('.base_address input, .base_address select'),
          each((el) => {
            $setVal('', el);
            if (T.lang === 'en') {
              if ($hasClass('state', el)) {
                const selected_country = countries.find((c) => Number(c.id) === Number(selected_country_id));

                const {
                  _: { country_states = [] },
                } = selected_country ?? { _: {} };

                if (UtilS.isEmpty(country_states)) {
                  $replaceWith(
                    $el(
                      `<input type="text" name="state" class="state" placeholder="${T(
                        'checkout::State or Province',
                      )}" value="" size="15" maxlength="50" />`,
                    ),
                    el,
                  );
                } else {
                  $replaceWith(
                    $el(OMPCheckoutTmplS.makeCountryStatesSelectOptionBoxHtml({ country_states })),
                    el,
                  );
                }
              }
            }
          }),
        );

        const express_select_el = $qs('select.express');
        const express_service_select_el = $qs('select.express_service');

        const { selected_shipping_company_id } = ShippingF.replaceExpressHtml({
          express_el: express_select_el,
          express,
          selected_country_code: country_code,
        });

        express_select_el.value = selected_shipping_company_id;

        ShippingF.replaceExpressServiceHtml({
          express_service_el: express_service_select_el,
          express_services,
          selected_shipping_company_id,
        });

        go(
          $('select.dialing_code option'),
          each($removeAttr('selected')),
          filterL((option) => $attr('code', option) == country_code),
          take(1),
          each((option) => $setAttr(['selected', true], option)),
        );

        await shippingCompanyChanged(e);

        try {
          // 해외 배송 경고 문구 처리
          const warning = (
            await axios.get(
              UtilS.makeApiUrl('/@api/shipping/oversea/warning/:country_id', {
                country_id: selected_country_id,
              }),
            )
          ).data;

          ShippingF.changeShippingWarningTmpl(warning);
        } catch (err) {
          ConfigSentryF.error(err);
        }
      }),
      $.on('mousedown', 'select[name="express"]', () => {
        !isCountrySelected() && $.alert(T('checkout::국가를 먼저 선택해주세요.'));
      }),
      $.on('mousedown', 'select[name="express_service"]', () => {
        !isCountrySelected() && $.alert(T('checkout::국가를 먼저 선택해주세요.'));
      }),
      $.on2('change', 'select[name="express"]', async function (e) {
        const ct = e.currentTarget;
        const { express_services } = go($qs('.section_body.shipping_provider'), $data) || {};

        const express_service_select_el = $qs('select.express_service');
        ShippingF.replaceExpressServiceHtml({
          express_service_el: express_service_select_el,
          express_services,
          selected_shipping_company_id: ct.value,
        });

        await shippingCompanyChanged(e);
      }),
      $.on('change', 'select[name="express_service"]', shippingCompanyChanged),

      $.on2('click', '.user_email .btn', function () {
        return _p.go($.get('/@api/get/user'), async function (data) {
          if (!data.result)
            return $.alert(T('my_info::There is no user information. Please log in again after login.'));
          if (G.collabo_type === '') {
            await OMPAuthChangeEmailF.openChangeEmailFrame({ email: box.sel('user->email') }, (f, user) => {
              if (!user) return;
              box.set('user->email', user.email);
              box.set('user->updated_at', user.updated_at);

              if (!UtilF.isLegacyMobile()) $.text_to(user.email, $1('.email_text span.value'));
              else box.set('___updated_user', user);
            });
          } else {
            $.frame.open(
              {
                frame_name: 'mp.checkout.change_email',
              },
              {
                tabs: [
                  {
                    page_name: 'mp.checkout.change_email',
                    data_func: function () {
                      const $page = $1('.don_page[page_name="mp.checkout.change_email"]');
                      $page.data_update = function (res) {
                        box.set('user->email', res.user.email);
                        box.set('user->updated_at', res.user.updated_at);

                        if (!UtilF.isLegacyMobile()) $.text_to(res.user.email, $1('.email_text span.value'));
                        else box.set('___updated_user', res.user);
                      };

                      return data.user;
                    },
                  },
                ],
              },
            );
          }
        });
      }),

      $.on('click', '.payment_types >.selector button', function (e) {
        if (get_amount() === 0) return;

        _p.go(
          e.delegateTarget,
          $.find1('.payment_types'),
          $.attr({
            type: $.attr(e.currentTarget, 'payment_type'),
          }),
        );
      }),

      $.on('input', '.coupon_point input.point', async function (e) {
        const input = e.currentTarget;
        const point_price = best_point_price($.val(input));
        reset_point_price(input, point_price);
        box.set('projection_price_info->original_point_price', -point_price);
        expressPromo();
      }),

      $.on('click', '.coupon_point .all_point', async function (e) {
        const input = _p.go(e.delegateTarget, $.find1('input.point'));
        const point_price = best_point_price(box.sel('user->point'));
        reset_point_price(input, point_price);
        box.set('projection_price_info->original_point_price', -point_price);
        expressPromo();
      }),

      $.on2('click', '.coupon_point .apply[applied="true"]', async function (e) {
        e.stopPropagation();
        /* 신한 ! */
        const code = go(e.delegateTarget, $find('.promotion_code'), $val);
        if (code == MarppleShinhanConstantS.info.coupon) {
          return $.alert('해당 상품은 현재 쿠폰으로만 구매가 가능합니다.');
        }

        _p.go(
          e.delegateTarget,
          $.find1('.promotion_code'),
          $.val(''),
          $.remove_attr('readonly'),
          _p('focus'),
        );
        _p.go(e.delegateTarget, $.find1('.promotion_description'), $.text(''));
        _p.go(e.currentTarget, $.attr({ applied: false }));
        box.unset('promotion_code');
        box.set('projection_price_info->coupon_price', 0);
        box.set('projection_price_info->original_coupon_price', 0);
        const point_input = _p.go(e.delegateTarget, $.find1('input.point'));
        if (box.sel('projection_price_info->original_point_price') < 0)
          reset_point_price(
            point_input,
            best_point_price(-box.sel('projection_price_info->original_point_price')),
          );
        reset_price_summary();
        expressPromo();
      }),

      $.on2('change', '.shipping_type input[name="type"]', changeShippingType),

      $.on2('keydown', '.coupon_point .promotion_code', function (e) {
        e.keyCode == 13 &&
          $.trigger($.find1(e.delegateTarget, '.coupon_point .apply[applied="false"]'), 'click');
      }),

      $.on('keyup', '.only_number', function (e) {
        $.val(e.currentTarget, $.val(e.currentTarget).replace(/[^0-9]/gi, ''));
        if ($hasClass('phone_number')(e.currentTarget)) {
          const text = $.val(e.currentTarget);
          if (text?.length > 12) $.val(e.currentTarget, text.slice(0, 12));
        } else if ($hasClass('business_number')(e.currentTarget)) {
          const text = $.val(e.currentTarget);
          if (text?.length > 10) $.val(e.currentTarget, text.slice(0, 10));
        }
      }),
      $.on2('click', '.coupon_point .apply[applied="false"]', async function (e) {
        const name = _p.go(e.delegateTarget, $.find1('.promotion_code'), $.val);
        if (!name) return;
        const coupon_restrictions = [];
        const coupon_restriction = find(([coupon]) => name.indexOf(coupon) !== -1, coupon_restrictions);
        if (
          coupon_restriction &&
          !box()
            .user_products.map((up) => up.store_id)
            .includes(coupon_restriction[1])
        ) {
          return $.alert(T('Unfortunately this promo'));
        }
        const HYUNDAI_N_STORE_ID = 10017;
        const HYUNDAI_N_PRODUCT_ID = 6356500;
        if (
          some((pre) => name.indexOf(pre) !== -1, ['NGOODS', 'THANKYOUN']) &&
          box()
            .user_products.map((up) => up.store_id)
            .includes(HYUNDAI_N_STORE_ID) &&
          go(
            box().user_products,
            flatMap((u) => u._.up_cs),
            map(sel('_.product_color._.stores_product.original_product_id')),
            every((id) => id !== HYUNDAI_N_PRODUCT_ID),
          )
        ) {
          alert('N 브랜드 굿즈 패키지 구매시에만 사용 가능합니다.');
          _p.go(e.delegateTarget, $.find1('.promotion_code'), $.val(''));
          return;
        }
        await _p.go($.post('/@api/promotion_code/apply', { name }), async function (res) {
          function fail(msg) {
            return _p.go(
              $.alert(msg),
              _p.c(e.delegateTarget),
              $.find1('.promotion_code'),
              _p('focus'),
              function () {
                box.set('promotion_code', null);
                box.set('projection_price_info->coupon_price', 0);
              },
              reset_price_summary,
            );
          }
          const projection_price_info = box.sel('projection_price_info');
          if (!res.result) {
            return fail(res.msg);
          } else if (res.promotion_code.min_price > projection_price_info.product_price) {
            return fail(
              T('coupon_min_price_error', {
                min_price: PriceS.pricify(res.promotion_code.min_price),
              }),
            );
          } else if (
            res.promotion_code.store_id &&
            !box()
              .user_products.map((up) => up.store_id)
              .every((store_id) => store_id === res.promotion_code.store_id)
          ) {
            return fail(T('Unfortunately this promo'));
          } else {
            let coupon_price;
            let original_coupon_price;
            if (res.promotion_code.is_percent) {
              coupon_price = -PriceS.mult(
                PriceS.add(
                  projection_price_info.product_price,
                  projection_price_info.shipping_price,
                  projection_price_info.additional_shipping_price,
                ),
                res.promotion_code.discount_p / 100,
              );
              original_coupon_price = -PriceS.mult(
                PriceS.add(
                  projection_price_info.product_price,
                  projection_price_info.shipping_price,
                  projection_price_info.additional_shipping_price,
                ),
                res.promotion_code.discount_p / 100,
              );
            } else {
              coupon_price = Math.max(
                -res.promotion_code.discount_p,
                -PriceS.add(
                  projection_price_info.product_price,
                  projection_price_info.shipping_price,
                  projection_price_info.additional_shipping_price,
                ),
              );
              original_coupon_price = -res.promotion_code.discount_p;
            }

            coupon_price = roundCouponPrice(coupon_price);

            if (
              PriceS.add(
                projection_price_info.product_price,
                projection_price_info.shipping_price,
                projection_price_info.additional_shipping_price,
                coupon_price,
              ) < 0
            )
              return fail(T('주문 금액보다 할인 금액이 큰 경우 쿠폰 적용이 불가능합니다.'));

            box.set('promotion_code', res.promotion_code);
            box.set('projection_price_info->coupon_price', coupon_price);
            box.set('projection_price_info->original_coupon_price', original_coupon_price);

            const promo_desc = res.promotion_code.description;

            _p.go(e.delegateTarget, $.find1('.promotion_code'), $.attr({ readonly: 'readonly' }));
            _p.go(e.delegateTarget, $.find1('.promotion_description'), $.text(promo_desc || ''));
            _p.go(e.currentTarget, $.attr({ applied: true }));
            if (G.collabo_type === '') {
              $.alert(`${promo_desc ? `[${promo_desc}]` : ''} ${T('mp_checkout::쿠폰이 적용 됐습니다.')}`);
            }
            const point_input = _p.go(e.delegateTarget, $.find1('input.point'));
            reset_point_price(
              point_input,
              best_point_price(-box.sel('projection_price_info->original_point_price')),
            );
            expressPromo();
          }
        });
      }),

      $.on('input', 'select[name="memo"]', (e) => {
        if (e.currentTarget.value == 'memo2') {
          $.show($1('input[name="memo2"]'));
          const mp_select_wrapper_el = $closest('.mp-select-wrapper', e.currentTarget);
          if (mp_select_wrapper_el) $addClass('memo2-selected', mp_select_wrapper_el);
        } else {
          $.hide($1('input[name="memo2"]'));
          const mp_select_wrapper_el = $closest('.mp-select-wrapper', e.currentTarget);
          if (mp_select_wrapper_el) $removeClass('memo2-selected', mp_select_wrapper_el);
        }
      }),

      $.on2(
        'click',
        '.submit',
        ___(
          // 결제 버튼 클릭
          _p
            .if2(async function (e) {
              const shipping_type = $.val(
                $.find1(e.delegateTarget, '.shipping_type_tab input[name="type"]:checked'),
              );
              return (
                (_p.contains(['parcel', 'quick'], shipping_type) || T.lang != 'kr') &&
                !$qs('[data-all_is_digital_product="true"]') &&
                _p.go.call(
                  this,
                  e.delegateTarget,
                  $.find('[name][required]'),
                  _find(__($.val, _p.not)),
                  function (input) {
                    return (this.input = input);
                  },
                  _p.nnot,
                )
              );
            })(
              _p.$alert(T('Please enter your shipping address.')),
              _p.this,
              _p.val('input'),
              _p.tap(function (input_el) {
                return $.scroll_top2(window, $.offset(input_el).top - 100);
              }),
              _p('focus'),
            )
            .else(async function (e) {
              if (T.lang !== 'kr') {
                const postcode$ = $qs('input.postcode');
                if (
                  UtilF.checkPostCodeEmpty({
                    postcode$,
                    callback: () => {
                      go($.alert(T('Please enter the postal code in the postal code field.')), () => {
                        $.scroll_top2(window, $.offset(postcode$).top - 200);
                        postcode$.focus();
                      });
                    },
                  })
                ) {
                  return;
                }
              }

              const ev_objs = go(
                box.sel('user_products'),
                filter((up) => up._.product_color?.product_faces2?.ev?.event_name),
                map((up) => ({
                  ev: up._.product_color?.product_faces2?.ev,
                  up_c_ss: go(
                    up._.up_cs,
                    flatMap((up_c) => up_c._.up_c_ss),
                  ),
                })),
              );
              const has_maker_event_error =
                ev_objs?.length &&
                (await go(
                  ev_objs,
                  every(async ({ ev }) => {
                    const res = await axios.get('/@api/maker_event/event', {
                      params: {
                        event_name: ev.event_name,
                      },
                    });
                    if (!res.data?.maker_event) {
                      $.alert('이벤트 기간이 아닌 상품이 포함되어 있습니다.<br>삭제해주세요.');
                      return;
                    }
                    return true;
                  }),
                  not,
                ));
              if (has_maker_event_error) {
                $.don_loader_end();
                return;
              }
              const invalid_only_number_el = find(
                (el) => $val(el) !== $val(el).replace(/[^0-9]/g, ''),
                $qsa('.only_number'),
              );
              if (invalid_only_number_el) {
                await $.alert(T('my_info::Only Numbers'));
                invalid_only_number_el.focus();
                $.scroll_top2(window, $.offset(invalid_only_number_el).top - 100);
                return;
              }
              const valid_delivery_fee = await changeShippingType(e);
              if (!valid_delivery_fee) {
                return;
              }
              const projection_price_info = box.sel('projection_price_info');
              const user = box.sel('user');
              const payment_type = _p.go(e.delegateTarget, $.find1('.payment_types'), $.attr('type'));

              const uAParser = new UAParser();
              if (
                !ev_objs?.length &&
                payment_type !== 'naverpay' &&
                T.lang === 'kr' &&
                uAParser.getBrowser().name.toLowerCase() === 'safari' &&
                uAParser.getOS().name.toLowerCase() === 'mac os'
              ) {
                return $.alert(
                  '<h3>macOS 사파리에서는<br>네이버페이만 이용 가능합니다.</h3><p>그 외 결제수단을 사용하길 원하시면<br>크롬을 이용해주시거나<br>모바일에서 결제해 주세요.</p>',
                );
              }

              const up_c_ss = go(
                box.sel('user_products'),
                pluck('_'),
                pluck('up_cs'),
                deepFlat,
                pluck('_'),
                pluck('up_c_ss'),
                deepFlat,
              );

              const is_discontinued_or_not_stock = go(
                up_c_ss,
                some(({ _is_not_stock, _is_discontinued }) => _is_not_stock || _is_discontinued),
              );

              if (is_discontinued_or_not_stock) {
                return $.alert(TT('cart::cart_26'));
              }

              if (T.lang !== 'kr') {
                const express_el = $qs('select.express');
                if (express_el) {
                  if (go(express_el, $val) === '') {
                    return go($.alert(T('Please select the shipping provider.')), () => {
                      express_el.focus();
                    });
                  }
                }
              }

              if (T.lang == 'kr') {
                if (!_p.every($('.agreement .check_list input[type="checkbox"]'), _p.l('$.checked')))
                  return $.alert('모든 약관에 동의하셔야 결제 가능합니다.');
                if (payment_type == 'without_bank') {
                  const payment_type_checked_val = $.val($1('input[name="receipt_type2"]:checked'));
                  if (payment_type_checked_val != 'none') {
                    const el_not_filled = _p.find(
                      $('.receipt_body.' + payment_type_checked_val + ' .receipt_required'),
                      function (input_el) {
                        return $.val(input_el) == '';
                      },
                    );
                    if (el_not_filled) {
                      return _p.go($.alert('영수증 필수 항목을 채워주세요.'), function () {
                        el_not_filled.focus();
                        $.scroll_top2(window, $.offset(el_not_filled).top - 100);
                      });
                    }
                  }
                }

                if (!$.val($1('input[name="orderer_name"]')))
                  return _p.go($.alert('주문자명을 써주세요.'), function () {
                    $.scroll_top2(window, $.offset($1('input[name="orderer_name"]')).top - 100);
                    $1('input[name="orderer_name"]').focus();
                  });

                if (!$.val($1('input[name="orderer_mobile"]')))
                  return _p.go($.alert('연락처를 써주세요.'), function () {
                    $.scroll_top2(window, $.offset($1('input[name="orderer_name"]')).top - 100);
                    $1('input[name="orderer_mobile"]').focus();
                  });

                const pdf_file_expiration = go(
                  box.sel('user_products'),
                  map(({ _: { product_color } }) => {
                    return product_color._.pdf_printing_file;
                  }),
                  reject((pdf_printing_file) => UtilS.isEmpty(pdf_printing_file)),
                  filter(({ expired_at }) => OMPCoreUtilS.isExpired({ expired_at })),
                  (expirations) => expirations.length > 0,
                );

                if (pdf_file_expiration) {
                  return await OMPCoreUtilF.titleAlert({
                    title: OMPCoreUtilF.isMobile() ? `${TT('pdf::expired::11')}` : `${TT('pdf::expired::5')}`,
                    content: OMPCoreUtilF.isMobile()
                      ? `${TT('pdf::expired::12')}`
                      : `${TT('pdf::expired::13')}`,
                    ok: `${TT('pdf::expired::14')}`,
                  });
                }
              } else {
                if (
                  G.collabo_type == 'line' &&
                  !_p.every($('.agreement .check_list input[type="checkbox"]'), _p.l('$.checked'))
                ) {
                  return $.alert('Please agree to tax guide.');
                }
              }

              const headUp = go(
                box.sel('user_products'),
                sortBy((up) => GoodsTypeS.isPbg(up.goods_type_id)),
                head,
              );

              const projection = {
                status: 'before_order',
                orderer_name:
                  $.val($1('input[name="orderer_name"]'))?.trim() ||
                  user.name?.trim() ||
                  $.val($1('input[name="name"]'))?.trim() ||
                  '',
                orderer_email:
                  $.val($1('input[name="orderer_email"]'))?.trim() ||
                  user.email?.trim() ||
                  $.val($1('input.email'))?.trim() ||
                  '',
                orderer_mobile: $.val($1('input[name="orderer_mobile"]')) || user.mobile || '',
                user_id: user.id,
                user_product_id: sel('id', headUp),
                special_request: $1('.make_request').value,
                quantity: projection_price_info.quantity,
              };

              if (box.sel('promotion_code')) {
                projection.promotion_code = box.sel('promotion_code').name.toUpperCase();
                projection.promotion_code_id = box.sel('promotion_code').id;
              }

              // prices
              projection['product_original_price' + _en] = projection_price_info.product_original_price || 0;
              projection['discount_price' + _en] = projection_price_info.discount_price || 0;
              projection['product_price' + _en] = projection_price_info.product_price || 0;
              projection['shipping_price' + _en] = projection_price_info.shipping_price || 0;
              projection['point_price' + _en] = projection_price_info.point_price || 0;
              projection['coupon_price' + _en] = projection_price_info.coupon_price || 0;
              projection['original_coupon_price' + _en] = projection_price_info.original_coupon_price || 0;
              projection['total_price' + _en] = get_only_marpple_amount() || 0;
              projection['amount' + _en] = get_only_marpple_amount() || 0;
              if (T.lang === 'kr' && G.collabo_type === '')
                projection.additional_shipping_price = projection_price_info.additional_shipping_price || 0;

              const payment = {
                pay_method:
                  projection['amount' + _en] == 0
                    ? 'free'
                    : T.lang != 'kr'
                    ? GlobalPortOneConstantS.METHOD_KEYS.includes(payment_type) ||
                      payment_type === 'global_port_one'
                      ? payment_type
                      : 'card'
                    : payment_type,
              };
              payment.type = _p.contains(
                ['without_bank', 'free', 'offline_card', 'offline_cash'],
                payment.pay_method,
              )
                ? 'marpple'
                : T.lang != 'kr'
                ? 'paypal'
                : 'html5_inicis';
              if (GlobalPortOneConstantS.METHOD_KEYS.includes(payment.pay_method)) {
                payment.type = go(
                  GlobalPortOneConstantS.ALL_PAYMENT_METHODS,
                  find(({ method_key }) => method_key === payment.pay_method),
                  sel('pmt_channel'),
                );
              }
              if (payment_type === 'global_port_one') {
                payment.type = 'global_port_one';
              }
              if (payment.pay_method === 'without_bank') {
                /* 무통장 입금 */
                const has_problem = await ModuleInfoF.hasProblem('without_bank');
                if (has_problem) return;
              } else if (payment.type !== 'marpple') {
                /* import */
                const has_problem = await ModuleInfoF.hasProblem('import');
                if (has_problem) return;
              }
              if (payment.pay_method != 'free') {
                if (T.lang != 'kr' && payment_type == 'alipay') {
                  payment.pay_method = 'alipay';
                  payment.type = 'eximbay';
                }

                if (payment_type == 'naverpay') {
                  payment.pay_method = 'naverpay';
                  payment.type = 'naverpay';
                  // if (get_amount() < 100) {
                  //   return $.alert('네이버페이는 결제 금액이 100원 미만일 경우, 결제를 진행할 수 없습니다.');
                  // }
                }

                if (collabo_type == 'line' && payment_type == 'samsung') {
                  payment.type += '.MOIcreline';
                }
              }

              payment['total_price' + _en] = projection['total_price' + _en];
              payment['amount' + _en] = projection['amount' + _en];

              if (payment.pay_method == 'without_bank') {
                const recipt_type2 = G.$form_$to_attrs(
                  $1('.payment_type.without_bank .receipt_type'),
                ).receipt_type2;
                payment.receipt = { type: recipt_type2, status: 'ready' };
                _p.extend(payment.receipt, G.$form_$to_attrs($1('.receipt_body.' + payment.receipt.type)));
                payment.receipt.type =
                  recipt_type2 == 'cash'
                    ? 1
                    : recipt_type2 == 'company'
                    ? 2
                    : recipt_type2 == 'taxBill'
                    ? 3
                    : 0;
                const bank_info_el = _p.go(e.delegateTarget, $.find1('.bank_info'));
                const bank_el = $.find1(bank_info_el, 'select[name="bank"]');
                const bank_infos = $.val(bank_el).split('|');
                payment.bank_name = bank_infos[0];
                payment.bank_number = bank_infos[1];
                const deposit_user_name_el = $.find1(bank_info_el, 'input[name="deposit_user_name"]');
                payment.deposit_user_name = $.val(deposit_user_name_el);
                if (!payment.bank_name)
                  return _p.go($.alert('입금 은행을 선택해 주세요.'), function () {
                    bank_el.focus();
                  });
                if (!payment.deposit_user_name)
                  return _p.go($.alert('입금자명을 입력해 주세요.'), function () {
                    deposit_user_name_el.focus();
                  });
                /*
                 * 현금영수증 정보 필요
                 * 현금영수증 발행을 위한 가맹점 주문번호, 현금영수증 발행 주문명, 현금영수증 발행 금액, 휴대폰번호, 주민등록번호, 사업자등록번호
                 * */
              }

              if (
                T.lang == 'en' &&
                (await go(
                  $qsa('.section.shipping .base_address input'),
                  filterL((input) =>
                    go(input, $val, (str) => str.replace(/ /g, '').match(/guam|puertorico|saipan/i)),
                  ),
                  take(1),
                  each(async (input) => {
                    await $.alert('Shipping to that address is not possible.');
                    setTimeout(() => input.focus(), 100);
                  }),
                  (_) => _.length,
                ))
              ) {
                return;
              }

              const shipping = _p.extend(
                { type: 'parcel' },
                G.$form_$to_attrs($1('.section.shipping')),
                G.$form_$to_attrs($1('.section.shipping_type')),
              );

              if (shipping.memo || shipping.memo2) {
                if (shipping.memo == 'memo2') {
                  shipping.memo = shipping.memo2 || '';
                }
              }
              delete shipping.memo2;

              if (T.lang == 'kr') {
                shipping.mobile1 = shipping.mobile1.replace(/[^0-9]/g, '');
                shipping.mobile2 = shipping.mobile2.replace(/[^0-9]/g, '');
                shipping.shipping_company_id = 'cj';
              } else {
                shipping.mobile_en = shipping.mobile_en.replace(/[^0-9]/g, '');
                shipping.country_id = parseInt(
                  $.attr($1('#shipping_info select[name="country"] option:checked'), '_id'),
                );
                // shipping.shipping_company_id = $val($qs('input[name="shipping_company"]:checked') || {});
                shipping.shipping_company_id = UtilF.getSelectBoxOptionValue('select[name="express"]');
                shipping.shipping_company_service_id = UtilF.getSelectBoxOptionValue(
                  'select[name="express_service"]',
                );
              }

              if (shipping.type == 'combined') {
                projection.projection_id = shipping.projection_id;
                projection.type = 'combined';
                shipping.name = '';
                shipping.mobile1 = '';
                shipping.mobile2 = '';
                shipping.postcode = '';
                shipping.address = '';
                shipping.address2 = '';
                shipping.memo = '';
              } else {
                delete shipping.projection_id;
              }

              // if (G._en === '' && (shipping.type === 'parcel' || shipping.type === 'quick')) {
              //   if (projection.orderer_name?.length < 2 || projection.orderer_name?.length > 20)
              //     return $.alert('주문자 이름은 2글자 이상 또는 20글자 이하만 가능합니다.');
              //   if (shipping.name?.length < 2 || shipping.name?.length > 20)
              //     return $.alert('수령인 이름은 2글자 이상 또는 20글자 이하만 가능합니다.');
              // }

              if (T.lang != 'kr') {
                const selected_shipping_company_id = $qs('select[name="express"]')?.value;
                const is_jp_allowed =
                  selected_shipping_company_id === ShippingConstantS.DEFAULT_SHIPPING_COMPANY_FOR_JP;

                const { has_invalid_character_item } = ShippingF.validationOverseaAddressItemsCharacters(
                  shipping,
                  is_jp_allowed,
                );

                if (has_invalid_character_item) {
                  return $.alert(T('Please enter your address and name in English.'));
                }
              }

              try {
                const error_up_bp_names = go(
                  box.sel('user_products'),
                  /* 아래 up 가 더이상 cart 에 없으면 코드 지우기 */
                  filter((up) => [13867342, 13868530, 13869345, 13869344, 13869343].includes(up.id)),
                  map((up) => up._.product_color._.base_product.name),
                  compact,
                );
                if (error_up_bp_names?.length) {
                  await $.alert(
                    `${error_up_bp_names.join(
                      ', ',
                    )} 제품들을 디자인 확인 후 장바구니에 다시 담아서 주문해주세요.(확인이 됐다면 계속 진행해주세요.)`,
                  );
                }
              } catch (e) {}

              const otc_additional_obj =
                GlobalPortOneConstantS.BANK_TRANSFER_PAY_METHODS.includes(payment.pay_method) &&
                (await OMPPromptModalF.openFrame({
                  title: '決済のために氏名の入力が必要です。',
                  inputs: [
                    {
                      placeholder: '氏',
                      name: 'billing_surname',
                      type: 'text',
                    },
                    {
                      placeholder: '名',
                      name: 'billing_name',
                      type: 'text',
                    },
                  ],
                }));
              if (GlobalPortOneConstantS.BANK_TRANSFER_PAY_METHODS.includes(payment.pay_method)) {
                if (!otc_additional_obj) return;
                if (!otc_additional_obj?.billing_name || !otc_additional_obj?.billing_surname) {
                  $.alert('氏名の入力が必要です。');
                  return;
                }
              }
              return _p.go(
                {
                  projection,
                  shipping,
                  user_products: simplize_ups(box.sel('user_products')),
                  projection_payment: payment,
                },
                (params) => {
                  return $.post_load('/@api/projection/create', params);
                },
                function (payment_obj) {
                  if (location.href.includes('localhost')) {
                    box.set('___payment_obj', payment_obj);
                  }
                  $.don_loader_start();
                  if (payment_type == 'naverpay') {
                    payment_obj.tax_free = 0;
                    // payment_obj.naverV2 = true;
                    if ($1('html[is_mobile="true"]')) {
                      payment_obj.naverPopupMode = false;
                    } else {
                      payment_obj.naverPopupMode = true;
                    }
                    payment_obj.naverProducts = _p.go(
                      box.sel('user_products'),
                      _p.map((up) => up._.up_cs),
                      _p.flatten,
                      _p.map((up_c) => ({
                        categoryType: 'PRODUCT',
                        categoryId: 'GENERAL',
                        uid: up_c.id,
                        name: up_c._.product_color._.base_product.name,
                        count: 1,
                      })),
                      _p.flatten,
                    );
                  }

                  if (T.lang != 'kr' && payment_type == 'alipay') {
                    payment_obj.language = 'zh';
                  }

                  // 결제 준비를 실패 했을때
                  if (payment_obj.is_failed) {
                    if (payment_obj.is_cart) {
                      _p.go($.alert(payment_obj.msg), function () {
                        location.replace(`/${T.lang}/@/cart`);
                      });
                      if ($1('html[is_mobile="false"]')) {
                        $.css($1('#don_alert .content'), { width: 'auto', display: 'inline-block' });
                      }
                      $.don_loader_end();
                      return;
                    } else {
                      const pb_error_message =
                        T.lang === 'kr' && PBErrorConstantS.findError(payment_obj.err_message);
                      return _p.go(
                        pb_error_message
                          ? $.alert(IamportS.rejectImpUid(pb_error_message.text))
                          : OMPCoreUtilF.alert({
                              content: payment_obj.whole_error_message
                                ? payment_obj.whole_error_message
                                : `${T('Payment Error')}<br />${IamportS.rejectImpUid(payment_obj.msg)}`,
                              msg_width: 355,
                            }),
                        function () {
                          $.don_loader_end();
                          location.reload();
                        },
                      );
                    }
                  }
                  const { projection_id } = payment_obj.custom_data;

                  if (payment.type == 'marpple') {
                    location.href = 'receipt/' + projection_id + '?marpple_success=true';
                    return;
                  }
                  const m_redirect_url = location.href.split('/checkout')[0] + '/receipt/' + projection_id;
                  const is_creator_app = MShopUtilF.isCreatorApp();
                  return _p.go(
                    payment_obj,
                    _p(_p.extend, _p, {
                      m_redirect_url: is_creator_app
                        ? m_redirect_url
                        : m_redirect_url.replace('https://marpple.shop', 'https://marpple.marpple.shop'),
                    }),
                    function (payment_obj) {
                      return new Promise(function (resolve) {
                        if (GlobalPortOneConstantS.METHOD_KEYS.includes(payment_obj.pay_method)) {
                          if (OMPCoreUtilF.isMpApp()) {
                            return $.alert('Please use this payment method on the website.');
                          }
                          if (!['jp', 'en'].includes(T.lang)) {
                            $.alert('현재 영문, 일문 서비스만 가능합니다.');
                            return;
                          }
                          const url = new URL(location.href);
                          const up_id = url.searchParams.get('up_id');
                          new GlobalPortOneF.Impt(G.collabo_type).requestPay(
                            {
                              request_type: GlobalPortOneConstantS.REQUEST_TYPE.checkout,
                              otc_additional_obj,
                              method_key: payment_obj.pay_method,
                              pmt_channel: payment_obj.pg,
                              pmt_method: payment_obj.pay_method,
                              merchant_order_id: payment_obj.merchant_uid,
                              amount: payment_obj.amount,
                              currency: payment_obj.currency,
                              billing_name: payment_obj.buyer_name,
                              billing_email: payment_obj.buyer_email,
                              billing_phone: payment_obj.buyer_tel,
                              success_url: `${location.origin}/${T.lang}/receipt/${projection_id}`,
                              failure_url: `${location.origin}${location.pathname}${
                                up_id ? `?up_id=${up_id}` : ''
                              }`,
                              iamport_name: payment_obj.name,
                              order_details: go(
                                box.sel('user_products'),
                                flatMap((up) => up._.up_cs),
                                map((up_c) => {
                                  return {
                                    id: String(up_c.id),
                                    name:
                                      up_c._.product_color._.base_product['name' + G._en] +
                                      (up_c._.product_color._.base_product.is_bpc_hidden
                                        ? ``
                                        : `[${up_c._.product_color._.base_product_color['name' + G._en]}]`),
                                    price: Number(up_c['discounted_price' + G._en]),
                                    quantity: go(
                                      up_c._.up_c_ss,
                                      sumBy((up_c_s) => up_c_s.quantity),
                                    ),
                                  };
                                }),
                              ),
                            },
                            (err) => {
                              setTimeout(function () {
                                $.don_loader_end();
                              }, 500);
                              resolve();
                              if (err?.message) {
                                $.alert(err?.message);
                              }
                            },
                            () => {
                              setTimeout(function () {
                                $.don_loader_end();
                              }, 2000);
                              resolve();
                            },
                          );
                        } else {
                          try {
                            axios.post(
                              makeApiUrl(`/:lang/@api/payment_logs/request`, {
                                lang: T.lang,
                              }),
                              {
                                module: PaymentLogConstantS.MODULE.KOREA_PORT_ONE,
                                description: '결제 요청',
                                merchant_uid: payment_obj.merchant_uid,
                                data: payment_obj,
                              },
                            );
                          } catch (e) {}
                          if (OMPCoreUtilF.isMpApp()) {
                            /* 리액트 네이티브 환경에 대응하기 */
                            const paramsToString = JSON.stringify({
                              userCode: 'imp03882726', // 가맹점 식별코드
                              data: payment_obj, // 결제 데이터
                              type: 'payment', // 결제와 본인인증 구분을 위한 필드
                            });
                            window.ReactNativeWebView.postMessage(paramsToString);
                            $.don_loader_end();
                            return resolve();
                          } else if ($1('html[is_mobile="false"]')) {
                            IMP.request_pay(payment_obj, function (rsp) {
                              // 결제 성공시
                              if (rsp.success) {
                                $.don_loader_start();
                                location.href =
                                  'receipt/' +
                                  projection_id +
                                  '?imp_success=true&' +
                                  $.param(_p.pick(rsp, ['imp_uid', 'merchant_uid', 'imp_success']));
                              } else {
                                if (rsp.error_msg) {
                                  $.alert(IamportS.rejectImpUid(rsp.error_msg));
                                } else {
                                  $.alert(T('Payment has been stopped'));
                                }
                              }
                              resolve();
                            });
                          } else {
                            IMP.request_pay(payment_obj);
                          }

                          setTimeout(function () {
                            $.don_loader_end();
                          }, 500);
                        }
                      });
                    },
                  );
                },
              );
            }),
        ),
      ),
      $.on2('click', '.product_file_name', ({ currentTarget }) => {
        const { fileUrl, fileName } = currentTarget.dataset;

        if (fileUrl) {
          OMPCoreUtilF.downloadFileAppCompatible({ url: fileUrl, file_name: fileName });
        }
      }),
    );
  },
};

function isCountrySelected() {
  return !!document.querySelector('select[name="country"] option:checked:not([disabled])');
}
