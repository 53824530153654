import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { OMPPromptModalMuiF } from '../Mui/module/OMPPromptModalMuiF.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';

export const openFrame = ({ title, button_title, inputs }) => {
  return new Promise((resolve) => {
    MuiF.openFrame(OMPPromptModalMuiF.frame, (frame, page, [tab]) => {
      page.title = title;
      frame.is_modal = !UtilF.isMobile();
      if (UtilF.isMobile()) {
        frame.animation = true;
        frame.height = 320 + G.mp.frame.v_height;
        frame.header_height = G.mp.frame.v_height;
        frame.always_remove = true;
        frame.prev_frame_show = true;
      }
      tab.makeData = () => {
        return {
          button_title,
          inputs,
        };
      };
      frame.closed = (frame, data) => {
        resolve(data);
      };
    });
  });
};
