import { CreatorAddressConstantS } from '../../../../../../../modules/Creator/Address/S/Constant/module/CreatorAddressConstantS.js';

export default {
  modules: {
    VectorEditor: {
      AcrylicFigure: {
        Free: {
          CreatorPC: {
            CuttingLineAuto: {
              template: {
                header_title: '아크릴 모양 편집',
                description: '아크릴 모양을 편집해 주세요.',
                edit_manual_button: '직접 편집',
                prev_button: '이전',
                next_button: '다음',
              },
              error_message: {
                no_editor_container_el: '아크릴 모양 에디터를 열 수 없습니다.',
                no_acrylic_figure_cutting_line_editor: '아크릴 모양 에디터를 열 수 없습니다.',
                no_make_target_image_el: '아크릴 모양을 만들 피규어 이미지를 생성할 수 없습니다.',
                no_default_outer_cutting_line: '기본 아크릴 모양을 찾을 수 없습니다.',
                svg_data_url_making_error: '피규어 모양을 만드는 데 실패했습니다.',
                svg_img_el_load_error: '피규어 모양을 만드는 데 실패했습니다.',
                svg_canvas_to_blob_error: '피규어 모양을 만드는 데 실패했습니다.',
                png_data_url_making_error: '피규어 모양을 만드는 데 실패했습니다.',
                max_cutting_line_offset_error: '더 이상 오프셋을 늘릴 수 없습니다.',
                min_cutting_line_offset_error: '더 이상 오프셋을 줄일 수 없습니다.',
                manual_cutting_line_error: '아크릴 모양을 직접 편집할 수 없습니다.',
                cutting_line_calculation_error: '재단선 계산에 실패했습니다.',
                cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              },
            },
            CuttingLineManual: {
              template: {
                header_title: '아크릴 모양 편집',
                prev_button: '이전',
                next_button: '다음',
                path_button_group_add: '추가',
                path_button_group_delete_close: '삭제/닫기',
                path_button_group_change: '변경',
                path_button_add_l: '선택한 점에 직선 추가',
                path_button_add_c: '선택한 점에 곡선 추가',
                path_button_remove: '선택한 점 삭제',
                path_button_change_to_l: '선택한 점을 직선으로 변경',
                path_button_change_to_c: '선택한 점을 곡선으로 변경',
                path_button_reset: '처음부터 만들기',
              },
              error_message: {
                no_editor_container_el: '아크릴 모양 에디터를 열 수 없습니다.',
                no_acrylic_figure_cutting_line_editor: '아크릴 모양 에디터를 열 수 없습니다.',
                no_make_target_image_el: '아크릴 모양을 만들 피규어 이미지를 생성할 수 없습니다.',
                waiting_cutting_line_update:
                  '아직 내부 칼선이 반영되지 않았습니다. 업데이트된 내부 칼선을 확인해주세요.',
                cutting_line_calculation_error: '재단선 계산에 실패했습니다.',
                cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
                inner_cutting_line_making_error: '아크릴 안쪽 모양을 계산하는 데 실패했습니다.',
              },
            },
            PathEditor: {
              template: {
                header_title: '패스 편집',
                path_button_group_add: '추가',
                path_button_group_delete_close: '삭제/닫기',
                path_button_group_change: '변경',
                path_button_add_l: '선택한 점에 직선 추가',
                path_button_add_c: '선택한 점에 곡선 추가',
                path_button_remove: '선택한 점 삭제',
                path_button_close: '열린 점 닫기',
                path_button_open: '닫힌 점 열기',
                path_button_change_to_l: '선택한 점을 직선으로 변경',
                path_button_change_to_c: '선택한 점을 곡선으로 변경',
                done_button: '완료',
              },
            },
            template: {
              menu_upload_image: '이미지 업로드',
              menu_my_image: '내 이미지',
              menu_shape: '도형',
              menu_path: '패스',
              menu_text: '텍스트',
              layer_title: '레이어',
              layer_label_group: '그룹',
              layer_label_text: '텍스트',
              layer_label_shape: '도형',
              layer_label_image: '이미지',
              cancel_button: '취소',
              next_button: '다음',
            },
            alert_message: {
              empty_acrylic_figure: '먼저 피규어를 만들어주세요.',
              close_acrylic_figure_editor: '디자인이 모두 삭제됩니다.<br />에디터를 종료하시겠습니까?',
            },
            error_message: {
              cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              no_editor_container_el: '피규어 에디터를 열 수 없습니다.',
              no_pre_process: '이전 작업 복원에 실패했습니다.',
              no_acrylic_figure_editor: '피규어 에디터를 열 수 없습니다.',
            },
          },
          Mobile: {
            CuttingLineAuto: {
              template: {
                header_title: '아크릴 모양 편집',
                description: '아크릴 모양을 편집해 주세요.',
                edit_manual_button: '직접 편집',
                prev_button: '이전',
                next_button: '다음',
              },
              error_message: {
                no_editor_container_el: '아크릴 모양 에디터를 열 수 없습니다.',
                no_acrylic_figure_cutting_line_editor: '아크릴 모양 에디터를 열 수 없습니다.',
                no_make_target_image_el: '아크릴 모양을 만들 피규어 이미지를 생성할 수 없습니다.',
                no_default_outer_cutting_line: '기본 아크릴 모양을 찾을 수 없습니다.',
                svg_data_url_making_error: '피규어 모양을 만드는 데 실패했습니다.',
                svg_img_el_load_error: '피규어 모양을 만드는 데 실패했습니다.',
                svg_canvas_to_blob_error: '피규어 모양을 만드는 데 실패했습니다.',
                png_data_url_making_error: '피규어 모양을 만드는 데 실패했습니다.',
                max_cutting_line_offset_error: '더 이상 오프셋을 늘릴 수 없습니다.',
                min_cutting_line_offset_error: '더 이상 오프셋을 줄일 수 없습니다.',
                manual_cutting_line_error: '아크릴 모양을 직접 편집할 수 없습니다.',
                cutting_line_calculation_error: '재단선 계산에 실패했습니다.',
                cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              },
            },
            CuttingLineManual: {
              template: {
                cancel_button: '취소',
                done_button: '완료',
                path_button_add_l: '직선 추가',
                path_button_add_c: '곡선 추가',
                path_button_remove: '점 삭제',
                path_button_change_to_l: '직선 변경',
                path_button_change_to_c: '곡선 변경',
                path_button_reset: '새로 만들기',
              },
              error_message: {
                no_editor_container_el: '아크릴 모양 에디터를 열 수 없습니다.',
                no_acrylic_figure_cutting_line_editor: '아크릴 모양 에디터를 열 수 없습니다.',
                no_make_target_image_el: '아크릴 모양을 만들 피규어 이미지를 생성할 수 없습니다.',
                waiting_cutting_line_update:
                  '아직 내부 칼선이 반영되지 않았습니다. 업데이트된 내부 칼선을 확인해주세요.',
                cutting_line_calculation_error: '재단선 계산에 실패했습니다.',
                cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
                inner_cutting_line_making_error: '아크릴 안쪽 모양을 계산하는 데 실패했습니다.',
              },
            },
            ElementLayer: {
              template: {
                layer_title: '레이어',
                layer_label_group: '그룹',
                layer_label_text: '텍스트',
                layer_label_shape: '도형',
                layer_label_image: '이미지',
              },
            },
            PathEditor: {
              template: {
                path_button_add_l: '직선 추가',
                path_button_add_c: '곡선 추가',
                path_button_remove: '점 삭제',
                path_button_close: '패스 닫기',
                path_button_open: '패스 열기',
                path_button_change_to_l: '직선 변경',
                path_button_change_to_c: '곡선 변경',
                done_button: '완료',
              },
            },
            template: {
              cancel_button: '취소',
              next_button: '다음',
              text_menu_decorate_text: '텍스트 꾸미기',
              text_menu_open_keyboard: '키보드 열기',
              text_menu_duplicate: '복제',
              text_menu_delete: '삭제',
              path_menu_set_color: '색상',
              path_menu_set_stroke: '테두리',
              path_menu_edit_path: '패스 편집',
              path_menu_duplicate: '복제',
              path_menu_delete: '삭제',
              shape_menu_set_color: '색상',
              shape_menu_set_stroke: '테두리',
              shape_menu_set_duplicate: '복제',
              shape_menu_set_delete: '삭제',
              object_menu_duplicate: '복제',
              object_menu_delete: '삭제',
              bottom_menu_image: '이미지 업로드',
              bottom_menu_shape: '도형',
              bottom_menu_path: '패스',
              bottom_menu_text: '텍스트',
              bottom_menu_group: '꾸미기',
            },
            error_message: {
              reopen_prev_acrylic_figure_error: '이전 작업 복원에 실패했습니다.',
              cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              empty_acrylic_figure: '먼저 피규어를 만들어주세요.',
              close_acrylic_figure_editor: '디자인이 모두 삭제됩니다.<br />에디터를 종료하시겠습니까?',
            },
          },
          PC: {
            CuttingLineAuto: {
              template: {
                header_title: '아크릴 모양 편집',
                description: '아크릴 모양을 편집해 주세요.',
                edit_manual_button: '직접 편집',
                prev_button: '이전',
                next_button: '다음',
              },
              error_message: {
                no_editor_container_el: '아크릴 모양 에디터를 열 수 없습니다.',
                no_acrylic_figure_cutting_line_editor: '아크릴 모양 에디터를 열 수 없습니다.',
                no_make_target_image_el: '아크릴 모양을 만들 피규어 이미지를 생성할 수 없습니다.',
                no_default_outer_cutting_line: '기본 아크릴 모양을 찾을 수 없습니다.',
                svg_data_url_making_error: '피규어 모양을 만드는 데 실패했습니다.',
                svg_img_el_load_error: '피규어 모양을 만드는 데 실패했습니다.',
                svg_canvas_to_blob_error: '피규어 모양을 만드는 데 실패했습니다.',
                png_data_url_making_error: '피규어 모양을 만드는 데 실패했습니다.',
                max_cutting_line_offset_error: '더 이상 오프셋을 늘릴 수 없습니다.',
                min_cutting_line_offset_error: '더 이상 오프셋을 줄일 수 없습니다.',
                manual_cutting_line_error: '아크릴 모양을 직접 편집할 수 없습니다.',
                cutting_line_calculation_error: '재단선 계산에 실패했습니다.',
                cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              },
            },
            CuttingLineManual: {
              template: {
                header_title: '아크릴 모양 편집',
                prev_button: '이전',
                next_button: '다음',
                path_button_group_add: '추가',
                path_button_group_delete_close: '삭제/닫기',
                path_button_group_change: '변경',
                path_button_add_l: '선택한 점에 직선 추가',
                path_button_add_c: '선택한 점에 곡선 추가',
                path_button_remove: '선택한 점 삭제',
                path_button_change_to_l: '선택한 점을 직선으로 변경',
                path_button_change_to_c: '선택한 점을 곡선으로 변경',
                path_button_reset: '처음부터 만들기',
              },
              error_message: {
                no_editor_container_el: '아크릴 모양 에디터를 열 수 없습니다.',
                no_acrylic_figure_cutting_line_editor: '아크릴 모양 에디터를 열 수 없습니다.',
                no_make_target_image_el: '아크릴 모양을 만들 피규어 이미지를 생성할 수 없습니다.',
                waiting_cutting_line_update:
                  '아직 내부 칼선이 반영되지 않았습니다. 업데이트된 내부 칼선을 확인해주세요.',
                cutting_line_calculation_error: '재단선 계산에 실패했습니다.',
                cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
                inner_cutting_line_making_error: '아크릴 안쪽 모양을 계산하는 데 실패했습니다.',
              },
            },
            PathEditor: {
              template: {
                header_title: '패스 편집',
                path_button_group_add: '추가',
                path_button_group_delete_close: '삭제/닫기',
                path_button_group_change: '변경',
                path_button_add_l: '선택한 점에 직선 추가',
                path_button_add_c: '선택한 점에 곡선 추가',
                path_button_remove: '선택한 점 삭제',
                path_button_close: '열린 점 닫기',
                path_button_open: '닫힌 점 열기',
                path_button_change_to_l: '선택한 점을 직선으로 변경',
                path_button_change_to_c: '선택한 점을 곡선으로 변경',
                done_button: '완료',
              },
            },
            template: {
              menu_upload_image: '이미지 업로드',
              menu_my_image: '내 이미지',
              menu_shape: '도형',
              menu_path: '패스',
              menu_text: '텍스트',
              layer_title: '레이어',
              layer_label_group: '그룹',
              layer_label_text: '텍스트',
              layer_label_shape: '도형',
              layer_label_image: '이미지',
              cancel_button: '취소',
              next_button: '다음',
            },
            alert_message: {
              empty_acrylic_figure: '먼저 피규어를 만들어주세요.',
              close_acrylic_figure_editor: '디자인이 모두 삭제됩니다.<br />에디터를 종료하시겠습니까?',
            },
            error_message: {
              cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              no_editor_container_el: '피규어 에디터를 열 수 없습니다.',
              no_pre_process: '이전 작업 복원에 실패했습니다.',
              no_acrylic_figure_editor: '피규어 에디터를 열 수 없습니다.',
            },
          },
        },
        StandLeg: {
          CreatorPC: {
            template: {
              header_title: '아크릴 스탠드 고정대 편집',
              description: '스탠드 고정대 위치와 크기를 조절해 주세요.',
              minus_button: '-',
              plus_button: '+',
              size_description: '스탠드 고정대의 가로 크기를 조절해 주세요.',
              position_description: '스탠드 고정대의 위치를 조절해 주세요.',
              cancel_button: '이전',
              done_button: '완료',
            },
            error_message: {
              no_editor_container_el: '에디터를 연결할 수 없습니다.',
              no_acrylic_figure_stand_leg_editor: '에디터를 연결할 수 없습니다.',
              no_make_target_image_el: '스탠드 이미지를 찾을 수 없습니다.',
              max_stand_leg_width_error: '더 이상 고정대 크기를 늘릴 수 없습니다.',
              min_stand_leg_width_error: '더 이상 고정대 크기를 줄일 수 없습니다.',
              cutting_line_calculation_error: '재단선 계산에 실패했습니다.',
            },
          },
          Mobile: {
            template: {
              title: '아크릴 스탠드 고정대 편집',
              cancel_button: '이전',
              done_button: '완료',
              description: '스탠드 고정대 위치와 크기를 조절해 주세요.',
              minus_button: '-',
              plus_button: '+',
              size_description: '스탠드 고정대 가로 크기',
              position_description: '스탠드 고정대 위치',
            },
            error_message: {
              max_stand_leg_width_error: '더 이상 고정대 크기를 늘릴 수 없습니다.',
              min_stand_leg_width_error: '더 이상 고정대 크기를 줄일 수 없습니다.',
              cutting_line_calculation_error: '재단선 계산에 실패했습니다.',
            },
          },
          PC: {
            template: {
              header_title: '아크릴 스탠드 고정대 편집',
              description: '스탠드 고정대 위치와 크기를 조절해 주세요.',
              minus_button: '-',
              plus_button: '+',
              size_description: '스탠드 고정대의 가로 크기를 조절해 주세요.',
              position_description: '스탠드 고정대의 위치를 조절해 주세요.',
              cancel_button: '이전',
              done_button: '완료',
            },
            error_message: {
              no_editor_container_el: '에디터를 연결할 수 없습니다.',
              no_acrylic_figure_stand_leg_editor: '에디터를 연결할 수 없습니다.',
              no_make_target_image_el: '스탠드 이미지를 찾을 수 없습니다.',
              max_stand_leg_width_error: '더 이상 고정대 크기를 늘릴 수 없습니다.',
              min_stand_leg_width_error: '더 이상 고정대 크기를 줄일 수 없습니다.',
              cutting_line_calculation_error: '재단선 계산에 실패했습니다.',
            },
          },
        },
        template: {
          empty_template_guide: '나만의 스탠드를 만들어 보세요.',
        },
        message: {
          invalid_prev_acrylic_figure_svg: '이전 작업을 불러올 수 없습니다.',
          login_first: '로그인이 필요합니다. 먼저 로그인을 해주세요.',
          make_images: '출력용 고해상도 이미지를 생성 중입니다.<br />완료될 때까지 기다려주세요.',
          too_large_image: '스탠드 이미지가 너무 커서 생성할 수 없습니다. PC 환경에서 만들어주세요.',
          output_making_error: '제품 제작용 파일 생성에 실패했습니다.',
          load_image_error: '이미지 파일을 불러올 수 없습니다.',
          invalid_meta_data: '스탠드 데이터를 찾을 수 없습니다.',
        },
      },
      FreeShape: {
        Mobile: {
          template: {
            title: '도형',
          },
        },
        PC: {
          template: {
            title: '도형',
          },
        },
      },
      Keyring: {
        Free: {
          CreatorPC: {
            CuttingLineAuto: {
              template: {
                header_title: '아크릴 모양 편집',
                description: '아크릴 모양을 편집해 주세요.',
                edit_manual_button: '직접 편집',
                prev_button: '이전',
                next_button: '다음',
              },
              error_message: {
                no_editor_container_el: '아크릴 모양 에디터를 열 수 없습니다.',
                no_keyring_cutting_line_editor: '아크릴 모양 에디터를 열 수 없습니다.',
                no_make_target_image_el: '아크릴 모양을 만들 키링 이미지를 생성할 수 없습니다.',
                no_default_outer_cutting_line: '기본 아크릴 모양을 찾을 수 없습니다.',
                svg_data_url_making_error: '키링 모양을 만드는 데 실패했습니다.',
                svg_img_el_load_error: '키링 모양을 만드는 데 실패했습니다.',
                svg_canvas_to_blob_error: '키링 모양을 만드는 데 실패했습니다.',
                png_data_url_making_error: '키링 모양을 만드는 데 실패했습니다.',
                max_cutting_line_offset_error: '더 이상 오프셋을 늘릴 수 없습니다.',
                min_cutting_line_offset_error: '더 이상 오프셋을 줄일 수 없습니다.',
                manual_cutting_line_error: '아크릴 모양을 직접 편집할 수 없습니다.',
                cutting_line_calculation_error: '고리 재단선 계산에 실패했습니다.',
                cutting_line_making_error: '고리 재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              },
            },
            CuttingLineManual: {
              template: {
                header_title: '아크릴 모양 편집',
                prev_button: '이전',
                next_button: '다음',
                path_button_group_add: '추가',
                path_button_group_delete_close: '삭제/닫기',
                path_button_group_change: '변경',
                path_button_add_l: '선택한 점에 직선 추가',
                path_button_add_c: '선택한 점에 곡선 추가',
                path_button_remove: '선택한 점 삭제',
                path_button_change_to_l: '선택한 점을 직선으로 변경',
                path_button_change_to_c: '선택한 점을 곡선으로 변경',
                path_button_reset: '처음부터 만들기',
              },
              error_message: {
                no_editor_container_el: '아크릴 모양 에디터를 열 수 없습니다.',
                no_keyring_cutting_line_editor: '아크릴 모양 에디터를 열 수 없습니다.',
                no_make_target_image_el: '아크릴 모양을 만들 키링 이미지를 생성할 수 없습니다.',
                waiting_cutting_line_update:
                  '아직 내부 칼선이 반영되지 않았습니다. 업데이트된 내부 칼선을 확인해주세요.',
                cutting_line_calculation_error: '고리 재단선 계산에 실패했습니다.',
                cutting_line_making_error: '고리 재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
                inner_cutting_line_making_error: '아크릴 안쪽 모양을 계산하는 데 실패했습니다.',
              },
            },
            PathEditor: {
              template: {
                header_title: '패스 편집',
                path_button_group_add: '추가',
                path_button_group_delete_close: '삭제/닫기',
                path_button_group_change: '변경',
                path_button_add_l: '선택한 점에 직선 추가',
                path_button_add_c: '선택한 점에 곡선 추가',
                path_button_remove: '선택한 점 삭제',
                path_button_close: '열린 점 닫기',
                path_button_open: '닫힌 점 열기',
                path_button_change_to_l: '선택한 점을 직선으로 변경',
                path_button_change_to_c: '선택한 점을 곡선으로 변경',
                done_button: '완료',
              },
            },
            template: {
              menu_upload_image: '이미지 업로드',
              menu_my_image: '내 이미지',
              menu_shape: '도형',
              menu_path: '패스',
              menu_text: '텍스트',
              layer_title: '레이어',
              layer_label_group: '그룹',
              layer_label_text: '텍스트',
              layer_label_shape: '도형',
              layer_label_image: '이미지',
              cancel_button: '취소',
              next_button: '다음',
            },
            alert_message: {
              empty_keyring: '먼저 키링을 만들어주세요.',
              close_keyring_editor: '디자인이 모두 삭제됩니다.<br />에디터를 종료하시겠습니까?',
            },
            error_message: {
              hook_cutting_line_making_error: '고리 재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              no_editor_container_el: '키링 에디터를 열 수 없습니다.',
              no_pre_process: '이전 작업 복원에 실패했습니다.',
              no_keyring_editor: '키링 에디터를 열 수 없습니다.',
            },
          },
          Mobile: {
            CuttingLineAuto: {
              template: {
                header_title: '아크릴 모양 편집',
                description: '아크릴 모양을 편집해 주세요.',
                edit_manual_button: '직접 편집',
                prev_button: '이전',
                next_button: '다음',
              },
              error_message: {
                no_editor_container_el: '아크릴 모양 에디터를 열 수 없습니다.',
                no_keyring_cutting_line_editor: '아크릴 모양 에디터를 열 수 없습니다.',
                no_make_target_image_el: '아크릴 모양을 만들 키링 이미지를 생성할 수 없습니다.',
                no_default_outer_cutting_line: '기본 아크릴 모양을 찾을 수 없습니다.',
                svg_data_url_making_error: '키링 모양을 만드는 데 실패했습니다.',
                svg_img_el_load_error: '키링 모양을 만드는 데 실패했습니다.',
                svg_canvas_to_blob_error: '키링 모양을 만드는 데 실패했습니다.',
                png_data_url_making_error: '키링 모양을 만드는 데 실패했습니다.',
                max_cutting_line_offset_error: '더 이상 오프셋을 늘릴 수 없습니다.',
                min_cutting_line_offset_error: '더 이상 오프셋을 줄일 수 없습니다.',
                manual_cutting_line_error: '아크릴 모양을 직접 편집할 수 없습니다.',
                cutting_line_calculation_error: '고리 재단선 계산에 실패했습니다.',
                cutting_line_making_error: '고리 재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              },
            },
            CuttingLineManual: {
              template: {
                cancel_button: '취소',
                done_button: '완료',
                path_button_add_l: '직선 추가',
                path_button_add_c: '곡선 추가',
                path_button_remove: '점 삭제',
                path_button_change_to_l: '직선 변경',
                path_button_change_to_c: '곡선 변경',
                path_button_reset: '새로 만들기',
              },
              error_message: {
                no_editor_container_el: '아크릴 모양 에디터를 열 수 없습니다.',
                no_keyring_cutting_line_editor: '아크릴 모양 에디터를 열 수 없습니다.',
                no_make_target_image_el: '아크릴 모양을 만들 키링 이미지를 생성할 수 없습니다.',
                waiting_cutting_line_update:
                  '아직 내부 칼선이 반영되지 않았습니다. 업데이트된 내부 칼선을 확인해주세요.',
                cutting_line_calculation_error: '고리 재단선 계산에 실패했습니다.',
                cutting_line_making_error: '고리 재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
                inner_cutting_line_making_error: '아크릴 안쪽 모양을 계산하는 데 실패했습니다.',
              },
            },
            ElementLayer: {
              template: {
                layer_title: '레이어',
                layer_label_group: '그룹',
                layer_label_text: '텍스트',
                layer_label_shape: '도형',
                layer_label_image: '이미지',
              },
            },
            PathEditor: {
              template: {
                path_button_add_l: '직선 추가',
                path_button_add_c: '곡선 추가',
                path_button_remove: '점 삭제',
                path_button_close: '패스 닫기',
                path_button_open: '패스 열기',
                path_button_change_to_l: '직선 변경',
                path_button_change_to_c: '곡선 변경',
                done_button: '완료',
              },
            },
            template: {
              cancel_button: '취소',
              next_button: '다음',
              text_menu_decorate_text: '텍스트 꾸미기',
              text_menu_open_keyboard: '키보드 열기',
              text_menu_duplicate: '복제',
              text_menu_delete: '삭제',
              path_menu_set_color: '색상',
              path_menu_set_stroke: '테두리',
              path_menu_edit_path: '패스 편집',
              path_menu_duplicate: '복제',
              path_menu_delete: '삭제',
              shape_menu_set_color: '색상',
              shape_menu_set_stroke: '테두리',
              shape_menu_set_duplicate: '복제',
              shape_menu_set_delete: '삭제',
              object_menu_duplicate: '복제',
              object_menu_delete: '삭제',
              bottom_menu_image: '이미지 업로드',
              bottom_menu_shape: '도형',
              bottom_menu_path: '패스',
              bottom_menu_text: '텍스트',
              bottom_menu_group: '꾸미기',
            },
            error_message: {
              reopen_prev_keyring_error: '이전 작업 복원에 실패했습니다.',
              cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              empty_keyring: '먼저 키링을 만들어주세요.',
              close_keyring_editor: '디자인이 모두 삭제됩니다.<br />에디터를 종료하시겠습니까?',
            },
          },
          PC: {
            CuttingLineAuto: {
              template: {
                header_title: '아크릴 모양 편집',
                description: '아크릴 모양을 편집해 주세요.',
                edit_manual_button: '직접 편집',
                prev_button: '이전',
                next_button: '다음',
              },
              error_message: {
                no_editor_container_el: '아크릴 모양 에디터를 열 수 없습니다.',
                no_keyring_cutting_line_editor: '아크릴 모양 에디터를 열 수 없습니다.',
                no_make_target_image_el: '아크릴 모양을 만들 키링 이미지를 생성할 수 없습니다.',
                no_default_outer_cutting_line: '기본 아크릴 모양을 찾을 수 없습니다.',
                svg_data_url_making_error: '키링 모양을 만드는 데 실패했습니다.',
                svg_img_el_load_error: '키링 모양을 만드는 데 실패했습니다.',
                svg_canvas_to_blob_error: '키링 모양을 만드는 데 실패했습니다.',
                png_data_url_making_error: '키링 모양을 만드는 데 실패했습니다.',
                max_cutting_line_offset_error: '더 이상 오프셋을 늘릴 수 없습니다.',
                min_cutting_line_offset_error: '더 이상 오프셋을 줄일 수 없습니다.',
                manual_cutting_line_error: '아크릴 모양을 직접 편집할 수 없습니다.',
                cutting_line_calculation_error: '고리 재단선 계산에 실패했습니다.',
                cutting_line_making_error: '고리 재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              },
            },
            CuttingLineManual: {
              template: {
                header_title: '아크릴 모양 편집',
                prev_button: '이전',
                next_button: '다음',
                path_button_group_add: '추가',
                path_button_group_delete_close: '삭제/닫기',
                path_button_group_change: '변경',
                path_button_add_l: '선택한 점에 직선 추가',
                path_button_add_c: '선택한 점에 곡선 추가',
                path_button_remove: '선택한 점 삭제',
                path_button_change_to_l: '선택한 점을 직선으로 변경',
                path_button_change_to_c: '선택한 점을 곡선으로 변경',
                path_button_reset: '처음부터 만들기',
              },
              error_message: {
                no_editor_container_el: '아크릴 모양 에디터를 열 수 없습니다.',
                no_keyring_cutting_line_editor: '아크릴 모양 에디터를 열 수 없습니다.',
                no_make_target_image_el: '아크릴 모양을 만들 키링 이미지를 생성할 수 없습니다.',
                waiting_cutting_line_update:
                  '아직 내부 칼선이 반영되지 않았습니다. 업데이트된 내부 칼선을 확인해주세요.',
                cutting_line_calculation_error: '고리 재단선 계산에 실패했습니다.',
                cutting_line_making_error: '고리 재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
                inner_cutting_line_making_error: '아크릴 안쪽 모양을 계산하는 데 실패했습니다.',
              },
            },
            PathEditor: {
              template: {
                header_title: '패스 편집',
                path_button_group_add: '추가',
                path_button_group_delete_close: '삭제/닫기',
                path_button_group_change: '변경',
                path_button_add_l: '선택한 점에 직선 추가',
                path_button_add_c: '선택한 점에 곡선 추가',
                path_button_remove: '선택한 점 삭제',
                path_button_close: '열린 점 닫기',
                path_button_open: '닫힌 점 열기',
                path_button_change_to_l: '선택한 점을 직선으로 변경',
                path_button_change_to_c: '선택한 점을 곡선으로 변경',
                done_button: '완료',
              },
            },
            template: {
              menu_upload_image: '이미지 업로드',
              menu_my_image: '내 이미지',
              menu_shape: '도형',
              menu_path: '패스',
              menu_text: '텍스트',
              layer_title: '레이어',
              layer_label_group: '그룹',
              layer_label_text: '텍스트',
              layer_label_shape: '도형',
              layer_label_image: '이미지',
              cancel_button: '취소',
              next_button: '다음',
            },
            alert_message: {
              empty_keyring: '먼저 키링을 만들어주세요.',
              close_keyring_editor: '디자인이 모두 삭제됩니다.<br />에디터를 종료하시겠습니까?',
            },
            error_message: {
              hook_cutting_line_making_error: '고리 재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              no_editor_container_el: '키링 에디터를 열 수 없습니다.',
              no_pre_process: '이전 작업 복원에 실패했습니다.',
              no_keyring_editor: '키링 에디터를 열 수 없습니다.',
            },
          },
        },
        Grid: {
          CreatorPC: {
            PathEditor: {
              template: {
                header_title: '패스 편집',
                path_button_group_add: '추가',
                path_button_group_delete_close: '삭제/닫기',
                path_button_group_change: '변경',
                path_button_add_l: '선택한 점에 직선 추가',
                path_button_add_c: '선택한 점에 곡선 추가',
                path_button_remove: '선택한 점 삭제',
                path_button_close: '열린 점 닫기',
                path_button_open: '닫힌 점 열기',
                path_button_change_to_l: '선택한 점을 직선으로 변경',
                path_button_change_to_c: '선택한 점을 곡선으로 변경',
                done_button: '완료',
              },
            },
            template: {
              menu_upload_image: '이미지 업로드',
              menu_my_image: '내 이미지',
              menu_shape: '도형',
              menu_path: '패스',
              menu_text: '텍스트',
              layer_title: '레이어',
              layer_label_group: '그룹',
              layer_label_text: '텍스트',
              layer_label_shape: '도형',
              layer_label_image: '이미지',
              cancel_button: '취소',
              next_button: '다음',
            },
            alert_message: {
              empty_keyring: '먼저 키링을 만들어주세요.',
              close_keyring_editor: '디자인이 모두 삭제됩니다.<br />에디터를 종료하시겠습니까?',
            },
            error_message: {
              cutting_line_calculation_error: '재단선 계산에 실패했습니다.',
              hook_cutting_line_making_error: '고리 재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              no_editor_container_el: '키링 에디터를 열 수 없습니다.',
              no_pre_process: '이전 작업 복원에 실패했습니다.',
              no_keyring_editor: '키링 에디터를 열 수 없습니다.',
            },
          },
          Mobile: {
            ElementLayer: {
              template: {
                header_title: '레이어',
                label_group: '그룹',
                label_text: '텍스트',
                label_shape: '도형',
                label_image: '이미지',
              },
            },
            PathEditor: {
              template: {
                done_button: '완료',
                path_button_add_l: '직선 추가',
                path_button_add_c: '곡선 추가',
                path_button_remove: '점 삭제',
                path_button_close: '패스 닫기',
                path_button_open: '패스 열기',
                path_button_change_to_l: '직선 변경',
                path_button_change_to_c: '곡선 변경',
              },
            },
            template: {
              cancel_button: '취소',
              next_button: '다음',
              text_menu_decorate_text: '텍스트 꾸미기',
              text_menu_open_keyboard: '키보드 열기',
              text_menu_duplicate: '복제',
              text_menu_delete: '삭제',
              path_menu_set_color: '색상',
              path_menu_set_stroke: '테두리',
              path_menu_edit_path: '패스 편집',
              path_menu_duplicate: '복제',
              path_menu_delete: '삭제',
              shape_menu_set_color: '색상',
              shape_menu_set_stroke: '테두리',
              shape_menu_set_duplicate: '복제',
              shape_menu_set_delete: '삭제',
              object_menu_duplicate: '복제',
              object_menu_delete: '삭제',
              bottom_menu_image: '이미지 업로드',
              bottom_menu_shape: '도형',
              bottom_menu_path: '패스',
              bottom_menu_text: '텍스트',
              bottom_menu_group: '꾸미기',
            },
            error_message: {
              reopen_prev_keyring_error: '이전 작업 복원에 실패했습니다.',
              cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              cutting_line_calculation_error: '재단선 계산에 실패했습니다.',
              empty_keyring: '먼저 키링을 만들어주세요.',
              close_keyring_editor: '디자인이 모두 삭제됩니다.<br />에디터를 종료하시겠습니까?',
            },
          },
          PC: {
            PathEditor: {
              template: {
                header_title: '패스 편집',
                path_button_group_add: '추가',
                path_button_group_delete_close: '삭제/닫기',
                path_button_group_change: '변경',
                path_button_add_l: '선택한 점에 직선 추가',
                path_button_add_c: '선택한 점에 곡선 추가',
                path_button_remove: '선택한 점 삭제',
                path_button_close: '열린 점 닫기',
                path_button_open: '닫힌 점 열기',
                path_button_change_to_l: '선택한 점을 직선으로 변경',
                path_button_change_to_c: '선택한 점을 곡선으로 변경',
                done_button: '완료',
              },
            },
            template: {
              menu_upload_image: '이미지 업로드',
              menu_my_image: '내 이미지',
              menu_shape: '도형',
              menu_path: '패스',
              menu_text: '텍스트',
              layer_title: '레이어',
              layer_label_group: '그룹',
              layer_label_text: '텍스트',
              layer_label_shape: '도형',
              layer_label_image: '이미지',
              cancel_button: '취소',
              next_button: '다음',
            },
            alert_message: {
              empty_keyring: '먼저 키링을 만들어주세요.',
              close_keyring_editor: '디자인이 모두 삭제됩니다.<br />에디터를 종료하시겠습니까?',
            },
            error_message: {
              cutting_line_calculation_error: '재단선 계산에 실패했습니다.',
              hook_cutting_line_making_error: '고리 재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              no_editor_container_el: '키링 에디터를 열 수 없습니다.',
              no_pre_process: '이전 작업 복원에 실패했습니다.',
              no_keyring_editor: '키링 에디터를 열 수 없습니다.',
            },
          },
        },
        Hook: {
          CreatorPC: {
            template: {
              header_title: '키링 구멍 편집',
              description: '키링 구멍의 유형을 선택하고, 위치를 이동해 보세요.',
              inside_button: '안쪽',
              outside_button: '바깥쪽',
              cancel_button: '이전',
              done_button: '완료',
            },
            error_message: {
              no_editor_container_el: '에디터를 연결할 수 없습니다.',
              no_keyring_hook_editor: '에디터를 연결할 수 없습니다.',
              no_make_target_image_el: '키링 이미지를 찾을 수 없습니다.',
            },
          },
          Mobile: {
            template: {
              title: '키링 구멍 편집',
              cancel_button: '이전',
              done_button: '완료',
              description: '키링 구멍의 유형을 선택하고, 위치를 이동해 보세요.',
              inside_button: '안쪽',
              outside_button: '바깥쪽',
            },
          },
          PC: {
            template: {
              header_title: '키링 구멍 편집',
              description: '키링 구멍의 유형을 선택하고, 위치를 이동해 보세요.',
              inside_button: '안쪽',
              outside_button: '바깥쪽',
              cancel_button: '이전',
              done_button: '완료',
            },
            error_message: {
              no_editor_container_el: '에디터를 연결할 수 없습니다.',
              no_keyring_hook_editor: '에디터를 연결할 수 없습니다.',
              no_make_target_image_el: '키링 이미지를 찾을 수 없습니다.',
            },
          },
        },
        template: {
          empty_template_guide: '나만의 키링을 만들어 보세요.',
        },
        message: {
          invalid_editor_type: '잘못된 에디터 타입입니다.',
          invalid_prev_keyring_svg: '이전 작업을 불러올 수 없습니다.',
          login_first: '로그인이 필요합니다. 먼저 로그인을 해주세요.',
          make_images: '출력용 고해상도 이미지를 생성 중입니다.<br />완료될 때까지 기다려주세요.',
          too_large_image: '키링 이미지가 너무 커서 생성할 수 없습니다. PC 환경에서 만들어주세요.',
          output_making_error: '제품 제작용 파일 생성에 실패했습니다.',
          load_image_error: '이미지 파일을 불러올 수 없습니다.',
          invalid_meta_data: '키링 데이터를 찾을 수 없습니다.',
        },
      },
      TopMenu: {
        PC: {
          template: {
            top_menu_history_reset: '처음으로',
            top_menu_history_undo: '취소',
            top_menu_history_redo: '다시실행',
            top_menu_toggle_layers_mode: '레이어',
            top_menu_duplicate: '복제',
            top_menu_delete: '삭제',
            top_menu_forward: '앞으로',
            top_menu_backward: '뒤로',
            top_menu_group: '그룹',
            top_menu_ungroup: '그룹해제',
            top_menu_flip_horizontal: '좌우반전',
            top_menu_flip_vertical: '상하반전',
            top_menu_move_to_left_end: '왼쪽',
            top_menu_move_to_right_end: '오른쪽',
            top_menu_move_to_top_end: '위',
            top_menu_move_to_bottom_end: '아래',
            top_menu_move_to_horizontal_center: '가운데',
            top_menu_move_to_vertical_center: '가운데',
          },
        },
      },
      Sticker: {
        Single: {
          CreatorPC: {
            CuttingLineAuto: {
              template: {
                header_title: '스티커 칼선 편집',
                description: '스티커를 뗄 수 있도록 칼선 모양을 편집해 주세요.',
                edit_manual_button: '직접 편집',
                prev_button: '이전',
                next_button: '다음',
                done: '완료',
              },
              error_message: {
                no_editor_container_el: '아크릴 모양 에디터를 열 수 없습니다.',
                no_acrylic_figure_cutting_line_editor: '아크릴 모양 에디터를 열 수 없습니다.',
                no_make_target_image_el: '아크릴 모양을 만들 피규어 이미지를 생성할 수 없습니다.',
                no_default_outer_cutting_line: '기본 아크릴 모양을 찾을 수 없습니다.',
                svg_data_url_making_error: '피규어 모양을 만드는 데 실패했습니다.',
                svg_img_el_load_error: '피규어 모양을 만드는 데 실패했습니다.',
                svg_canvas_to_blob_error: '피규어 모양을 만드는 데 실패했습니다.',
                png_data_url_making_error: '피규어 모양을 만드는 데 실패했습니다.',
                max_cutting_line_offset_error: '더 이상 오프셋을 늘릴 수 없습니다.',
                min_cutting_line_offset_error: '더 이상 오프셋을 줄일 수 없습니다.',
                manual_cutting_line_error: '아크릴 모양을 직접 편집할 수 없습니다.',
                cutting_line_calculation_error: '재단선 계산에 실패했습니다.',
                cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              },
            },
            CuttingLineManual: {
              template: {
                header_title: '직접 편집',
                prev_button: '닫기',
                next_button: '다음',
                path_button_group_add: '추가',
                path_button_group_delete_close: '삭제/닫기',
                path_button_group_change: '변경',
                path_button_add_l: '선택한 점에 직선 추가',
                path_button_add_c: '선택한 점에 곡선 추가',
                path_button_remove: '선택한 점 삭제',
                path_button_change_to_l: '선택한 점을 직선으로 변경',
                path_button_change_to_c: '선택한 점을 곡선으로 변경',
                path_button_reset: '처음부터 만들기',
                path_button_close: '열린 점 닫기',
                path_button_open: '닫힌 점 열기',
                done_button: '완료',
              },
              error_message: {
                no_editor_container_el: '아크릴 모양 에디터를 열 수 없습니다.',
                no_acrylic_figure_cutting_line_editor: '아크릴 모양 에디터를 열 수 없습니다.',
                no_make_target_image_el: '아크릴 모양을 만들 피규어 이미지를 생성할 수 없습니다.',
                waiting_cutting_line_update:
                  '아직 내부 칼선이 반영되지 않았습니다. 업데이트된 내부 칼선을 확인해주세요.',
                cutting_line_calculation_error: '재단선 계산에 실패했습니다.',
                cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
                inner_cutting_line_making_error: '아크릴 안쪽 모양을 계산하는 데 실패했습니다.',
              },
            },
            PathEditor: {
              template: {
                header_title: '패스 편집',
                path_button_group_add: '추가',
                path_button_group_delete_close: '삭제/닫기',
                path_button_group_change: '변경',
                path_button_add_l: '선택한 점에 직선 추가',
                path_button_add_c: '선택한 점에 곡선 추가',
                path_button_remove: '선택한 점 삭제',
                path_button_close: '열린 점 닫기',
                path_button_open: '닫힌 점 열기',
                path_button_change_to_l: '선택한 점을 직선으로 변경',
                path_button_change_to_c: '선택한 점을 곡선으로 변경',
                done_button: '완료',
              },
            },
            template: {
              menu_upload_image: '이미지 업로드',
              menu_my_image: '내 이미지',
              menu_shape: '도형',
              menu_path: '패스',
              menu_text: '텍스트',
              layer_title: '레이어',
              layer_label_group: '그룹',
              layer_label_text: '텍스트',
              layer_label_shape: '도형',
              layer_label_image: '이미지',
              cancel_button: '취소',
              next_button: '다음',
              done_button: '완료',
            },
            alert_message: {
              empty_acrylic_figure: '먼저 피규어를 만들어주세요.',
              close_acrylic_figure_editor: '디자인이 모두 삭제됩니다.<br />에디터를 종료하시겠습니까?',
            },
            error_message: {
              cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              no_editor_container_el: '피규어 에디터를 열 수 없습니다.',
              no_pre_process: '이전 작업 복원에 실패했습니다.',
              no_acrylic_figure_editor: '피규어 에디터를 열 수 없습니다.',
            },
          },
          Mobile: {
            CuttingLineAuto: {
              template: {
                header_title: '스티커 칼선 편집',
                description: '스티커를 뗄 수 있도록 칼선 모양을 편집해 주세요.',
                edit_manual_button: '직접 편집',
                prev_button: '이전',
                next_button: '완료',
              },
              error_message: {
                max_cutting_line_offset_error: '더 이상 오프셋을 늘릴 수 없습니다.',
                min_cutting_line_offset_error: '더 이상 오프셋을 줄일 수 없습니다.',
                cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              },
            },
            CuttingLineManual: {
              template: {
                cancel_button: '취소',
                done_button: '완료',
                path_button_add_l: '직선 추가',
                path_button_add_c: '곡선 추가',
                path_button_remove: '점 삭제',
                path_button_change_to_l: '직선 변경',
                path_button_change_to_c: '곡선 변경',
                path_button_reset: '새로 만들기',
              },
              error_message: {
                cutting_line_calculation_error: '재단선 계산에 실패했습니다.',
                cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              },
            },
            ElementLayer: {
              template: {
                layer_title: '레이어',
                layer_label_group: '그룹',
                layer_label_text: '텍스트',
                layer_label_shape: '도형',
                layer_label_image: '이미지',
              },
            },
            PathEditor: {
              template: {
                path_button_add_l: '직선 추가',
                path_button_add_c: '곡선 추가',
                path_button_remove: '점 삭제',
                path_button_close: '패스 닫기',
                path_button_open: '패스 열기',
                path_button_change_to_l: '직선 변경',
                path_button_change_to_c: '곡선 변경',
                done_button: '완료',
              },
            },
            template: {
              cancel_button: '취소',
              next_button: '다음',
              done_button: '완료',
              text_menu_decorate_text: '텍스트 꾸미기',
              text_menu_open_keyboard: '키보드 열기',
              text_menu_duplicate: '복제',
              text_menu_delete: '삭제',
              path_menu_set_color: '색상',
              path_menu_set_stroke: '테두리',
              path_menu_edit_path: '패스 편집',
              path_menu_duplicate: '복제',
              path_menu_delete: '삭제',
              shape_menu_set_color: '색상',
              shape_menu_set_stroke: '테두리',
              shape_menu_set_duplicate: '복제',
              shape_menu_set_delete: '삭제',
              object_menu_background: '배경 설정',
              object_menu_duplicate: '복제',
              object_menu_delete: '삭제',
              bottom_menu_image: '이미지 업로드',
              bottom_menu_shape: '도형',
              bottom_menu_path: '패스',
              bottom_menu_text: '텍스트',
              bottom_menu_group: '꾸미기',
            },
            error_message: {
              reopen_prev_acrylic_figure_error: '이전 작업 복원에 실패했습니다.',
              cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              close_acrylic_figure_editor: '디자인이 모두 삭제됩니다.<br />에디터를 종료하시겠습니까?',
            },
          },
          PC: {
            CuttingLineAuto: {
              template: {
                header_title: '스티커 칼선 편집',
                description: '스티커를 뗄 수 있도록 칼선 모양을 편집해 주세요.',
                edit_manual_button: '직접 편집',
                prev_button: '이전',
                next_button: '다음',
                done_button: '완료',
              },
              error_message: {
                no_editor_container_el: '아크릴 모양 에디터를 열 수 없습니다.',
                no_acrylic_figure_cutting_line_editor: '아크릴 모양 에디터를 열 수 없습니다.',
                no_make_target_image_el: '아크릴 모양을 만들 피규어 이미지를 생성할 수 없습니다.',
                no_default_outer_cutting_line: '기본 아크릴 모양을 찾을 수 없습니다.',
                svg_data_url_making_error: '피규어 모양을 만드는 데 실패했습니다.',
                svg_img_el_load_error: '피규어 모양을 만드는 데 실패했습니다.',
                svg_canvas_to_blob_error: '피규어 모양을 만드는 데 실패했습니다.',
                png_data_url_making_error: '피규어 모양을 만드는 데 실패했습니다.',
                max_cutting_line_offset_error: '더 이상 오프셋을 늘릴 수 없습니다.',
                min_cutting_line_offset_error: '더 이상 오프셋을 줄일 수 없습니다.',
                manual_cutting_line_error: '아크릴 모양을 직접 편집할 수 없습니다.',
                cutting_line_calculation_error: '재단선 계산에 실패했습니다.',
                cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              },
            },
            CuttingLineManual: {
              template: {
                header_title: '직접 편집',
                prev_button: '이전',
                next_button: '다음',
                done_button: '완료',
                path_button_group_add: '추가',
                path_button_group_delete_close: '삭제/닫기',
                path_button_group_change: '변경',
                path_button_add_l: '선택한 점에 직선 추가',
                path_button_add_c: '선택한 점에 곡선 추가',
                path_button_remove: '선택한 점 삭제',
                path_button_change_to_l: '선택한 점을 직선으로 변경',
                path_button_change_to_c: '선택한 점을 곡선으로 변경',
                path_button_reset: '처음부터 만들기',
              },
              error_message: {
                no_editor_container_el: '아크릴 모양 에디터를 열 수 없습니다.',
                no_acrylic_figure_cutting_line_editor: '아크릴 모양 에디터를 열 수 없습니다.',
                no_make_target_image_el: '아크릴 모양을 만들 피규어 이미지를 생성할 수 없습니다.',
                waiting_cutting_line_update:
                  '아직 내부 칼선이 반영되지 않았습니다. 업데이트된 내부 칼선을 확인해주세요.',
                cutting_line_calculation_error: '재단선 계산에 실패했습니다.',
                cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
                inner_cutting_line_making_error: '아크릴 안쪽 모양을 계산하는 데 실패했습니다.',
              },
            },
            PathEditor: {
              template: {
                header_title: '패스 편집',
                path_button_group_add: '추가',
                path_button_group_delete_close: '삭제/닫기',
                path_button_group_change: '변경',
                path_button_add_l: '선택한 점에 직선 추가',
                path_button_add_c: '선택한 점에 곡선 추가',
                path_button_remove: '선택한 점 삭제',
                path_button_close: '열린 점 닫기',
                path_button_open: '닫힌 점 열기',
                path_button_change_to_l: '선택한 점을 직선으로 변경',
                path_button_change_to_c: '선택한 점을 곡선으로 변경',
                done_button: '완료',
                prev_button: '닫기',
              },
            },
            template: {
              menu_upload_image: '이미지 업로드',
              menu_my_image: '내 이미지',
              menu_shape: '도형',
              menu_path: '패스',
              menu_text: '텍스트',
              layer_title: '레이어',
              layer_label_group: '그룹',
              layer_label_text: '텍스트',
              layer_label_shape: '도형',
              layer_label_image: '이미지',
              cancel_button: '취소',
              next_button: '다음',
              prev_button: '이전',
              done_button: '완료',
            },
            alert_message: {
              empty_acrylic_figure: '먼저 피규어를 만들어주세요.',
              close_acrylic_figure_editor: '디자인이 모두 삭제됩니다.<br />에디터를 종료하시겠습니까?',
            },
            error_message: {
              cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
              no_editor_container_el: '피규어 에디터를 열 수 없습니다.',
              no_pre_process: '이전 작업 복원에 실패했습니다.',
              no_acrylic_figure_editor: '피규어 에디터를 열 수 없습니다.',
            },
          },
        },
        template: {
          empty_template_guide: '나만의 스탠드를 만들어 보세요.',
        },
        FreeMobile: {
          cancel: '취소',
          done: '완료',
          set_background: '배경 설정',
          add_sticker: '스티커 추가',
          edit: '편집',
          duplicate: '복제',
          remove: '삭제',
        },
        GridMobile: {
          CopyGrid: {
            cancel: '취소',
            done: '완료',
            desc: '복제할 영역을 선택하고 완료해 주세요.',
          },
        },
        obj: {
          '배경 색상': '배경 색상',
        },
        message: {
          invalid_prev_acrylic_figure_svg: '이전 작업을 불러올 수 없습니다.',
          login_first: '로그인이 필요합니다. 먼저 로그인을 해주세요.',
          make_images: '출력용 고해상도 이미지를 생성 중입니다.<br />완료될 때까지 기다려주세요.',
          too_large_image: '스탠드 이미지가 너무 커서 생성할 수 없습니다. PC 환경에서 만들어주세요.',
          output_making_error: '제품 제작용 파일 생성에 실패했습니다.',
          load_image_error: '이미지 파일을 불러올 수 없습니다.',
          invalid_meta_data: '스탠드 데이터를 찾을 수 없습니다.',
          fail_image: '이미지 생성에 실패 했습니다.',
          '스티커를 가져올 수 없습니다.': '스티커를 가져올 수 없습니다.',
          '스티커가 없습니다. 스티커를 만들어주세요.': '스티커가 없습니다.<br />스티커를 만들어주세요.',
          '현재 모든 스티커가 1개로':
            '현재 모든 스티커가 1개로 연결되어 있어 완료할 수 없습니다.<br />스티커를 여러개로 만들어주세요.',
          cutting_line_making_error: '재단선을 생성하는 데 실패했습니다. 다시 시도해 주세요.',
          cutting_line_making_error2: '재단선을 생성하는 데 실패했습니다.',
          '스티커 에디터 안전 간격 찾을 수 없습니다.': '스티커 에디터 안전 간격 찾을 수 없습니다.',
          '스티커 에디터 안전 영역 찾을 수 없습니다.': '스티커 에디터 안전 영역 찾을 수 없습니다.',
          '안전 간격을 계산할 수 없습니다.': '안전 간격을 계산할 수 없습니다.',
          '안전 영역을 계산할 수 없습니다.': '안전 영역을 계산할 수 없습니다.',
          '스티커 에디터 앞면 이미지를 찾을 수 없습니다.': '스티커 에디터 앞면 이미지를 찾을 수 없습니다.',
          '스티커 영역을 계산할 수 없습니다.': '스티커 영역을 계산할 수 없습니다.',
          '잘못된 그리드 크기입니다.': '잘못된 그리드 크기입니다.',
          '잘못된 그리드입니다.': '잘못된 그리드입니다.',
          '재단선을 만들 수 없습니다.': '재단선을 만들 수 없습니다.',
          '스티커 이미지가 너무 커서 생성할 수 없습니다. PC 환경에서 만들어주세요.':
            '스티커 이미지가 너무 커서 생성할 수 없습니다. PC 환경에서 만들어주세요.',
          '스티커를 찾을 수 없습니다.': '스티커를 찾을 수 없습니다.',
          '디자인이 모두 삭제됩니다. 에디터를 종료하시겠습니까?':
            '디자인이 모두 삭제됩니다.<br />에디터를 종료하시겠습니까?',
          '죄송합니다. 다시 시도해 주세요.': '죄송합니다. 다시 시도해 주세요.',
          '빈 스티커입니다. 스티커를 만들어주세요 :)': '빈 스티커입니다.<br />스티커를 만들어주세요 :)',
          '너무 가깝거나 가장자리에 있는 스티커를 움직여주세요.':
            '너무 가깝거나 가장자리에 있는 스티커를 움직여주세요.',
          '복사에 실패했습니다.': '복사에 실패했습니다.',
          '스티커는 최대 20개까지 생성할 수 있습니다.': '스티커는 최대 20개까지 생성할 수 있습니다.',
          '선택된 스티커가 없습니다.': '선택된 스티커가 없습니다.',
        },
        FreeGrid: {
          '스티커 추가하기': '스티커 추가하기',
          '스티커 추가': '스티커 추가',
          '기존 스티커를 덮어쓰기 하시겠습니까?': '기존 스티커를 덮어쓰기 하시겠습니까?',
          취소: '취소',
          완료: '완료',
          편집: '편집',
          삭제: '삭제',
          스티커: '스티커',
          '배경 설정': '배경 설정',
          '만든 스티커 수정이 필요하신가요?': '만든 스티커 수정이 필요하신가요?',
          '스티커 편집하기': '스티커 편집하기',
          복제: '복제',
          '복제할 영역을 선택하고 완료해 주세요.': '복제할 영역을 선택하고 완료해 주세요.',
          이전: '이전',
          '반칸이 있습니다. 모든 빈칸을 채워주세요.': '반칸이 있습니다.<br />모든 빈칸을 채워주세요.',
        },
      },
    },
  },
  mshop_cart_and_checkout: {
    배송비추가: '+ 배송비',
    구매불가: '구매불가',
    마플샵배송: '마플샵배송',
    셀러배송: '크리에이터배송',
    디지털상품: '디지털상품',
    요청사항: '요청사항',
    '수량 변경': '수량 변경',
    배송비: '배송비',
    무료배송: '무료배송',
    도서산간비: '도서산간비',
    이상: '이상',
    '구매시 무료 배송': '구매시 무료 배송',
    '결제 완료 후 7일간 다운로드 가능': '결제 완료 후 7일간 다운로드 가능',
    '결제 완료 후': '결제 완료 후',
    '7일간 다운로드': '7일간 다운로드',
    가능: '가능',
    상품금액: '상품금액',
    주문금액: '주문금액',
    '주문 정보': '주문 정보',
    주문정보: '주문정보',
    '총 주문 금액': '총 주문 금액',
    수량: '수량',
    개: '개',
    '총 수량': '총 수량',
    '총 상품금액': '총 상품금액',
    '총 배송비': '총 배송비',
    '마플샵배송 도서산간': '마플샵배송 도서산간',
    '셀러배송 도서산간': '크리에이터배송 도서산간',
    '쿠폰 할인': '쿠폰 할인',
    '포인트 할인': '포인트 할인',
    '주문서 작성': '주문서 작성',
    '구매 불가 상품이 있습니다.': `구매 불가 상품이 있습니다.<br>구매 불가한 상품 아래에 있는 설명을 참고해주세요.`,
    '상품의 옵션이 변경되었습니다. 확인후 장바구니에 다시 넣어주세요.':
      '상품의 옵션이 변경되었습니다. 확인후 장바구니에 다시 넣어주세요.',
    '선택한 옵션 또는 상품이 품절되었습니다.': '선택한 옵션 또는 상품이 품절되었습니다.',
    '현재 가능한 재고는 개 입니다.': '현재 가능한 재고는 {{remaining_stock_quantity}}개 입니다.',
    '선택한 옵션 또는 상품의 재고가 부족하여 주문이 불가합니다. 수량을 변경해 주세요.':
      '선택한 옵션 또는 상품의 재고가 부족하여 주문이 불가합니다. 수량을 변경해 주세요.',
    '오류가 발생했습니다.<br>새로고침 됩니다.': '오류가 발생했습니다.<br>새로고침 됩니다.',
  },
  mshop_customer_order: {
    '배송지 변경 불가': '배송지 변경 불가',
    '상품 취소 요청 접수': '상품 취소 요청 접수',
    '취소 완료': '취소 완료',
    '확인 후 자동 취소가 완료됩니다.': '확인 후 자동 취소가 완료됩니다.',
    '취소 요청': '취소 요청',
    '문제가 발생했습니다.': '문제가 발생했습니다.',
    '상품 취소 요청': '상품 취소 요청',
    '취소 상품은 다시 복구할 수 없습니다.<br>정말 주문을 취소하시겠습니까?':
      '취소 상품은 다시 복구할 수 없습니다.<br>정말 주문을 취소하시겠습니까?',
    '스토어의 모든 "마플샵 배송" 상품들이 취소 됩니다.<br>계속 하시겠습니까?': `[ {{name}} ] 스토어의 모든 "마플샵 배송" 상품들이 취소 됩니다.<br>계속 하시겠습니까?`,
  },
  mp_checkout: {
    '쿠폰이 적용 됐습니다.': '쿠폰이 적용 됐습니다.',
    '주문서 작성': '주문서 작성',
  },
  masking_tape_modal_page: {
    title: '미리보기',
    description1: '실제로 위의 이미지와 같이 가운데 부분만 패턴으로 제작 됩니다.',
    description2:
      '* 문제가 있어보인다면, 가이드의 "패턴 꾸미기 -> 점선영역" 부분을 다시 확인하고 작업해주세요.',
    cancel: '가이드 확인하기',
    done: '계속하기',
  },
  composite_preview_modal_page: {
    title: '미리보기',
    description1: '* 업로드 한 디자인 그대로 인쇄되니 상세페이지 내 가이드를 확인해 주세요.',
    description2: '* 컬러 모드 차이로 인해 화면과 실제 상품의 색상이 미세하게 다를 수 있습니다.',
    cancel: '가이드 확인하기',
    done: '계속하기',
    back: '만들기툴 돌아가기',
  },
  stores_delivery_company_info: {
    '배송 방법': '배송 방법',
    '일반 택배': '일반 택배',
    '기본 배송비': '기본 배송비',
    '도서 산간비': '도서 산간비',
    '배송 지역': '배송 지역',
    전국: '전국',
    '배송 기간': '배송 기간',
    '상품 출고 후 영업일':
      '상품 출고 후 영업일 기준 1~3일 이내 수령이 가능하며 도서 산간 지역이거나 택배사의 물량이 많은 경우 기간이 조금 더 소요될 수 있습니다.',
    '무료 배송 조건': '무료 배송 조건',
    '제작 소요 기간': '제작 소요 기간',
    없음: '없음',
    '이상 구매 시 무료 배송': '이상 구매 시 무료 배송',
    배송기간: '배송기간',
    '지금 주문하면':
      '지금 주문하면&nbsp;<span class="pd-guide__start-end-strong">{{start}} ~ {{end}}&nbsp;</span>사이에 출발해요!',
    '특별함을 담아 제작해서 배송해드려요.':
      '특별함을 담아 제작해서 배송해드려요.<br />설레는 마음으로 기다려주세요!',
    배송정보: '배송정보',
  },
  plus_admin: {
    pa1: '크리에이터 관리',
    pa2: '소속 크리에이터의 스토어를 추가하고 관리해 보세요.',
    pa3: '스토어',
    pa4: '라벨',
    pa5: '크리에이터 정보',
    pa6: '스토어 생성',
    pa7: '초기화',
    pa8: '검색',
    pa9: '라벨 없음',
    pa10: '중요 유튜버',
    pa11: '중요 인스타',
    pa12: '중요 아티스트',
    pa13: '중요 기타',
    pa14: '문제, 이슈',
    pa15: '기타',
    pa16: '부평구문화재단',
    pa17: '로고',
    pa18: '라벨',
    pa19: 'SNS',
    pa20: '스토어 이름',
    pa20_1: '필터관리',
    pa21: '생성시간',
    pa22: '크루 이름',
    pa23: '크리에이터',
    pa24: '상품 수',
    pa25: '공개',
    pa26: '비공개',
    pa27: '소개',
    pa28: '링크',
    pa29: '스튜디오',
    pa30: '편집',
    pa31: '스토어 라벨을 수정하는 데 실패했습니다.',
    pa32: '스토어 생성',
    pa33: '샵 도메인',
    pa34: '크리에이터 Email',
    pa35: '스토어 도메인은 모두 소문자로 저장됩니다.',
    pa36: '추가',
    pa37: '이메일을 입력해 주세요.',
    pa38: '{{label}}을 입력해 주세요.',
    pa39: '유효한 도메인을 입력하세요.',
    pa40: '스토어를 생성했습니다.',
    pa41: '스토어를 생성하는 데 실패했습니다.',
    pa42: '스토어 편집',
    pa43: '수정',
    pa44: '삭제',
    pa45: '등록',
    pa46: '스토어 소개',
    pa47: '심사평',
    pa48: '크루 이름',
    pa49: '참고 url',
    pa50: '첨부파일',
    pa51: '공개 상품 수',
    pa52: '비공개 상품 수',
    pa53: '스토어 주소',
    pa54: '스튜디오 주소',
    pa55: 'HOT 크리에이터',
    pa56: '스토어 썸네일',
    pa57: '썸네일 업로드',
    pa58: '운영 중지',
    pa59: '내부 규정 위반',
    pa60: '판매 승인',
    pa61: '초대한 크리에이터',
    pa62: '스토어 보기',
    pa63: '크루 몰 홈 상품 노출',
    pa64: '브랜드 홈 노출',
    pa65: '탈퇴 희망',
    pa66: '타투 판매',
    pa67: '예',
    pa67_1: '아니오',
    pa68: '불합격 상태입니다.',
    pa69: '정말로 스토어의 크리에이터를 삭제하시겠습니까?',
    pa70: '정말로 해당 스토어를 삭제하시겠습니까?',
    pa71: '썸네일 업로드 실패',
    pa72: '썸네일 업로드 성공',
    pa73: '썸네일 삭제 실패',
    pa74: '썸네일 삭제 성공',
    pa75: '수정 실패',
    pa76: '수정 완료',
    pa77: '스토어 이름 수정',
    pa78: '스토어 이름을 입력해 주세요.',
    pa79: '비운영 크리에이터 관리',
    pa80: '현재 운영하고 있지 않은 크리에이터 스토어를 확인하고, 운영을 시작해 보세요.',
    pa81: '스토어 ID',
    pa82: '라벨',
    pa83: 'SNS',
    pa84: '생성일',
    pa85: '미승인 스토어',
    pa86: '파일',
    pa87: '이름없음',
    pa88: '판매 승인',
    pa89: '판매 승인 실패',
    pa90: '최종 불합격',
    pa91: '최종 불합격 실패',
    pa92: '초대받은 크리에이터',
    pa93: '초대받은 크리에이터',
    pa94: '연결된 크리에이터 없음',
    pa95: '미등록 스토어',
    pa96: '불합격 스토어',
    pa97: '상품 판매 현황',
    pa98: '마플샵에서 주문 제작/배송하는 상품의 판매 현황과 수익을 확인할 수 있습니다.',
    pa98_1: '상품의 판매 현황을 확인할 수 있습니다.',
    pa99: '{{domain}} 크루를 찾을 수 없습니다.',
    pa100: '주간',
    pa101: '월간',
    pa102: '연간',
    pa103: '전체',
    pa104: '크루',
    pa105: '주문수',
    pa106: '취소',
    pa107: '수량',
    pa108: '{{unit}} 소비자 판매가',
    pa111: '디자인 수익',
    pa112: '디자인 추가 수익',
    pa113: '디자인 총 수익',
    pa114: '크리에이터 디자인 수익',
    pa115: '기업 디자인 수익',
    pa116: '기업 할인 수익',
    pa117: '기업 총 수익',
    pa118: '크리에이터 출금',
    pa119: '크리에이터 수익에 따른 출금 신청 금액을 확인하고, 정산을 진행해 주세요.',
    pa120: '상점',
    pa121: '금액',
    pa122: '실 입금액',
    pa123: '계좌정보',
    pa124: '처리 상태',
    pa125: '신청 일시',
    pa126: '처리일시',
    pa127: '취소일시',
    pa128: '입금 예정일',
    pa129: '기능',
    pa130: '처리 상태',
    pa131: '처리 중',
    pa132: '승인',
    pa133: '취소',
    pa134: '검증 여부',
    pa135: '검증된 출금',
    pa136: '검증되지 않은 출금',
    pa137: '계좌유형',
    pa138: '개인',
    pa139: '19세 미만',
    pa140: '사업자',
    pa141: '해외 거주자',
    pa142: '엑셀 다운로드',
    pa143: '날짜 변경',
    pa144: '승인처리',
    pa145: '입금하기',
    pa146: '정말 출금 거절하시겠습니까?',
    pa147: '선택된 출금이 없습니다.',
    pa148: '<h3>출금 신청</h3>출금 신청이 완료되었습니다.',
    pa149: '서버에 에러가 있습니다.',
    pa150: '세금계산서 발행을 확인하고 진행해 주세요!',
    pa151: '계좌정보 수정 잠금',
    pa152: '계좌 정보',
    pa153: '상점 이름',
    pa154: '유형',
    pa155: '입금할 금액',
    pa156: '은행 이름',
    pa157: '페이팔 계정',
    pa158: '계좌 번호',
    pa159: '페이팔 계정 캡쳐본',
    pa160: '통장 사본',
    pa161: '개인 정보',
    pa162: '이름',
    pa163: '국적',
    pa164: '휴대폰',
    pa165: '신분증 사본',
    pa166: '정보제공 동의 여부',
    pa167: '동의',
    pa168: '미동의',
    pa169: '회사 정보',
    pa170: '상호명',
    pa171: '대표자명',
    pa172: '사업자번호',
    pa173: '담당자 이메일',
    pa174: '사업장 주소',
    pa175: '업종, 업태',
    pa176: '사업자 등록증 사본',
    pa177: '개인 정보',
    pa178: '법정대리인 정보',
    pa179: '법정대리인 생년월일',
    pa180: '법정대리인 이름',
    pa181: '법정대리인 동의서',
    pa182: '원화 (한국)',
    pa183: '달러',
    pa184: '실 입금액',
    pa185: '거절',
    pa186: '페이팔 이메일',
    pa187: '계좌번호',
    pa188: '주민등록번호',
    pa189:
      '<h3>출금 불가</h3>필요한 계좌정보 내용이 모두 기입되지 않았습니다. 계좌정보 페이지에서 내용을 기입해 주세요.',
    pa190:
      '<h3>세금계산서 발행</h3>마플에서 세금계산서 발행 여부를 확인 후 입금합니다. 세금계산서 발행이 아직 안되었다면 세금계산서 발행 후 출금 신청을 해주셔야합니다. 계속하시겠습니까?',
    pa191:
      '잠깐! 계좌 정보는 정확히 입력하셨나요?<br/>계좌 정보가 잘못된 경우 출금 신청은 취소되며<br/>재신청하는 출금 신청은 다음 달에 정산 진행됩니다.',
    pa192: '입력한 입금액이 정산 금액보다 많습니다. 계속 진행하려면 확인을 눌러주세요.',
    pa193: '기업 출금',
    pa194: '기업 출금 신청 금액을 확인하고, 출금 신청해 주세요.',
    pa195: '마플샵 분석',
    pa196: '스토어 초대',
    pa197: '{n}개',
    pa198: '판매 승인된 초대',
    pa199: '샵 오픈 승인',
    pa200: '오픈한 샵',
    pa201: '제품 생성 샵',
    pa202: '제품 미생성 샵',
    pa203: '샵 미오픈',
    pa204: '가입한 크리에이터중 합격메일 통지후 1주일 넘도록 제품 생성을 하지 않은 스토어수',
    pa205: '샵별 평균 상품 수',
    pa206: '1개 이상 팔린 샵',
    pa207: '합격 크리에이터',
    pa208: '불합격 크리에이터',
    pa209: '출금 금액',
    pa210: '정산 / 출금 누적 금액',
    pa211: '시간별 상품 생산 통계',
    pa212: '크리에이터 판매금액 / 판매량 Top 10',
    pa213: '크리에이터별 상품 생성 개수 Top15',
    pa214: '크리에이터 통계',
    pa215: '크리에이터 신청 분류',
    pa216: '주별 매출',
    pa217: '매출 누적 통계',
    pa218: '주별 접수자 수',
    pa219: '접수자 누적 통계',
    pa220: '주별 크리에이터 오픈 수',
    pa221: '크리에이터 오픈 누적 통계',
    pa222: '주별 상품 생성 수',
    pa223: '상품 생성 누적 통계',
    pa224: '접수자 수',
    pa225: '크리에이터 오픈 수',
    pa226: '상품 생성 수',
    pa227: '매출액',
    pa228: '날짜',
    pa229: '주문 수',
    pa230: '생성 수',
    pa231: '실제 크리에이터 수',
    pa232: '크리에이터 신청 수',
    pa233: '크리에이터별 종류',
    pa234: '크리에이터별 매출액',
    pa235: '크리에이터별 판매량',
    pa236: '월별 출금 금액 (만원)',
    pa237: '정산 금액 (만원)',
    pa238: '출금 금액 (만원)',
    pa239: '마플샵 디자이너 목록',
    pa240: '전체',
    pa241: '신청',
    pa242: '신청일',
    pa243: '상태',
    pa244: '상세 보기',
    pa245: '마플샵 디자이너 프로필',
    pa246: '인스타그램',
    pa247: '유튜브',
    pa248: '기타',
    pa249: '포트폴리오',
    pa250: '디자이너 포트폴리오',
    pa251: '해당 요청이 실패하였습니다.',
    pa252: '해당 디자이너 신청을 승인했습니다.',
    pa253: '해당 디자이너 신청을 거절했습니다.',
    pa254: '전체 상품',
    pa255: '공개 상품',
    pa256: '비공개 상품',
    pa257: '판매 보류 상품',
    pa258: '검수 완료 상품',
    pa258_1: '검수 완료',
    pa259: '검수 중인 상품',
    pa260: '모든 상품',
    pa261: '의류',
    pa262: '폰케이스/테크',
    pa263: '패션잡화',
    pa264: '홈데코/리빙',
    pa265: '스티커/지류',
    pa266: '액자',
    pa267: '문구/오피스',
    pa268: '디지털굿즈',
    pa269: '키즈/애견',
    pa270: '일반 상품',
    pa271: '디지털 상품',
    pa272: '검수할 상품이 없습니다!',
    pa273: '판매 보류',
    pa274: '남은 검수',
    pa275: '통과',
    pa276:
      '검수 확인 된 상품을 다시 확인하고 싶을땐 "전체 상품 -> 검수 완료" 로 이동해 주세요. \n 디자인이 수정되면 다시 검수를 받아야하기 때문에 검수한 상품이 또 나올 수 있습니다.',
    pa277: '확인',
    pa278: '키보드 작동을 허용하시겠습니까? \n 오른쪽 Key [➡️] 통과 \n 아래쪽 Key [⬇️️] 판매 보류',
    pa279: '천명이상',
    pa280: '{{number}}명',
    pa281: '크리에이터명',
    pa282: '준비중',
    pa283: '구독자수',
    pa284: '유튜버',
    pa285: '디자이너',
    pa286: '상품 디자인 검수',
    pa287: '유튜브판매 크리에이터',
    pa288: '크리에이터 팁 관리',
    pa289: '신고 접수',
    pa290: '상품 검수',
    pa291: 'PB상품 주문서',
    pa292: 'PB상품 주문서 상세보기',
    pa293: 'PB상품 목록',
    pa294: '기업 계좌 등록',
    pa295: '제품 퀄리티 관리',
    pa296: '기업 정산',
    pa297: '사이트 관리',
    pa298: '검수 필요 상품 수',
    pa299: '스토어 바로가기',
    pa300: '검수 바로가기',
    pa301: '가격',
    pa302: 'PB상품 교환/환불(완료:{{settled}} / 전체:{{total}})',
    pa303: '환불 요청',
    pa304: '환불 완료',
    pa305: '취소 요청',
    pa306: '취소 완료',
    pa307: '유효하지 않은 search_condition_key',
    pa308: '주문일 기준',
    pa309: '최근 1개월',
    pa310: '최근 3개월',
    pa311: '최근 6개월',
    pa312: '최근 1년',
    pa313: '내림차순',
    pa314: '오름차순',
    pa315: '검색 조건',
    pa316: '택배',
    pa317: '묶음배송',
    pa318: '퀵배송',
    pa319: '방문수령',
    pa320: '개별배송',
    pa321: '환불',
    pa322: '교환',
    pa323: '주문일',
    pa324: '주문한 사람',
    pa325: '결제수단',
    pa326: '주문 상품',
    pa327: '요약 정보',
    pa328: '주문 상품 정보',
    pa329: '주문 상품 ID',
    pa330: '상품 이름',
    pa331: '주문 수량',
    pa332: '주문 금액',
    pa333: '배송 정보',
    pa334: '배송 ID',
    pa335: '배송 종류',
    pa336: '받는 사람',
    pa337: '운송장',
    pa338: '연락처',
    pa339: '주소',
    pa340: '메모',
    pa341: '연락처1',
    pa342: '연락처2',
    pa343: '주문자도 배송알림',
    pa344: '없음',
    pa345: '마플샵상품코드',
    pa346: '이미지',
    pa347: '카테고리',
    pa348: '마플샵 스튜디오',
    pa349: '옵션',
    pa350: 'PB 상품 교환/환불',
    pa351: '회수 ID',
    pa352: '생성 날짜/수정 날짜',
    pa353: '상태',
    pa354: '환불 금액',
    pa355: '운송장 번호',
    pa356: '환불 상품',
    pa357: '상품 ID (회수/주문)',
    pa358: '회수 종류',
    pa359: '주문 상품 이름',
    pa360: '수량 (회수/주문)',
    pa361: '환불 상품이 아닙니다.',
    pa362: '사유',
    pa363: '추가 설명',
    pa364: '교환/환불 상품',
    pa365: '전체',
    pa366: '진행중',
    pa367: '처리 완료',
    pa368: '결제 수단',
    pa369: '전액 할인',
    pa370: '실시간계좌이체',
    pa371: '이니시스 실시간계좌이체',
    pa372: '신용카드',
    pa373: '삼성페이',
    pa374: 'PAYCO',
    pa375: '네이버페이',
    pa376: 'SSGPAY',
    pa377: 'LPAY',
    pa378: 'Alipay',
    pa379: '이니시스 신용카드',
    pa380: '무통장 입금',
    pa381: '오프라인 카드',
    pa382: '오프라인 현금',
    pa383: '가상계좌',
    pa384: '카카오페이',
    pa385: '토스페이',
    pa385_1: '카카오쇼핑 주문',
    pa386: '입력하신 정보로 기업 수익이 정산되므로 정확히 기입해 주세요.',
    pa387: '페이팔 이메일',
    pa388: '출금 신청 중에는 계좌를 수정할 수 없습니다. 잘못 입력된 정보가 있다면 문의 바랍니다.',
    pa389: '페이팔 계정 화면(선택)',
    pa390: '통장 사본 업로드(선택)',
    pa391: '법정대리인 자격이 되지 않습니다.',
    pa392: '미성년자는 19세 미만 유형으로 신청해야 합니다.',
    pa393: '오류가 있습니다. 개발팀에 문의 바랍니다.',
    pa394: 'paypal 계정(이메일)',
    pa395: '다음 내용이 유효하지 않습니다.',
    pa396: '크리에이터의 상품 판매량과 정산 금액을 확인할 수 있습니다.',
    pa397: '총 출금액',
    pa398: '합계',
    pa399: '엑셀 다운로드',
    pa400: '총 입금액',
    pa401: '잔액',
    pa402: '브랜드 관리',
    pa403: '색상, 로고 등 기업의 브랜드 아이덴티티를 반영하여 사이트에 적용해 보세요.',
    pa404: '메인 페이지 GNB 설정',
    pa405: 'PC용 로고',
    pa406: '권장 사이즈 (가로 80~920px, 세로 88px)',
    pa407: '모바일용 로고',
    pa408: '권장 사이즈 (가로 60~460px, 세로 56px)',
    pa409: '첨부된 파일이 없습니다.',
    pa410: '파일 업로드',
    pa411: '푸터(Footer) 설정',
    pa412: '기업명',
    pa413: '기업명을 입력해 주세요.',
    pa414: '기업 공식 홈페이지 URL',
    pa415: '페이스북 URL',
    pa416: '인스타그램 URL',
    pa417: '유튜브 URL',
    pa418: '트위터 URL',
    pa419: '저장하기',
    pa420: '이미지 파일이 아닙니다.',
    pa421: '저장되었습니다',
    pa422: '프로모션 배너 관리',
    pa423: '메인에 노출할 프로모션 배너를 만들고 관리해 보세요.',
    pa424: '노출하고 싶은 프로모션 배너를 만들고 관리해 프로모션 배너 만들기보세요.',
    pa425: '전체 프로모션',
    pa426: '메인 정렬 변경',
    pa427: '프로모션 배너 관리',
    pa428: '메인에 노출할 프로모션 배너를 만들고 관리해 보세요.',
    pa429: '예약 게시',
    pa430: '사이트 메인 페이지에 등록할<br/>프로모션 배너를 만들어 보세요.',
    pa431: '드래그하여 원하는 대로 정렬을 변경하실 수 있습니다.',
    pa432: '프로모션 배너 비공개로 변경하기',
    pa433: '노출 해제된 배너는 비공개로 전환됩니다.<br />지금 바로 메인에서 노출 해제 하시겠습니까?',
    pa434: '프로모션 배너 만들기',
    pa435: '이미지 선택',
    pa436: 'PC용 배너 이미지를 등록해 주세요.',
    pa437: '권장 사이즈',
    pa438: '모바일용 배너 이미지를 등록해 주세요.',
    pa439: '배너 생성',
    pa440: 'PC 프로모션 제목 설정',
    pa441: '내용 입력',
    pa442: '다국어를 지원하며, 입력 시 좌측에서 미리보기 가능합니다.',
    pa443: '제목 입력',
    pa444: '색상 선택',
    pa445: '콘텐츠 상세',
    pa446: '상품 상세',
    pa447: '크리에이터샵 홈',
    pa448: '마플샵 플러스 홈',
    pa449: '상품 목록 + 카테고리 조합',
    pa450: '크리에이터 신청',
    pa451: '모바일 프로모션 제목 설정',
    pa452: '프로모션 URL · 공개 설정',
    pa453: 'URL 주소',
    pa454: '배너 클릭 시 이동할 URL을 입력해 주세요.',
    pa455: '링크 타입',
    pa456: '현재 창',
    pa457: '새 창',
    pa458: '예약',
    pa459: '한국 표준 시간(KST) 기준이며, 설정한 게시일 전까지 비공개 상태로 유지됩니다.',
    pa460: '앱 링크 유형',
    pa461: '크리에이터 또는 마플샵 플러스 이름을 입력해 주세요.',
    pa462: '배너 생성',
    pa463: '저장 완료',
    pa464: '초기화',
    pa465: '마플샵 크리에이터 신청',
    pa466: '모바일용 이미지를 등록해 주세요.',
    pa467: '저장되었습니다.',
    pa468: '이미지 용량은 {{size}}KB까지 업로드 가능합니다.',
    pa469: '프로모션 배너를 삭제하시겠습니까?',
    pa470: '콘텐츠 관리',
    pa471: '다양한 테마의 콘텐츠를 만들고 관리해 보세요.',
    pa472: '전체 콘텐츠',
    pa473: '메인 정렬 변경',
    pa474: '다양한 테마를 주제로 상품을 구성하여<br/>콘텐츠를 새로 만들어 보세요.',
    pa475: '저장하기',
    pa476: '콘텐츠 작성하기',
    pa477: '글과 사진, 상품, 링크 등을 활용하여 콘텐츠를 작성해 보세요.',
    'pa477-1':
      '(콘텐츠 이미지 권장 사이즈 : 가로 1120px * 세로 5000px 이하 / 등록 가능 형식 : jpg, jpeg, png, gif, webp / 용량 : 5MB 이하)',
    pa478: '미리보기',
    pa479: '발행하기',
    pa480: '지정된 비율의 썸네일 이미지를 등록해 주세요.',
    'pa480-1': '썸네일 이미지 필수 사이즈',
    'pa480-2': '등록 가능 형식 : jpeg, png / 용량 5MB 이하',
    'pa480-3': '이미지 사이즈 확인',
    'pa480-4': '이미지의 사이즈가 맞지 않습니다.<br/>확인 후 다시 업로드해 주세요.',
    'pa480-5': '이미지 용량 확인',
    'pa480-6': '이미지의 용량이 초과되었습니다.<br/>확인 후 다시 업로드해 주세요.',
    'pa480-7': '이미지 확장자 확인',
    'pa480-8': '이미지의 확장자가 맞지 않습니다.<br/>확인 후 다시 업로드해 주세요.',
    pa481: '피드 타입',
    pa482: '마플샵 나우',
    pa483: '이미지 상세 설정',
    pa484: '메인 등록 여부',
    pa485: '썸네일에서 상품 미리보기',
    pa486: '마감 여부',
    pa487: '콘텐츠 카테고리',
    pa488: '선택해 주세요',
    pa489: '필요한 내용이 기입되지 않았습니다.',
    pa490: '콘텐츠 제목',
    pa491: '제목을 입력해 주세요.',
    pa492: '썸네일 텍스트 위치',
    pa493: '상단',
    pa494: '하단',
    pa495: '키 비주얼 이미지',
    'pa495-1': '키 비주얼 이미지 필수 사이즈',
    'pa495-2': '등록 가능 형식 : jpeg, png / 용량 5MB 이하',
    pa496: '이미지 업로드',
    pa497: '키 비주얼 텍스트',
    pa498: '키 비주얼 텍스트 색상',
    pa499: '콘텐츠를 메인에서 비공개 하시겠습니까?',
    pa500: '문제가 발생하였습니다.',
    pa501: '상품 페이지 관리',
    pa502: '상품 페이지의 카테고리를 꾸며 보세요.',
    pa503: '카테고리 묶음을 만들어보세요.',
    pa504: '모음',
    pa505: '정렬',
    pa506: '타입선택',
    pa507: '최소 하나의 언어에 제목을 입력해 주세요.',
    pa508: '카테고리를 추가해 주세요.권장 개수: small, medium - 6개이상, large - 4개이상',
    pa509: '카테고리는 필수입니다.',
    pa510: '카테고리 추가',
    pa511: '카테고리 설정(필수)',
    pa512: '이미지 변경',
    pa513: '스타일 변경(선택)',
    pa514: '카테고리 선택',
    pa515: '라이브 방송 관리',
    pa516: '메인에 노출할 라이브 방송을 만들고 관리해 보세요.',
    pa517: '전체 라이브 방송',
    pa518: '메인 정렬 변경',
    pa519: '사이트 메인 페이지에 등록할<br/>라이브 방송을 만들어 보세요.',
    pa520: '라이브 방송 제목 설정',
    pa521: '제목 입력',
    pa522: '설명 입력',
    pa523: '다국어를 지원합니다.',
    pa524: '라이브방송 URL · 공개 설정',
    pa525: 'URL 주소',
    pa526: 'iframe 동영상 가져오기',
    pa527: '저장하기',
    pa528: '라이브 방송 만들기',
    pa529: '올바른 iframe 형태가 아닙니다.',
    pa530: '라이브 방송을 삭제하시겠습니까?',
    pa531: '제목 입력',
    pa532: '다국어를 지원합니다.',
    pa533: '설명 입력',
    pa534: '다국어를 지원합니다.',
    pa535: '라이브 방송 제목 설정',
    pa536: '라이브방송 URL / 공개 설정',
    pa537: 'URL 주소',
    pa538: '배너 클릭 시 이동할 URL을 입력해 주세요.',
    pa539: 'iframe 동영상 가져오기',
    pa540: '메인 화면에 노출될 iframe 주소를 입력해 주세요.',
    pa541: '대표상품 URL 주소',
    pa542: '메인 화면에 노출될 대표상품 URL 주소를 입력해 주세요.',
    pa543: '공개 상태',
    pa544: '메인 공개 여부',
    pa545: '노출 해제된 라이브 방송은 비공개로 전환됩니다.<br/>지금 바로 메인에서 노출 해제 하시겠습니까?',
    pa546: '매니저 관리',
    pa547: '사이트 관리를 위해 매니저를 추가하고, 권한 범위를 설정해 보세요.',
    pa548: '기능',
    pa549: '크루 권한 목록',
    pa550: '권한',
    pa551: '마스터',
    pa552: '권한 영역: 크루',
    pa553: '크리에이터 출금 내역 조회',
    pa554: '크리에이터 출금 승인',
    pa555: '크리에이터 출금 취소',
    pa556: '크루 계좌 등록',
    pa557: '크루 출금 신청',
    pa558: '크루 출금 조회',
    pa559: '모든 스튜디오 접근',
    pa560: '상점 라벨 수정',
    pa561: '상점 만들기',
    pa562: '상점 삭제',
    pa563: '상점 크리에이터 변경',
    pa564: '상점 이름 변경',
    pa565: '상품 홈 노출 여부 수정',
    pa566: '주문 및 판매분석 수익 조회',
    pa567: '매니저 권한 수정',
    pa568: '매니저 삭제',
    pa569: '매니저 추가',
    pa570: '사이트 관리',
    pa571: '크루 어드민 접근',
    pa572: '권한 영역: 스튜디오',
    pa573: '크리에이터 출금 내역 조회',
    pa574: '출금 요청',
    pa575: '계좌 정보 조회',
    pa576: '상품 제작',
    pa577: '정산 내역 조회',
    pa578: '주문 내역 조회',
    pa579: '판매 분석 조회',
    pa580: '상점 이름 변경',
    pa581: '상품 삭제',
    pa582: '상품 판매 보류',
    pa583: '주문 및 판매분석 수익 조회',
    pa584: '정말 삭제하시겠습니까?',
    pa585: '서버 에러 발생, 개발팀에 문의 바랍니다.',
    pa586: '{label}을 입력해 주세요.',
    pa587: '매니저 추가',
    pa588: '매니저 이메일',
    pa589: '크루 권한',
    pa590: '크리에이터 등록',
    pa591: '불합격',
    pa592: '성인 인증 필요',
    pa593: '실패했습니다',
    pa594: '권한 목록표',
    pa595: '크루 권한 수정',
    pa596: '<h3>출금 불가</h3>출금 가능한 금액이 없습니다.',
    pa597: '출금 가능 금액이 최소 금액 만원을 넘지 않습니다.',
    pa598: '은행',
    pa599: '상품 목록',
    pa600: '썸네일 이미지를 먼저 등록해 주세요.',
    pa601: '내용을 입력해 주세요',
    pa602: '썸네일',
    pa603: '키 비주얼',
    pa604: '이미지의 비율을 확인해 주세요. 예상 높이 : {{height}}',
    pa605: '썸네일 이미지가 없습니다.',
    pa606: '키 비주얼 이미지가 없습니다.',
    pa607: '메인',
    pa608: '메인 브랜드 색상 선택',
    pa609: '브랜드 색상',
    pa610: '사이트의 메인 상단 메뉴 및 포인트 색상이 모두 반영됩니다.',
    pa611: '메타 태그 설정',
    pa612: 'OpenGraph 이미지',
    pa613: 'OpenGraph 제목',
    pa614: 'OpenGraph 내용',
    pa615: '권장 사이즈 (가로 1200px, 세로 600px)',
    pa616: '미리보기를 확인후 저장해 주세요.',
    pa617: '저장하면 되돌릴 수 없습니다. 저장하시겠습니까?',
    pa618: '준비중..',
    pa619: '신청날짜',
    pa620: '저장 실패했습니다.',
    pa621:
      '메인 크리에이터는 최대 7명까지 선택하실 수 있습니다.<br>다른 크리에이터를 선택 해제 후, 진행해 주세요.',
    pa622: '메인 크리에이터 관리',
    pa623: '메인에 노출할 크리에이터들을 선택해 주세요. 드래그하여 노출 순서 변경이 가능합니다.',
    pa624:
      '아래의 크리에이터 목록에서 사이트 메인에 노출할 크리에이터를 선택해 주세요.<br />메인 크리에이터는 최대 7명까지 등록 가능합니다.',
    pa625: '전체 크리에이터',
    pa626: '실 입금액을 입력해야 합니다.',
    pa627: '실 입금액을 숫자로 입력해야 합니다.',
    pa628: '이미 처리된 출금 요청입니다.',
    pa629: '이미 취소된 출금 요청입니다.',
    pa630: '주민번호',
    pa631: '제품 퀄리티 추가',
    pa632: '제품 퀄리티 수정',
    pa634: '사이트관리-배너',
    pa635: '{{period}} 게시 예정',
    pa636: '{{period}} 게시 종료',
    pa637: '{{period}} 게시 중',
    pa638: '{{date}} 부터 게시 중',
    pa639: '배너 소유권이 없습니다.',
    pa640: '사이트관리-브랜드',
    pa641: '검수 요청 상품 수',
    pa642: '검수 페이지',
    pa643: '해당 디자이너({{designer_id}})를 찾을 수 없습니다.',
    pa644: '사이트관리-라이브 방송',
    pa645: '라이브 방송 수정',
    pa646: '크리에이터 이메일이 없습니다.',
    pa647: '이미 등록된 이메일입니다.',
    pa648: '요청한 크루 권한({{level}})이 없습니다.',
    pa649: 'crew가 없습니다.',
    pa650: 'certification가 없습니다.',
    pa651: '해당하는 주문서가 없습니다.',
    pa652: '유효하지 않은 차감 금액입니다.',
    pa653: '주문서 ID',
    pa654: '추가하기',
    pa655: '엑셀 업로드',
    pa666: '요청',
    pa667: '사이트관리-타이틀',
    pa668: '타이틀 관리',
    pa669: '메인의 각 영역 타이틀을 변경하여 사이트에 적용해 보세요.',
    pa670: '메인 크리에이터 타이틀 설정',
    pa671: '다중 언어를 지원합니다.',
    pa672: '제목을 입력해 주세요. (공백 포함 최대 29자)',
    pa673: '내용 입력',
    pa674: '콘텐츠 타이틀 설정',
    pa675: '해당하는 스토어가 없습니다.',
    pa676: '해당하는 라벨을 찾을 수 없습니다.',
    pa677: '스토어의 해당 속성은 수정할 수 없습니다.',
    pa678: '스토어의 해당 속성을 수정할 권한이 없습니다.',
    pa679: '바뀌는 쉐어 비율은 이전에 만들어진 상품들에는 적용되지 않습니다.',
    pa680: '수익 쉐어 비율',
    pa681: '플러스',
    pa682: '이미 사용중인 크리에이터 이메일입니다.',
    pa683: 'all 크루가 존재하지 않습니다.',
    pa684: 'marpple 크루가 존재하지 않습니다.',
    pa685: '크리에이터에게 맞는 크루 권한이 없습니다.',
    pa686: '{{type}}가 없습니다.',
    pa687: '도메인이 없습니다.',
    pa688: '스토어 이름이 없습니다.',
    pa689: '중복된 도메인이 존재합니다.',
    pa690: '콘텐츠 설명',
    pa691: '상품 카테고리',
    pas690: '스타일 관리',
    pas691: '사이트관리-스타일 관리',
    pas692: '스타일에 따른 제품 사진을 등록해 보세요.',
    pas693: '메인 공개',
    pas694: '메인 비공개',
    pas695: '상품 추가',
    pas696: '상품 수정',
    pas697: '상품 주소',
    pas698: '상품의 주소를 입력 해주세요.',
    pas699: '배너가 공개 처리 되었습니다.',
    pas700: '배너가 비공개 처리 되었습니다.',
    pas701: '일시적인 오류 입니다.\n다시 시도해 주세요.',
    pas702: '상품이 추가 되었습니다.',
    pas703: '상품이 수정 되었습니다.',
    pas704: '이미지 용량은 {{size}}KB까지 업로드할 수 있습니다.',
    pas705: '이미지 사이즈는 {{size}}까지 업로드할 수 있습니다.',
    pas706: '투데이 상세',
    pa707: '검색 엔진 노출',
    pa708: '태그',
    pa709: 'SEO - Description',
    pa710: 'SEO - Title',
  },
  event: {
    e1: '댓글을 입력해 주세요.\n글과 무관한 댓글, 악플 등은 삭제될 수 있습니다 :)',
    e2: '1000자 이하',
    e3: '이벤트 삭제',
    e4: '저장하기를 누르면 되돌릴 수 없습니다. 공개는 미리보기에서만 할수있습니다.',
    e5: '꼭 저장 후 미리보기를 눌러주세요.',
    e6: '이벤트명',
    e7: '리스트 부제목입니다.',
    e8: '이벤트 기간:',
    e9: '리스트의 섬네일 (350x350)',
    e10: '이미지 변경',
    e11: '가로 992px',
    e12: '모바일용 배너 (600x600)',
    e13: '유저확인',
    e14: '문제가 발생했습니다. 다시 시도해 주세요.',
    e15: '이메일을 확인해 주세요.<br>만약 몇 분 동안 오지 않는다면,<br>스팸메일함을 확인해 주세요.',
    e16: '5자이상 1000자 이하로 작성해 주세요.',
    e17: '이벤트 기간이 아니거나 일시적인 오류입니다. 다시 시도해 주세요.',
    e18: '일시적인 오류입니다. 다시 시도해 주세요.',
    e19: '새로운 이벤트 생성하기',
  },
  creator: {
    feed: {
      f1: '피드 소유권이 없습니다.',
    },
  },
  bank_account: {
    ba1: '계좌 정보 등록',
    ba2: '입력하신 계좌 정보로 수익을 정산 받으실 수 있습니다.',
    ba3: '크리에이터 유형 선택',
    ba4: '만 14세 ~ 19세',
    ba5: '개인',
    ba6: '사업자',
    ba7: '해외 거주자',
    ba8: '계좌 정보 등록',
    ba9: '은행',
    ba10: '입금 받으실 계좌의 은행을 입력해 주세요.',
    ba11: '계좌 소유주',
    ba12: '계좌 소유주명을 입력해 주세요.',
    ba13: '계좌번호',
    ba14: '- 없이 숫자만 입력해 주세요.',
    ba15: '통장 사본(선택 사항)',
    ba16: '첨부된 파일이 없습니다.',
    ba17: '페이팔(PayPal) 계정',
    ba18: '페이팔(PayPal) 계정 이메일을 입력해 주세요.',
    ba19: '페이팔(Paypal) 계정 캡쳐본',
    ba20: '페이팔(PayPal) 웹사이트&nbsp;{{- link}}에 로그인합니다.',
    ba21: '우측 상단 톱니바퀴 모양의 설정 버튼을 누르면 나오는 계정 페이지를 캡쳐하여 업로드 바랍니다.&nbsp;{{- link}}',
    ba22: '파일 예시 보기',
    ba23: '개인 정보 등록',
    ba24: '마플샵 크리에이터의 본인 명의로 가입된 휴대전화를 통하여 본인 인증이 필요합니다.',
    ba25: '마플샵에서 개인에게 발생되는 수익에 따라 원천징수를 통해 금융 소득신고가 이뤄집니다.',
    ba26: '휴대폰 본인 인증',
    ba27: '크리에이터님의 실명을 입력해 주세요.',
    ba28: '국적',
    ba29: '크리에이터님의 국적을 입력해 주세요.',
    ba30: '주민등록번호',
    ba31: '주민등록번호 앞자리',
    ba32: '신분증 사본(선택 사항)',
    ba33: '서비스 약관 동의',
    ba34: '개인정보 활용 동의 (필수)',
    ba35: '수익 지급, 원천징수 처리, 지급명세서 제출, 개인사업자 사업소득 신고, 휴·폐업자 소득신고 및 처리 결과 회신을 위해서는 절차상 이름, 주민등록번호가 필수사항입니다.',
    ba36: '{{- link}}에 따른 개인정보 활용에 동의합니다.',
    ba37: '동의합니다.',
    ba38: '수익 지급과 세금 신고를 위한 필수 정보이기 때문에 동의하시지 않을 경우 수입 지급이 불가능합니다. 그래도 동의를 해제하시겠습니까?',
    ba39: '정산 약관 동의',
    ba40: '본인은 마플코퍼레이션의 서비스인 마플샵의 크리에이터로 해당 서비스를 통해 발생하는 수익을 정산받기 위해 마플샵 스튜디오 이용약관에 따라 위와 같이 정산에 필요한 내용들을 기입하며 정보 제공에 동의합니다.',
    ba41: '위에 입력한 내용은 모두 사실이며, 정보를 허위로 기재하거나 잘못 기입하여 발생한 손실에 대해서는 이의를 제기하거나 배상을 청구하지 않을 것을 약정합니다.',
    ba42: '법정대리인 본인 인증 및 동의서 등록',
    ba43: '마플샵 크리에이터가 만 14세 ~ 만 19세의 미성년자인 경우, 마플샵에서의 수익 활동을 위해 법정대리인(부모 등)의 동의가 필요합니다.',
    ba44: '마플샵에서 개인에게 발생되는 수익에 따라 원천징수를 통해 금융 소득신고가 이뤄집니다.',
    ba45: '이에 따라 법정대리인 본인 명의로 가입된 휴대전화를 통하여 본인 인증이 필요하며, 본인 인증 후 법정대리인 동의서 양식에 자필(수기)로 작성한 서류의 스캔본 혹은 촬영본을 첨부해야 합니다.',
    ba46: '법정대리인 휴대폰 본인 인증',
    ba47: '법정대리인 동의서 양식 다운로드',
    ba48: '법정대리인 휴대폰 번호',
    ba49: '법정대리인 생년월일',
    ba50: '법정대리인 이름',
    ba51: '법정 대리인 동의서 등록',
    ba52: '전자세금계산서 발행 가능 여부 선택',
    ba53: '전자세금계산서 발행이 가능하신가요?',
    ba54: '사업자 유형은 전자세금계산서 발행이 가능한 경우에 선택 가능합니다.',
    ba55: '크리에이터 유형 선택 이전에 전자세금계산서 발급 가능 여부를 확인 부탁드리며, 전자세금계산서 발행이 불가한 사업자는 개인 유형으로 등록 진행해 주세요.',
    ba56: '전자세금계산서 발행 가능',
    ba57: '전자세금계산서 발행 불가',
    ba58: '회사 정보 등록',
    ba59: '상호명',
    ba60: '회사 상호명을 입력해 주세요.',
    ba61: '대표자명',
    ba62: '회사 대표자명을 입력해 주세요.',
    ba63: '사업자번호',
    ba64: '담당자 이메일',
    ba65: '이메일 주소를 입력해 주세요.',
    ba66: '사업장 주소',
    ba67: '사업장 주소를 입력해 주세요.',
    ba68: '업태',
    ba69: '회사 업태를 입력해 주세요.',
    ba70: '업종',
    ba71: '회사 업종을 입력해 주세요.',
    ba72: '사업자 등록증 사본',
    ba73: '해외 거주자 정산 진행 조건 충족 여부 확인',
    ba74: '해외 거주자 정산 진행 조건',
    ba75: '해외에 연간 183일 이상 체류한 경우',
    ba76: '마플샵 수익 외, 대한민국 국세청에 신고되는 소득이 없는 경우',
    ba77: "본인은 '해외 거주자 정산 진행 조건'을 충족합니다.",
    ba78: '환율 적용 기준',
    ba79: '입금일 기준 전월 마지막 영업일의 매매기준율을 적용하여 USD로 입금 정산됩니다.',
    ba80: '예시: 2020년 06월 지급되는 경우, 5월의 마지막 영업일인 2020년 5월 29일의 매매기준율',
    ba81: '환율 적용 기준에 따라 USD로 입금 정산 받는 것을 동의합니다.',
    ba82: '페이팔 이메일',
    ba83: '페이팔 계정 캡쳐본',
    ba84: '통장 사본 업로드(선택)',
    ba85: '페이팔 로그인 후, 우측 상단의 톱니바퀴 버튼을 누르면 나오는 계정 페이지 캡쳐본을 업로드 바랍니다.',
    ba86: '회사 정보',
    ba87: '입력하신 정보로 기업 수익이 정산되므로 정확히 기입해 주세요.',
    ba88: '본인은 마플코퍼레이션의 서비스인 마플샵 크리에이터로 해당 서비스를 통해 발생하는 수익을 정산받기 위해 마플샵 스튜디오 이용약관에 따라 위와 같이 정산에 필요한 내용들을 기입하며 정보제공에 동의합니다.',
    ba89: '위 내용은 모두 사실이며, 정보를 허위로 기재하거나 잘못 기입하여 발생한 손실에 대해서는 이의를 제기하거나 배상을 청구하지 않을 것을 약정합니다.',
    ba90: '파일확인',
    ba91: '출금 신청 시에는 계좌 정보가 잠금됩니다. 수정/변경이 불가능 합니다.',
    ba92: '휴대폰 번호',
    ba93: '주민등록번호 뒷자리',
    ba94: '마플샵 스튜디오 이용약관',
    ba95: '정산 약관 동의 (필수)',
    ba96: '전자세금계산서 발행 불가한 경우',
    ba97: '전자세금계산서 발행이 불가한 사업자는 개인 유형으로 계좌 정보를 등록해 주세요.',
    ba98: '개인 유형으로 등록하시겠습니까?',
    ba99: '사업자 유형으로 계속 진행',
    ba100: '개인 유형으로 등록',
    ba101: '파일 삭제',
    ba102: '파일 확인',
    ba103: '페이팔 계정 캡쳐본이 없습니다.',
    ba104: '앱에서는 다운받을 수 없습니다. PC 에서 진행해 주세요.',

    ba105: '약관에 동의가 필요합니다.',
    ba106: '계좌 인증 오류: {{msg}}',
    ba107: '계좌정보가 정확하지 않습니다.',
    ba108: '필요한 내용이 아직 기입되지 않았습니다.',
    ba109: '본인 혹은 법정 대리인 인증 오류가 있습니다.',
  },
  translation: {
    'Facebook Error': '페이스북 장애로 현재 페이스북 로그인을 지원할 수 없습니다.',
    MARPPLE: '마플',
    Korean: '국문',
    English: '영문',
    Japanese: '일문',

    '자세히 보기': '자세히 보기',

    마플샵: '마플샵',
    님: '{{name}}님',

    'If you move from this page, the design you created will disappear.':
      '페이지를 이동하실 경우 만드신 디자인이 사라집니다.',
    // maker
    'No Print': '없음',
    'Change Character': '캐릭터 변경',
    Text: '텍스트',
    Design: '무료디자인',
    Close: '닫기',
    Character: '캐릭터 추가',
    Wappens: '자수 패치',
    Library: '내 이미지',
    'Upload Your Design': '이미지 업로드하기',
    Image: '이미지 업로드',
    'Add To Cart': '장바구니 담기',
    'Start Creating': '상품 만들기',
    Revert: '처음으로',
    Undo: '취소',
    Redo: '다시실행',
    Delete: '삭제',
    Forward: '앞으로',
    Backward: '뒤로',
    Group: '그룹',
    Ungroup: '그룹해제',
    Horizontal: '좌우반전',
    Vertical: '상하반전',
    Left: '왼쪽',
    Center: '가운데',
    Right: '오른쪽',
    Top: '위',
    Middle: '가운데',
    Bottom: '아래',
    Font: '서체 선택',
    Spacing: '문자 간격',
    Rotate: '회전',
    Line: '행 간격',
    Opacity: '투명도',
    Lock: '잠금',
    Unlock: '잠금해제',
    'Edit Image': '이미지 편집',
    'Change Font': '서체 선택',
    Change: '상품 변경',
    'Change Product': '상품 변경',
    'Coming soon': '준비중입니다',
    'One size': 'One size',

    Share: 'Share',
    'Wish List': '찜한상품',

    'Select the image at the bottom and edit it with your finger.':
      '아래 이미지 선택후,<br>손가락으로 자유롭게 편집해보세요!',
    'Embroidery patches are not available for this product': '이 상품에는 자수패치를 사용할 수 없습니다.',
    'Embroidery patches can not be grouped': '자수패치는 그룹핑 할 수 없습니다.',
    'The font colors are not available for this product.': '이 제품에는 사용하실 수 없는 컬러입니다.',
    'The font colors or image colors are not available for this product.':
      '이 제품에는 사용하실 수 없는 컬러입니다.',
    'The image color is not available for this product. Please choose the others.':
      '이 제품에는 사용하실 수 없는 컬러입니다. 다른 색을 골라주세요.',
    'Please check if the image is on the printable area.':
      '인쇄 가능한 영역을 벗어났습니다.<br>이미지 위치 및 크기를 확인해 주세요.',
    'Some image size is too large. Please set the image size smaller.':
      '40센치가 넘는 이미지가 있습니다.<br>이미지를 줄여주세요.',
    'Some image size is too small. Please set the image size bigger.':
      '작은 이미지가 있습니다.<br>이미지를 키워주세요.',
    'The image placed on the non-printable area will be deleted automatically. Would you like to proceed?':
      '인쇄 가능한 영역 밖에 있는 이미지들은 자동 삭제 후 장바구니에 담깁니다.<br>계속 진행 하시겠습니까?',
    'Item is overflowed.': '인쇄크기가 넘쳤습니다.',

    'Embroidery patches can not be resized.': '자수패치는 크기조정이 불가능합니다.',
    'Enlarge function makes image editing easier.':
      '두 손가락으로 확대 기능을 사용하시면 더 손쉽게 편집할 수 있어요.',
    "It's easy to edit with the lock function.": '이미지가 많을 때는 잠금 기능을 활용해보세요.',

    'Emoji and special characters are not supported.': '이모지와 특수문자는 지원되지 않습니다.',
    Cancel: '취소',
    Done: '완료',
    완료: '완료',
    'Design Complete': '디자인 완료',
    'Drag and drop your images.': '이미지를 끌어서 넣어주세요.',
    'There are no images selected.': '편집할 이미지가 없습니다.',
    'Background Remove Request': '배경 지움 요청',
    'Buckle Color': '버클 색상',
    Copy: '복사',
    Duplicate: '복제',
    Cut: '잘라내기',
    Paste: '붙여넣기',
    'Select All': '모두선택',
    'Remove All': '모두 지우기',

    'Hidden images are automatically deleted.': '숨긴 이미지는 자동 삭제됩니다.<br>계속 진행 하시겠습니까?',

    'Privacy Policy': '개인 정보 수집 및 이용 동의 (필수)',
    'Terms & Conditions': '서비스 이용 약관',

    모음: '모음',

    money_sign: '₩',

    //tutorial
    'Add text and design templates': '상품 디자인',
    'Design your own with text and design templates.':
      '텍스트와 디자인 템플릿을 이용해 당신만의 디자인을 해보세요.',
    'Upload your photo or design': '이미지 업로드',
    'You can upload your own design and photo.': '직접 디자인한 이미지나 사진을 업로드 할 수 있어요.',
    'Edit your design': '디자인 편집',
    'You can easily edit your design by using editing toolbar.':
      '이미지 편집기능을 사용하여 디자인을 손쉽게 변경할 수있어요.',
    'Design Align Difficult': '디자인 배치가 어려우신가요?',
    'Choose printing side': '인쇄면 선택',
    'Design Loc Opt': '위치 조정을 요청하시면 마플 담당자가<br>디자인 위치를 최적화된 곳에 배치해 드려요.',
    'You can have your product printed front and back of the product':
      '상품 인쇄면의 앞, 뒤, 옆면 등을 선택할 수 있습니다.',
    Next: '다음',
    'Got it': '완료',

    Check: '커스텀하는 방법',
    'Check before you start': '커스텀하는 방법',
    'Read carefully before you start designing': '상품만들기 전 꼭 확인해 주세요.',

    Product: '상품',

    'Font Style': '폰트 스타일',
    keyboard: '키보드',
    Color: '색상',
    'Device Color Preview': '기종 색상 미리보기',
    'Image Color': '색상',
    'Text Color': '글씨 색상',
    Pattern: '패턴',
    Crop: '자르기',
    Layer: '레이어',

    'Next step': '다음 단계',
    'Sell Your Products': '상품 판매',

    'Add Product': '상품 추가',
    Title: '상품 이름',
    'Set Your Price': '가격 설정',
    'Minimum Price': '기본 가격',
    Profit: '수익',
    Submit: '완료',
    'All Collaboration': '콜라보 전체 보기',
    Collaboration: '콜라보',

    'Added to cart': '상품을 장바구니에 담았습니다.',
    'My Cart': '내 장바구니',
    Cart: '장바구니',
    Checkout: '주문서작성',
    'Order Complete': '주문완료',
    'Your shopping cart is empty': '비어있는 장바구니를 채워주세요!',
    'Your wish list is empty': '찜한 상품이 없습니다.',
    'Product Details': '상품정보',
    'Size/Quantity': '사이즈/수량',
    Price: '가격',
    Optional: '편집',
    'Total Qty': '총 수량',
    'How many do you need?': '수량을 선택해 주세요.',
    Confirm: '확인',
    'Buy Now': '바로구매',
    'Buy Now 2': '바로 구매하기',
    Buy: '구매하기',
    'Add to Cart': '장바구니',
    'Add All To Cart': '모두 장바구니로 이동',
    Subtotal: '가격',
    Shipping: '배송비',
    Discount: '할인',
    Total: '합계',
    'No.': 'No.',
    Print: '인쇄',
    Size: '사이즈',
    Brand: '브랜드',
    Edit: '옵션 변경',
    'Save for Later': '나중에 구매',
    Remove: '삭제',
    'Add Color': '다른 색상',
    '+ Add Color': '색상 추가 +',
    'Proceed To Checkout': '주문서 작성',
    'Order completed': '주문 완료',
    'Resizing failed. Please try again.': '사이즈 변경에 실패했습니다. 다시 시도해 주세요.',
    'Please select one or more products.': '하나 이상의 상품을 선택해 주세요.',
    'There are some items in your cart': '사이즈와 수량을 선택하지 않은 상품이 있습니다.',
    'This item is already in your cart': '이미 담긴 상품입니다.',
    'Failed to put in cart': '장바구니에 담지 못했습니다. 다시 시도해 주세요.',
    'Your payment request failed': '결제요청에 실패했습니다.',
    'Please check your balance': '잔액을 확인해 주세요.',
    'Payment has been stopped': '결제를 취소하셨습니다. 주문 내역 확인 후 다시 결제해 주세요.',

    'View more': '더 보기',

    Featured: '추천순',
    'Low to High': '낮은 가격순',
    'High to Low': '높은 가격순',
    Filter: '필터 검색',
    Filters: '필터',
    'Select Filters': '필터 검색',
    Clear: '초기화',
    Apply: '적용',
    'All products': '모든 상품',
    'Show products': '상품보기',
    'No products matching the conditions.': '필터에 적합한 상품이 없습니다.',

    'Go to my shop': '내 샵 바로가기',

    'Sign in': '로그인',
    'Sign in Facebook': '페이스북으로 로그인',
    'Sign up Facebook': '페이스북으로 시작하기',
    'Sign out': '로그아웃',
    'Sign up': '회원가입',
    'Your Name': '이름',
    Male: '남성',
    Female: '여성',

    'This ID is already Withdrawal': '탈퇴한 아이디 입니다.',
    'This ID is already registered': '이미 가입된 아이디 입니다.',
    'You already have a registered email': '이메일이 이미 등록되어있습니다. 일반 로그인을 이용해 주세요.',
    'Name is empty': '이름을 써주세요.',
    'Email is empty': '이메일을 써주세요.',
    'Password is empty': '비밀번호를 입력해 주세요.',
    'This email address is not available.': '이메일 형식을 확인해 주세요.',
    'Incorrect email or password':
      '이메일 또는 비밀번호를 잘못 입력했습니다.<br />입력하신 내용을 다시 확인해 주세요.',
    'Sign up failed': '회원가입을 실패하였습니다.',
    'Reset password failed': '비밀번호 변경을 실패하였습니다.',
    'Check your email for a link':
      '비밀번호 재설정을 위해 이메일을 확인해 주세요. 몇 분 내에 나타나지 않으면 스팸 폴더를 확인해 주세요.',

    'Please enter your shipping address.': '입력되지 않은 필수 정보가 있습니다.',
    'Please select the filter you want.': '원하는 필터를 선택해 주세요.',
    'Unfortunately this promo': '유효하지 않은 쿠폰 코드입니다. 확인 후 다시 시도해 주세요.',
    'The promo code has been exhausted.': '해당 쿠폰 코드는 소진되었습니다.',

    '주문 금액보다 할인 금액이 큰 경우 쿠폰 적용이 불가능합니다.':
      '주문 금액보다 할인 금액이 큰 경우 쿠폰 적용이 불가능합니다.',

    Qty: '수량',
    Device: '휴대폰 기종 & 옵션',
    'Device Color': '기기 색상',
    'Please choose the size of the product.': '사이즈를 선택해 주세요.',
    'Special Requests': '제작 요청사항',
    'Shipping Address': '배송지 정보',
    'Payment Summary': '총 결제금액',
    Coupon: '쿠폰',
    Point: '포인트',
    Description: '상세내역',
    'My Point': '보유 포인트',
    'Total Price': '결제 금액',
    'Cancel Price': '주문 취소 금액',
    'Other Shipping Price 2': '묶음 배송비',
    'No special requests.': '특별 요청 사항이 없습니다.',
    'Credit Card': '신용카드',
    'Without a Bank Account': '무통장 입금',
    NAVERPAY: '네이버페이',
    SSGPAY: 'SSG페이',
    Alipay: '알리페이',
    SamsungPay: '삼성페이',

    'Refund Price': '환불된 금액',
    Free: '무료',

    'Other Discount': '추가 할인',
    'Other Price': '추가 금액',
    'Other Shipping Discount': '추가 배송비',
    'Other Shipping Price': '추가 배송할인',
    'Additional Payment': '추가 결제하기',
    'Amount due': '미결제액',
    'Paid Price': '총 결제 금액',
    Payable: '환불예정',

    'Are you sure you want to delete?': '삭제 하시겠습니까?',
    Post: '확인',
    '로그인 후 이용해 주세요.': '로그인 후 이용해 주세요.',
    '이벤트기간에만 댓글을 작성할 수 있습니다.': '이벤트기간에만 댓글을 작성할 수 있습니다.',
    '내용을 적어주세요.': '내용을 적어주세요.',
    'Page Not Found': '페이지가 없습니다.',
    'View all': '전체 보기',

    'Are you sure you want to cancel order?': '정말로 주문을 취소 하시겠습니까?',
    'Cancel request is fail.': '주문 취소 요청이 실패되었습니다.',
    'Cancel request is success.':
      '고객님의 주문 취소 요청이 완료되었습니다. 확인 후 취소 처리 해드리겠습니다.',
    'Upload an Image': '이미지 업로드',
    'My Review': '나의 리뷰',
    'Writable Review': '작성 가능한 리뷰',
    'Written Review': '내가 쓴 리뷰',
    'File size is too large': '파일 용량이 너무 큽니다.<br>{{mb}}MB 이하로 업로드해 주세요.',

    'You have pay an unpaid balance due to the order has been changed':
      '주문 정보가 변경되어<br>추가 결제가 필요합니다.<br><br>지금 결제하시겠습니까?',

    coupon_min_price_error: '이 쿠폰은 총 상품 금액이 {{min_price}}원 이상일 때 사용 가능합니다.',

    'Order Number': '주문 번호',

    'Product Mismatched': '상품 종류를 확인해 주세요.',
    'Price Mismatched': '결제 금액을 확인해 주세요.',

    'Payment Error': '결제 진행에 실패했습니다. 다시 결제하기를 눌러주세요.',

    'This browser is not supported and will not work properly':
      '현재 브라우저는 지원하지 않으며 정상 동작하지 않습니다. IE11 이상이나 크롬, 사파리, 모바일 브라우저 등 최신 브라우저를 사용해 주세요.',

    'Create your style, Curate your vibe': '마음대로 더하고 빼면 끝<br>어려울 거 없어요',
    "Don't miss out": '새로 업데이트된 테마',
    'Be yourself, Be a creator': '오늘의 나를 표현해봐',
    'More Themes': '다른 테마보기',
    'All Themes': '테마 전체 보기',
    'See more': '보러가기',
    'Start Designing': '커스텀 시작',

    '이전에 페이스북으로 가입하신 마플 계정이 있습니다. 페이스북으로 로그인 하시겠습니까?':
      '이전에 페이스북으로 가입하신 마플 계정이 있습니다. 페이스북으로 로그인 하시겠습니까?',
    '같은 이메일로 가입하신 마플 계정이 있습니다. 로그인 하시겠습니까?':
      '같은 이메일로 가입하신 마플 계정이 있습니다. 로그인 하시겠습니까?',

    '판매 종료': '판매 종료',
    '판매 시작': '판매 시작',

    // settings
    '썸네일 선택': '썸네일 선택',
    '선택 완료': '선택 완료',
    '상품 공개 여부': '상품 공개 여부',
    '배송 출발일 노출': '배송 출발일 노출',
    '판매 색상 선택': '판매 색상 선택',
    '다국어 지원 (선택 사항)': '다국어 지원 (선택 사항)',
    '전체 선택': '전체 선택',
    '대표 색상 선택': '대표 색상 선택',
    '판매 수량': '판매 수량',
    '1개당 판매 수익': '1개당 판매 수익',
    '1개당 추가 수익': '1개당 추가 수익',
    '디자인 스토리 작성하기': '디자인 스토리 작성하기',
    '한정 판매 기간 & 수량 설정': '한정 판매 기간 & 수량 설정',
    '1인당 구매 가능 수량': '1인당 구매 가능 수량',
    '상품 생성': '상품 생성',
    '파일 선택': '파일 선택',
    '언어별 스토리 설정': '언어별 스토리 설정',
    'SNS에 올린 글 가져오기': 'SNS에 올린 글 가져오기',
    '이미지 첨부': '이미지 첨부',
    '다중 선택 가능': '다중 선택 가능',

    Up: '위로',
    Down: '아래로',
    '샵 디자인': '샵 디자인',
    '상품 만들기': '상품 만들기',
    '상품별 판매 분석': '상품별 판매 분석',
    정산내역: '정산내역',
    계좌정보등록: '계좌정보등록',
    '계좌정보 확인': '계좌정보 확인',
    계정정보: '계정정보',
    마케팅: '마케팅',
    '출금 신청': '출금 신청',
    '상품 정렬': '상품 정렬',
    '내샵 보기': '내샵 보기',
    로그아웃: '로그아웃',
    '로고 &middot; 배너 변경': '로고 &middot; 배너 &middot; SNS 변경',
    유형: '유형',
    '소비자 가격': '소비자 가격',
    '판매 수량별 수익': '판매 수량별 수익',
    '판매 수량에 따라 추가 수익이 발생됩니다.': '판매 수량에 따라 추가 수익이 발생됩니다.',
    날짜: '날짜',
    수량: '수량',
    '취소된 수량': '취소된 수량',
    '주문 내역': '주문 내역',
    '취소된 주문수': '취소된 주문수',
    '1개당 수익': '1개당 수익',
    '총 수익': '총 수익',
    기업: '기업',
    '셀러 수익': '크리에이터 수익',
    '1개당 제작비': '1개당 제작비',
    '1개당 내 수익': '1개당 내 수익',
    수정: '수정',
    삭제: '삭제',
    시작일: '시작일',
    종료일: '종료일',
    '공개 여부': '공개 여부',
    공개: '공개',
    비공개: '비공개',
    '디자인 이름': '디자인 이름',
    '디자인 가격': '디자인 가격',
    한국어: '한국어',
    영어: 'English',
    일본어: '日本語',
    '판매 기간은 1주에서 4주로 설정할 수 있으며, 판매 종료 후 일괄 제작하여 출고됩니다.':
      '판매 기간은 1주에서 4주로 설정할 수 있으며, 판매 종료 후 일괄 제작하여 출고됩니다.',
    '제품 생성': '제품 생성',
    '스토어 생성': '스토어 생성',
    '변경 사항 취소': '변경 사항 취소',
    저장하기: '저장하기',
    '디자인 수정': '디자인 수정',
    '이 디자인으로 다른 상품 만들기': '이 디자인으로 다른 상품 만들기',
    '상품 복제': '상품 복제',
    '미리 보기': '미리 보기',
    '상품 삭제': '상품 삭제',
    '샵 이름': '샵 이름',
    '대표 SNS 주소': '대표 SNS 주소',
    '오른쪽 상단에 표시': '오른쪽 상단에 표시',
    '샵 설명': '샵 설명',
    '로고 이미지 업로드': '로고 이미지 업로드',
    '배너 배경색': '배너 배경색',
    '배너 이미지 업로드': '배너 이미지 업로드',
    '배너 제목': '배너 제목',
    '배너 설명': '배너 설명',
    저장: '저장',
    취소: '취소',
    초기화: '초기화',
    변경: '변경',
    '기본 이미지로 지정': '기본 이미지로 지정',
    '필터 적용': '필터 적용',
    '판매할 상품을 만들어보세요.': '판매할 상품을 만들어보세요.',
    '판매 순위': '판매 순위',
    상품: '상품',
    '공개 상태': '공개 상태',
    '판매 기간': '판매 기간',
    판매량: '판매량',
    수익: '수익',
    '추가 수익': '추가 수익',
    선택: '선택',
    '스토리 작성하기 <i>(권장)</i>': '스토리 작성하기 <i>(권장)</i>',
    '제목을 작성해 주세요.': '제목을 작성해 주세요.',
    '내용을 작성해 주세요.': '내용을 작성해 주세요.',
    '유튜브 비디오의 링크를 입력해 주세요.': '유튜브 비디오의 링크를 입력해 주세요.',
    '인스타그램 글의 링크를 입력해 주세요.': '인스타그램 글의 링크를 입력해 주세요.',
    '트위터 글의 링크를 입력해 주세요.': '트위터 글의 링크를 입력해 주세요.',
    '페이스북 글의 링크를 입력해 주세요.': '페이스북 글의 링크를 입력해 주세요.',
    '이미지를 업로드해 주세요.': '이미지를 업로드해 주세요.',
    '상품 디자인': '상품 디자인',
    '상품 정보 입력': '상품 정보 입력',
    '상품 선택': '상품 선택',
    '상품 카테고리 선택': '상품 카테고리 선택',
    '상품 정보 수정': '상품 정보 수정',
    '다시 시도해 주세요.': '다시 시도해 주세요.',
    '구매되었거나 장바구니에 담긴 상품은<br>삭제 할 수 없습니다.<br>대신 공개 설정을 이용해보세요.':
      '구매되었거나 장바구니에 담긴 상품은<br/>삭제 할 수 없습니다.<br/>대신 공개 설정을 이용해보세요.',
    '삭제 하시겠습니까?': '삭제 하시겠습니까?',
    '구매되었거나 장바구니에 담긴 상품은<br>디자인 가격을 수정할 수 없습니다.':
      '구매되었거나 장바구니에 담긴 상품은<br/>디자인 가격을 수정할 수 없습니다.',
    '디자인에 대한 가격을 설정해 주세요.': '디자인에 대한 가격을 설정해 주세요.',
    최소: '최소',
    최대: '최대',
    스토어: '스토어',
    이름: '이름',
    '상품 수': '상품 수',
    스튜디오: '스튜디오',
    로고: '로고',
    편집: '편집',
    크루: '크루',
    검색: '검색',
    링크: '링크',
    '날짜 변경': '날짜 변경',
    '판매 기간을 설정해 주세요.': '판매 기간을 설정해 주세요.',
    '최소 높이 28px, 최대 높이 56px': '최소 높이 28px, 최대 높이 56px',
    '최소 1120 * 400px, 최대 2240 * 800px': '최소 1120 * 400px, 최대 2240 * 800px',
    '최소 400px * 400px, 최대 800 * 800px': '최소 400px * 400px, 최대 800 * 800px',
    '계정정보에서 전화번호를 입력해 주세요.': '계정정보에서 전화번호를 입력해 주세요.',
    '프로필 이미지': '프로필 이미지',
    셀러: '크리에이터',
    '이 화면은 미리보기 페이지입니다.': '이 화면은 미리보기 페이지입니다.',
    '디자인 하기': '디자인 하기',
    '디자인 수정하기': '디자인 수정하기',

    '약관에 동의해 주세요.': '약관에 동의해 주세요.',
    '판매 기간이 설정된 상품만 구매할 수 있습니다.': '판매 기간이 설정된 상품만 구매할 수 있습니다.',
    '이미지 크기를 확인해 주세요.': '이미지 크기를 확인해 주세요.',
    '이미지의 비율을 확인해 주세요.': '이미지의 비율을 확인해 주세요.',
    '판매 기간 설정 필요': '판매 기간 설정 필요',
    파일선택: '파일선택',
    KIDS: '키즈',
    '다른 판매 기간과 겹치게 설정할 수 없습니다.': '다른 판매 기간과 겹치게 설정할 수 없습니다.',
    품절: '품절',
    '한정 판매 수량': '한정 판매 수량',
    기간: '기간',
    daily: '일간',
    yearly: '연간',
    monthly: '월간',
    weekly: '주간',
    all: '전체',
    'Please enter your address and name in English.': '주소와 이름을 영어로 입력해 주세요.',
    '1:1 문의': '1:1 문의',
    클립보드: '클립보드',
    정산금액: '정산금액',
    결제수수료제외: '결제수수료제외',
    '상품 색상 변경': '상품 색상 변경',
    '색상 선택 가능': '색상 선택 가능',
    '색상 선택 불가능': '색상 선택 불가능',

    매니저: '매니저',
    권한: '권한',
    목록: '목록',
    추가: '추가',
    '권한 목록표': '권한 목록표',
    Community: '커뮤니티',
    Seller: '크리에이터',
    '카테고리 보기': '카테고리 보기',
    '카테고리 닫기': '카테고리 닫기',
    최신순: '최신순',
    인기순: '인기순',
    'Sorry. Payment could not be completed because the limited quantity was exceeded.':
      '죄송합니다. 한정 수량을 초과하여 결제를 완료하지 못했습니다.',
    '신고 접수는 PC에서만 가능합니다.': '신고 접수는 PC에서만 가능합니다.',
    전체: '전체',
    '답변 대기': '답변 대기',
    '판매 재개/샵 정상 운영': '판매 재개/샵 정상 운영',
    '판매 중지/샵 운영 중지': '판매 중지/샵 운영 중지',
    '내부 규정 위반 답변완료': '내부 규정 위반 답변완료',
    '접수된 신고가 없습니다.': '접수된 신고가 없습니다.',
    '마플 개인 커스텀 주문 리뷰를 참고해보세요!': '마플 개인 커스텀 주문 리뷰를 참고해보세요!',
    '부가세 포함 가격': '{{profit_title}}은 부가세가 포함된 가격입니다.',
    '디자인 가격 부가세 포함 가격': '디자인 가격은 부가세가 포함된 가격입니다.',
    '디자인 가격 부가세 포함 가격 아프리카':
      '디자인 가격은 부가세가 포함된 가격입니다. BJ수익금은 디자인 가격의 70%로 책정되며 30%는 플랫폼 수수료입니다.',
    '디자인 가격이 더해져 자동 계산됩니다.': '{{title}} + 디자인 가격이 더해져 자동 계산됩니다.',
    '판매 수수료': '판매 수수료',
    '기본 상품 가격': '기본 상품 가격',
    '환불 정보': '환불 정보',
    '취소 금액 합계': '취소 금액 합계',
    '환불 수단': '환불 수단',
  },
  time_unit: {
    days: '일',
    hours: '시간',
    minutes: '분',
    seconds: '초',
  },
  req_msg: {
    Changed: '변경되었습니다.',
    'failed please retry': '요청 실패 다시 시도해 주세요.',
  },
  image_editor: {
    image_editor: '이미지 편집',
    edit: '편집',
    filter: '필터',
    adjustment: '조정',
    transform: '변형',
    initialize: '초기화',
    edit_freely: '이미지를 자유롭게 편집해 보세요.',
    cancel: '취소',
    complete: '완료',
    original: '원본',
    Paris: '파리',
    LosAngeles: '로스엔젤레스',
    Oslo: '오슬로',
    Lagos: '라고스',
    Melbourne: '멜버른',
    Jakarta: '자카르타',
    AbuDhabi: '아부다비',
    SaoPaulo: '상파울루',
    NewYork: '뉴욕',
    Cairo: '카이로',
    Moon: '달나라',
    brightness: '밝기',
    contrast: '명암',
    saturation: '채도',
    hue: '색조',
    red_tone: '적색 톤',
    green_tone: '녹색 톤',
    blue_tone: '청색 톤',
    brown_tone: '갈색 톤',
    opacity: '투명도',
    definition: '선명도',
    technicolor: '테크니컬러',
    film_camera: '필름',
    polaroid: '폴라로이드',
    vintage: '빈티지',
    vibrance: '활기',
    vignette: '비네트',
    noise: '노이즈',
    gamma: '감마',
    warmth: '따뜻함',
    bright_area: '밝은 영역',
    dark_area: '어두운 영역',
    invert: '반전',
    texture: '엠보스',
    size: '확대/축소',
    rotate: '회전',
    move_A: '가로 이동',
    move_B: '세로 이동',
    apply: '적용하시겠습니까?',
    editor_cancel: '취소하시겠습니까?',
    vertical: '세로',
    horizontal: '가로',
    crop_limit_1: '이미지의 ',
    crop_limit_2: ' 길이가 80px 보다 작아 변형 기능이 제한됩니다.',
    ie_warning:
      "이용 중인 브라우저에서는 '이미지 편집' 기능이 제한됩니다. 원활한 이용을 위해 다른 브라우저(크롬, 사파리, 모바일 브라우저 등)를 사용해 주세요.",
    cmyk_alert:
      '본 이미지는 CMYK 컬러 모드로 작업되었습니다. 이미지 편집기에서는 RGB 컬러 모드로 자동 변환되어 색감의 변화가 발생할 수 있습니다.',
    crop_alert:
      '이미지 확대 시 이미지 품질이 저하될 수 있으며 프린팅이 흐리거나 농도가 고르지 않을 수 있습니다.',
    color_change_alert:
      '화면에 편집 된 이미지는 실제 제품 인쇄 컬러와 차이가 있을 수 있으며 필터 사용 시 인쇄 퀄리티가 저하될 수 있습니다.',
  },
  creator_adjustment: {
    release: '출고',
    change: '변경',
    confirmed: '구매확정',
    '정산 날짜': '정산 날짜',
  },
  crew_level: {
    master: '마스터',
    accounter: '정산',
    analyzer: '분석',
    maker: '상품 제작 및 검수',
    seller: '크리에이터',
    designer: '디자이너',
    manager: '매니저',
  },
  crew_admin: {
    디자인: '디자인',
    매출: '매출',
  },
  marppleshop_action: {
    read_orders: '주문 내역 조회',
    read_sales_amount: '주문 및 판매분석 수익 조회',
    read_adjustments: '정산 내역 조회',
    delete_manager: '매니저 삭제',
    delete_store: '상점 삭제',
    create_store: '상점 만들기',
    update_store_seller: '상점 크리에이터 변경',
    update_store_name: '상점 이름 변경',
    create_manager: '매니저 추가',
    read_bank_account: '계좌 정보 조회',
    read_sales_analytics: '판매 분석 조회',
    update_manager_level: '매니저 권한 수정',
    request_withdraw: '출금 요청',
    cancel_withdraw: '크리에이터 출금 취소',
    approve_withdraw: '크리에이터 출금 승인',
    read_withdraws: '크리에이터 출금 내역 조회',
    register_crew_bank_account: '플러스 계좌 등록',
    make_product: '상품 제작',
    open_pb_private_info: 'PB 구매자 개인정보 조회',
    access_all_studio: '모든 스튜디오 접근',
    access_crew_admin: '플러스 어드민 접근',
    update_product_confirm: '상품 검수',
    make_pb_product: 'PB 상품 제작',
    update_marppleshop_marketing_confirm: '마플샵 마케팅 노출 확인',
    update_marppleshop_marketing_thumbnail: '마플샵 마케팅 스토어 썸네일 수정',
    read_marppleshop_analytics: '마플샵 분석 조회',
    delete_product: '상품 삭제',
    prohibit_product: '상품 판매 보류',
    update_suspend_store: '스토어 운영중지',
    confirm_designer_requests: '디자이너 신청 승인',
    read_pb_projections: 'PB 상품 주문서 조회',
    update_crew_store_labels: '상점 라벨 수정',
    update_want_withdrawal: '판매 승인 여부 수정',
    update_store_can_selling: '탈퇴 희망 여부 수정',
    invite_friend: '친구 초대 가능 여부',
    create_feed: '피드 생성',
    update_is_selling_tatttoo: '타투 판매 설정',
    approve_crew_withdraws: '기업 출금 승인',
    read_crew_withdraws: '기업 출금 조회',
    request_crew_withdraws: '기업 출금 신청',
    update_is_brand_home: '상품 홈 노출 여부 수정',
    manage_site: '사이트 관리',
    hide_crew_profit: '기업 수익 표기 숨김',
    set_fixed_seller_share: '수익 쉐어 비율 설정',
  },
  plus_main: {
    event: '콘텐츠',
    event_default_title: 'Special Curations Just For You',
    event_default_desc: '오직 당신만을 위해 준비된 특별한 콘텐츠',
    'no-today': '콘텐츠가 아직 없습니다.<br/> 곧 다양한 소식을 전해드릴게요!',
    'Featured Creators': 'Featured Creators',
    '지금 가장 인기 있는 셀러들의 굿즈를 만나보세요.':
      '지금 가장 인기 있는 크리에이터들의 굿즈를 만나보세요.',
    '셀러샵 전체 보기': '크리에이터샵 전체 보기',
  },
  community: {
    리뷰: '리뷰',
    '권한이 없습니다.': '권한이 없습니다.',
    '로그인 된 유저가 없습니다.': '로그인 된 유저가 없습니다.',
    실패: '실패',
    등록하기: '등록하기',
    '파일 업로드 실패했습니다.': '파일 업로드 실패했습니다.',
    '로그인 후 이용해 주세요. 로그인 하시겠습니까?': '로그인 후 이용해 주세요.<br>로그인 하시겠습니까?',
    '크리에이터님의 소식을 기다리고 있습니다. 다양한 소식을 전해 주세요 !':
      '크리에이터님의 소식을 기다리고 있습니다.<br>다양한 소식을 전해 주세요 !',
    '글 작성하기': '글 작성하기',
    '이미지를 등록해 주세요.': '이미지를 등록해 주세요.',
    수정: '수정',
    '커뮤니티에 올릴 글을 작성해 주세요.': '커뮤니티에 올릴 글을 작성해 주세요.',
    '더 보기': '더 보기',
    댓글: '댓글',
    상품: '상품',
    커뮤니티: '커뮤니티',
    '댓글 달기': '댓글을 남겨보세요.',
    등록: '등록',
    좋아요: '좋아요',
    개: '',
    삭제: '삭제',
    신고: '신고',
    '불러오기 실패 다시 클릭해 주세요.': '불러오기 실패 다시 클릭해 주세요.',
    '삭제하시겠습니까?': '삭제하시겠습니까?',
    '크리에이터님의 소식이 아직 없습니다. 곧 다양한 소식을 전해 드릴게요 !':
      '크리에이터님의 소식이 아직 없습니다.<br>곧 다양한 소식을 전해 드릴게요 !',
    인기글: '인기글',
    최신글: '최신글',
    '리뷰 상세': '리뷰 상세',
    '갤러리 상세': '갤러리 상세',
    '갤러리 글 수정하기': '갤러리 글 수정하기',
    글쓰기: '글쓰기',
    '커뮤니티 글 등록하기': '커뮤니티 글 등록하기',
    '커뮤니티 글 수정하기': '커뮤니티 글 수정하기',
    '이미지 첨부': '이미지 첨부',
    '커뮤니티 상세': '커뮤니티 상세',
    '팬심 가득 담은 응원 한마디!': '팬심 가득 담은 응원 한마디!',
    '포토 상세': '포토 상세',
  },
  crew_menu: {
    seller_management: '크리에이터 관리',
    pb_seller_management: '상품 스토어',
    inactive_seller_list: '비운영 크리에이터 관리',
    disapproval_store_list: '미승인 스토어',
    unregistered_store_list: '미등록 스토어',
    rejected_store_list: '불합격 스토어',
    invited_store_list: '초대받은 크리에이터',
    manager_management: '매니저 관리',
    seller_adjustment: '크리에이터 출금',
    store_profit: '굿즈 판매 현황',
    store_seller_profit: '상품 판매 현황',
    store_tpl_profit: '입고 상품 판매 현황',
    store_dtg_profit: '디지털 상품 판매 현황',
    confirmations: '상품 검수',
    pb_projections: 'PB상품 주문서',
    pb_projection_detail: 'PB상품 주문서 상세보기',
    pb_products: 'PB상품 목록',
    designers: '디자이너',
    products: '전체 상품',
    make_archive_admin: '제품 퀄리티 관리',
    product_check: '상품 디자인 검수',
    feeds: '크리에이터팁 관리',
    solution: '신고 접수',
    crew_withdraw: '기업 출금',
    crew_bank_account: '기업 계좌 등록',
    crew_adjustment: '기업 정산',
    crew_youtube: '유튜브판매 크리에이터',
    website: '사이트 관리',
  },
  line_theme: {
    'Customizable Products': '커스텀 가능 품목',
    'T-Shirts, Sweat Shirts, Hoodies, Zip Ups, Tote bags, Phone Cases':
      '티셔츠, 맨투맨, 후디, 집업, 캔버스백, 폰케이스',
    iPhone: '아이폰 지원기종',
    'Samsung Galaxy': '갤럭시 지원기종',
  },
  dialog_msg: {
    Yes: '예',
    No: '아니오',
    Ok: '확인',
  },
  maker: {
    select_face: {
      tooltip: '👀 다른 면도 인쇄할 수 있어요!',
    },
    locOpt: {
      name: '위치 조정 요청',
      guideTitle: '위치 조정 안내',
      guideDescription: '작업하신 디자인을 기준으로 상품에 가장 어울리는 위치로 조정해 드립니다.',
      guideWarning:
        '*디자인의 크기나 색상을 수정하지 않으며, 위의 4가지 경우를 제외한 위치는 조정하지 않습니다.',
      guideWarningCheckbox: '위치 조정 요청시, 조정으로 인한 교환/환불/반품이 불가함을 인지하고 동의합니다.',
      resetWarning: '상품 변경으로 인하여 위치 조정 요청 기능이 초기화 됩니다.',
      requestActivation: '위치 조정 요청',
      requestCancel: '위치 조정 요청 취소',
      noDesignWarning: '위치 조정 요청할 디자인이 없습니다.',
      frontLeft: '앞면 왼쪽',
      frontRight: '앞면 오른쪽',
      frontCenter: '앞면 중앙',
      backCenter: '뒷면 중앙',
      close: '닫기',
    },
    'lets design': '디자인하기',
    '양옆 채우기': '양옆 채우기',
    icon: {
      Preview: '미리보기',
    },
    'Accepted File Types: JPEG, PNG, AI.':
      '이 상품에 가능한 이미지 파일은 JPEG 또는 PNG<span class="creator_del_ai">, AI&nbsp;</span> 파일입니다.',
    'Accepted File Types: JPEG, PNG, SVG.': '이 상품에 가능한 이미지 파일은 JPEG 또는 PNG, SVG 파일입니다.',
    'Accepted File Types: JPEG, PNG.': '이 상품에 가능한 이미지 파일은 JPEG 또는 PNG 파일입니다.',
    Product: '상품&middot;색&middot;사이즈',
    Design: '꾸미기',
    Character: '캐릭터추가',
    'This product does not support ai file. Please convert the file into png or jpeg format.':
      '이 상품은 AI파일을 지원하지 않습니다. PNG 또는 JPEG 파일로 변환해서 업로드해 주세요.',
    'Please notice that unsupportive characters are going to be excluded.':
      '지원하지 않는 문자는 제외됩니다. 확인해 주세요.',
    'This font does not support bold. The font will be changed to the default thickness.':
      '해당 서체는 볼드를 지원하지 않습니다.<br>기본 굵기로 변경됩니다.',
    'This size is temporarily out of stock.': '해당 사이즈는 일시 품절입니다.',
    'The size of the embroidery patch is': '이 자수 패치의 크기는',
    'cm.': 'cm 입니다.',
    Photo: '포토리뷰',
    Preview: '기종별 미리보기',
    'At least 500px': '좋은 품질로 인쇄하기 위해서는<br>최소 500px 이상의 이미지가 필요합니다.',
    'MAX 8000px': '8,000px 이하의 이미지로 변경하여 업로드해 주세요.',
    file_error: '알 수 없거나 잘못된 이미지입니다.<br>이미지를 수정 혹은 다시 저장하여 업로드해보세요.',
    '100%': '기본 크기',
    'Something went wrong. Please check all the designs.':
      '문제가 발생했습니다. 디자인에 문제가 없는지 한 번 확인 후 진행 해주세요.',
    'The font is no longer supported. Please select the other fonts.':
      '더 이상 제공하지 않는 폰트입니다.<br>다른 폰트를 선택해 주세요.',
    Unknown: '이름 없음',
    "The font doesn't support Bold.": '이 서체는 볼드체를 지원하지 않습니다.',
    Message: {
      undesigned_area_message: `앗! 디자인 안된 영역이 있어요.😂`,
      there_is_no_design_message: `앗! 디자인이 없어요. 😂`,
      'Fill the image to the dotted line for full-sided printing.':
        '윗면, 아랫면, 옆면 인쇄를 원하시면 이미지를 점선까지 채워주세요.',

      'Place important images inside the dotted line.': '중요한 이미지는 점선 내부에 배치해 주세요.',
      'Fill the image to the solid line for full-sided printing.':
        '위·아래·옆면까지 전체 인쇄를 원하시면 실선까지 채워주세요.',
      'Please fill in the design to the outer dotted line to full print the image.':
        '전면 인쇄를 원하시면 바깥쪽 점선까지 디자인을 채워주세요.',
      'Please fill in the design to the dotted line for production safety.':
        '제작의 안정성을 위해 디자인을 점선까지 채워주세요.',
      'The product image has been changed. Please check out the positions of your designs.':
        '제품 이미지가 수정됐습니다.<br>디자인의 위치를 한 번 확인해 주세요.',
      'If you dont put an image on the bottom of it, its made in white. Would you like to proceed with it?':
        '바닥면에 이미지를 넣지 않으면 하얀색으로 제작됩니다. 계속하시겠습니까?',
      'If you do not delete the background image of what you want to engrave, it will be engraved as it is, so be sure to delete the background before uploading.':
        '이미지 배경을 지우지 않으면 사각형 그대로 각인됩니다. 반드시 배경을 지우고 업로드해 주세요.',
      'This product is does not allow background remove request.':
        '배경 지움 요청이 불가능한 상품입니다.<br>자동 해제 됐습니다.',
      'Images with small resolution may reduce print quality. Do you want to change to the optimal size?':
        '이미지 해상도가 작아 인쇄 품질이 저하될 수 있습니다. 최적 사이즈로 변경 하시겠습니까?',
      go_to_not_designed_fcanvas: '디자인하지 않으신 면이 있습니다. 계속 진행하시겠습니까?',
      cancel_fit_width: `이미지 크기 조절이 필요한 경우, '양옆 채우기' 옵션을 해제하세요.`,
      it_is_not_product_for_fit_width_function: `양옆 채우기 기능이 없는 제품입니다.<br>자동 해제 됐습니다.`,
      border_1mm: `제품 특성상 테두리 1mm는 인쇄가 되지 않습니다.`,
      border_2mm: `제품 특성상 테두리 2mm는 인쇄가 되지 않습니다.`,
      embro_product: `업로드한 이미지 크기에 따라 별도의 추가비용이 발생할 수 있습니다.`,
      important_in_green: `디자인 내 중요 요소는 초록색 점선 내에 배치해 주세요.`,
      '전면 인쇄를 원하시는 경우 반드시 이미지를 점선 끝까지 채워주시기 바랍니다.':
        '전면 인쇄를 원하시는 경우 반드시 이미지를 점선 끝까지 채워주시기 바랍니다.',
      '로고 주변은 피해서 디자인 해주세요.':
        '로고 위에는 프린트가 불가하니,<br>로고를 피해서 디자인 해주세요.😉',
      '해당 제품의 경우 자수 제작은 불가합니다.': '해당 제품의 경우 자수 제작은 불가합니다.',
      '제품 이미지가 수정 됐습니다. 디자인의 위치와 크기를 확인해주세요.':
        '제품 이미지가 수정 됐습니다.<br>디자인의 위치와 크기를 확인해주세요.',
      '그대로 작업하기': '그대로 작업하기',
      '수정하러 가기': '수정하러 가기',
      '전면 인쇄를 원하시면 바깥쪽 점선까지 디자인을 채워주시고/중요 요소는 초록색 점선 내에 배치해 주세요.':
        '전면 인쇄를 원하시면 바깥쪽 점선까지 디자인을 채워주시고<br>중요 요소는 초록색 점선 내에 배치해 주세요.',
      '이 상품은 뒷면만 인쇄가 가능합니다.': '이 상품은 뒷면만 인쇄가 가능합니다.',
      '앞면과 뒷면 모두 동일한 색상으로 인쇄돼요.': '앞면과 뒷면 모두 동일한 색상으로 인쇄돼요.',
    },
  },
  line_maker: {
    Change: '바꾸기',
    'Right click is not available.': '오른쪽 버튼을 이용할 수 없습니다.',
    'Address Copied. Paste the copied address.': '주소가 복사되었습니다.<br>원하는 곳에 붙여넣기 해주세요.',
    Share: '공유하기',
  },
  line_create: {
    '의류 커스텀': '의류 커스텀',
    '폰케이스 커스텀': '폰케이스 커스텀',
    '캔버스백 커스텀': '캔버스백 커스텀',
    '에어팟케이스 커스텀': '에어팟케이스 커스텀',
    '홈데코 커스텀': '홈데코 커스텀',
    '키즈의류 커스텀': '키즈의류 커스텀',
    '펫 용품 커스텀': '펫 용품 커스텀',
    '모바일 액세서리 커스텀': '모바일 액세서리 커스텀',
  },

  product_detail: {
    Tip: '커스텀하는 방법',
    Story: '스토리',
    'Product Info': '상품 정보',
    'Print Guide': '제작 가이드',
    Guide: '구매 전 확인해 주세요.',
    'Size chart': '사이즈 정보',
    'Size Compare': '사이즈 비교',
    Reviews: '리뷰',
    '인쇄 관련 사항': '인쇄 관련 사항',
    '교환 및 환불 안내': '교환 및 환불 안내',
    '자주 묻는 질문': '자주 묻는 질문',
    '배송 및 환불': '배송 및 환불',
    배송안내: '배송안내',
    'FAQ 전체 보러가기': 'FAQ 전체 보러가기',
    '배송 FAQ 보러가기': '배송 FAQ 보러가기',
    '교환/환불 FAQ 보러가기': '교환/환불 FAQ 보러가기',
    '1:1 문의하기': '1:1 문의하기',
    '일대일 문의하기': '일대일 문의하기',
    precautions: '구매 시 주의사항',
    precautions_info:
      '전자상거래 등에서의 소비자보호에 관한 법률에 의거하여 미성년자가 물품을 구매하는 경우, 법정대리인이 동의하지 않으면 미성년자 본인 또는 법정대리인이 구매를 취소할 수 있습니다.',
    '셀러 배송 상품': '크리에이터 배송 상품',
    '해당 상품은 셀러가 직접 배송하는 상품입니다. 구매 안내를 확인해주세요.':
      '해당 상품은 크리에이터가 직접 배송하는 상품입니다.<br>구매 안내를 확인해주세요.',
    '브랜드 배송 상품': '브랜드 배송 상품',
    '해당 상품은 브랜드에서 직접 배송하는 상품입니다. 구매 안내를 확인해주세요.':
      '해당 상품은 브랜드에서 직접 배송하는 상품입니다.<br>구매 안내를 확인해주세요.',
    'The product information is invalid.<br>Will refresh.': '상품정보가 잘못되었습니다.<br>새로고침 됩니다.',
    '상품 기본 정보': '상품 기본 정보',
    'KC 및 기타 인증': 'KC 및 기타 인증',
    인증번호확인: '인증번호확인',
    '구매전 확인': '구매 전에 안전 인증 정보를 꼭 확인하세요.',
    '인증 책임 판매자': '본 내용은 판매자가 직접 등록한 것으로 해당 정보에 대한 책임은 판매자에게 있습니다.',
    '수량은 1개 이상이어야 합니다.': '수량은 1 이상이어야 합니다.',
    '옵션은 필수로 선택하셔야해요.': '옵션은 필수로 선택하셔야해요.',
    '옵션으로 선택된 상품이 더 이상 존재하지 않습니다.': '옵션으로 선택된 상품이 더 이상 존재하지 않습니다.',
    no_photo_reviews: '이 상품의 첫 번째 포토 리뷰어가 되어주세요.',
  },

  pattern: {
    'Pattern-Size': '크기',
    'Pattern-Type': '타입',
    Copies: '반복',
    Type: '타입',
    Repeat: '정방향',
    Lattice1: '격자타입1',
    Lattice2: '격자타입2',
    Lattice3: '격자타입3',
    'Pattern-Move': '이동',
  },

  pattern_mobile: {
    'Pattern-Size': '크기',
    'Pattern-Type': '타입',
    Copies: '반복',
    Type: '타입',
    Repeat: '정방향',
    Lattice1: '격자타입1',
    Lattice2: '격자타입2',
    Lattice3: '격자타입3',
    'Pattern-Move': '이동',
  },

  color: {
    Add: '상품 추가',
    Done: '확인',
    'No colors are available to add': '추가할 수 있는 색상이 없습니다.',
  },

  sticker: {
    'Are you sure to delete this?': '이미지를 삭제하시겠습니까?',
    'Delete failed. Please try again.': '이미지 삭제 실패. 다시 시도해 주세요.',
    'Delete Success.': '이미지가 삭제되었습니다.',
  },

  group: {
    Group: '단체',
    'All Group': '단체 전체 보기',
  },

  payment_done: {
    'Payment Successful': '주문해 주셔서 감사합니다.',
    'View Orders': '구매 내역 확인',
    'Continue Shopping': '쇼핑 계속하기',
  },

  mpay: {
    '결제 안내': '결제 안내',
    copy_link: '링크복사',
    url_copied: 'URL이 클립보드에 복사되었습니다.',
    url_copied2: '주소가 복사되었습니다. 원하는 곳에 붙여넣기 해주세요.',
    url_failed: 'URL 복사에 실패했습니다.',
    wrong_url: '잘못된 주소 입니다.',
  },

  return_status: {
    on_request: '요청',
    on_process: '처리중',
    rejected: '실패',
    completed: '완료',
  },

  return_type: {
    exchange: '교환',
    refund: '환불',
  },

  home: {
    Home: '홈',
    Reviews: '리뷰',
    Goods: '오늘의 굿즈',
    'Make Your Own': '내가 만드는 나만의 상품',
    'Quick and easy start': '쉽고 빠른 만들기',
    'Complete within 3 minutes': '별도 프로그램 설치없이',
    'with no installation of any programs': '3분 완성 만들기툴로 간편하게',
    'Meticulously crafted products': '꼼꼼한 상품 제작',
    'Guarantee the highest quality': '커스텀 제작에 대한 최고의 노하우로',
    'in the industry': '최고의 품질을 보장',
    'Free design template': '디자인 무료 제공',
    'Enjoy the best collection of': '디자인 아이디어를 제공하는',
    'art and fonts on the web': '마플만의 무료 템플릿',
  },

  signup: {
    '이용약관 동의': '이용약관 동의',
    보기: '보기',
    '개인정보수집 이용 동의': '개인정보수집&middot;이용 동의',
    '만 14세 이상입니다.': '만 14세 이상입니다.',
    '만14세 이상 가입이 가능합니다.': '만14세 이상 가입이 가능합니다.',
    '개인정보수집 이용에 동의해 주세요.': '개인정보수집&middot;이용에 동의해 주세요.',
    'Email News': '이메일 마케팅 수신동의 (선택)',
  },

  login: {
    Email: '이메일',
    'Your Name': '이름',
    Email_placeholder: '이메일',
    'Reset your password': '비밀번호 찾기',
    'Forgot Password?': '아이디 / 비밀번호 찾기',
    'Reset password': '비밀번호 변경 이메일 받기',
    'Enter your email address and we will send you a link to reset your password.':
      '이메일 입력 시 재설정 링크를 보내드립니다.',
    'Your email address is verified.': '환영합니다!<br>이메일 인증이 완료되었습니다.',
    'Your email address is not verified.': '이메일이 인증되지 않았습니다.',
    'Your email address is not verified2': '이메일이 인증되지 않았습니다.<br>인증하시겠습니까?',
    check_agree: '이용약관에 동의해 주세요.',
    'Change password': '비밀번호 변경',
    '비밀번호 10 ~ 64자': '10 ~ 64자 (최대한 길게)',
    'Success change password': '비밀번호가 변경되었습니다',
    'Please verify your e-mail address': '이메일 인증이 필요합니다.',
    'Please click on the verification link you sent to': '인증하기 버튼을 클릭하시면 인증이 완료됩니다.<br>',
    'Resend verification email': '인증 이메일 다시 보내기',
    'Change Email Address': '이메일 변경',
    'Current Email Address': '현재 입력된 이메일',
    'We ll send an order confirmation to this address.': '위 이메일로 주문 내역 메일이 전송됩니다.',
    'Please verify your current e-mail address.': '사용 가능한 메일인지 확인해 주세요.',
    'New Email Address': '새로운 이메일을 입력해 주세요.',
    Password: '비밀번호',
    'Save Changes': '이메일 변경',
    'Enter your new email': '새로운 이메일을 입력해 주세요.',
    'Enter your password': '비밀번호를 입력해 주세요.',
    'Check your email again': '현재 이메일과 같은 이메일 입니다. 다시 확인해 주세요.',
    'There was a problem<br>Your password is incorrect': '비밀번호가 잘못되었습니다.',
    'Email address already in use': '이미 사용중인 이메일 주소 입니다.',
    'There was a problem<br>Please try Again': '이메일 변경을 실패했습니다.<br>다시 시도해 주세요.',
    'Check your email.': '이메일을 확인해 주세요.<br> 몇 분 안에 나타나지 않으면, 스팸 폴더를 확인해 주세요.',
    'failed please retry': '요청 실패 다시 시도해 주세요.',
    Back: '뒤로가기',
    'login failed': '로그인 실패',
    'sign up failed': '회원가입 실패',
    '비밀번호는 10자 이상 최대한 길게 작성해주세요.': '비밀번호는 10 ~ 64자, 최대한 길게 작성해주세요.',
    '비밀번호에 동일한 문자를 과도하게 연속해서 사용할 수 없습니다.':
      '비밀번호에 동일한 문자를 과도하게 연속해서 사용할 수 없습니다.',
    '비밀번호에 ID를 포함할 수 없습니다.': '비밀번호에 ID를 포함할 수 없습니다.',
    '비밀번호에 특수문자는 !@#$%^*+=-만 사용 가능합니다.':
      '비밀번호에 특수문자는 !@#$%^*+=-만 사용 가능합니다.',
    '연속된 문자를 사용할 수 없습니다.': '연속된 문자를 사용할 수 없습니다.',
  },

  seller_login: {
    '초대 받으신 이메일과 다릅니다.': '초대 받으신 이메일과 다릅니다.',
    '마플샵 셀러 신청시 입력하신 이메일과 다릅니다. 동일한 이메일로 이용해 주세요.':
      '마플샵 크리에이터 신청시 입력하신 이메일과 다릅니다. 동일한 이메일로 이용해 주세요.',
    '인증 정보가 잘못되었습니다.': '인증 정보가 잘못되었습니다.',
    '다른 그룹에 이미 속해 있어 해당 스토어에 가입 및 로그인을 할 수 없습니다.':
      '다른 그룹에 이미 속해 있어 해당 스토어에 가입 및 로그인을 할 수 없습니다.',
    '유저의 스토어가 아닙니다.': '유저의 스토어가 아닙니다.',
    '마플샵 관련 정보가 없습니다. 고객센터에 문의해 주세요.':
      '마플샵 관련 정보가 없습니다. 고객센터에 문의해 주세요.',
    '스토어가 없습니다. 고객센터에 문의해 주세요.': '스토어가 없습니다. 고객센터에 문의해 주세요.',
  },

  gnb: {
    REVIEW: '포토리뷰',
    PREVIEW: '미리보기',
    EVENT: '이벤트',
    HELP: '고객센터',
    CUSTOM: '상품 만들기',
    CUSTOM2: 'CUSTOM',
    GROUP: '단체주문',
    COLLABORATION: '콜라보',
    CHAT: '1:1 채팅',
    'WELCOME!': '안녕하세요!',
    Collaboration: '추천 콜라보',
    'PHONE CASE': '폰케이스',
  },

  line_gnb: {
    의류: '의류',
    가방: '가방',
    폰케이스: '폰케이스',
    에어팟케이스: '에어팟케이스',
    쿠션: '쿠션',
    '담요/테피스트리': '담요/테피스트리',
    시계: '시계',
    펫용품: '펫용품',
  },

  review: {
    'Accepted File Types: JPEG, PNG, GIF': '업로드 가능한 이미지 파일은 JPEG 또는 PNG, GIF 파일입니다.',
    'Write a Review': '리뷰 작성',
    'Support your Sellers': '리뷰로 응원하기',
    'Select Points': '별점을 선택해 주세요.',
    '사진을 올려 주세요.': '사진을 올려 주세요.',
    Review: '상품평을 작성해 주세요.',
    'Support your sellers with a review.': '리뷰로 여러분의 크리에이터를 응원해 주세요.',
    'Minimum of 5 words': '최소 5글자 이상 작성해 주세요.',
    'Maximum of 2000 words': '최대 2000글자 이하로 작성해 주세요.',
    'Write a review for this item.':
      '마플에서의 경험, 만드신 상품에 대한 리뷰 부탁드립니다.\n멋진 후기에 추가 포인트 적립도 잊지 않겠습니다!',
    'Write a review for this item.2': '리뷰를 작성해 주세요.',
    '리뷰를 입력해 주세요. 자세한 리뷰는 크리에이터에게 큰 응원이 됩니다.':
      '리뷰를 입력해 주세요. 자세한 리뷰는 크리에이터에게 큰 응원이 됩니다.',
    'Get MarppleShop points for both you and your seller by writing a review.':
      '크리에이터를 응원하는 리뷰를 등록하시면 간단한 심사 후 최대 {{order_point}}P를 적립해드립니다.\n크리에이터에게도 최대 {{seller_point}}P의 작품 활동 지원금을 드립니다.',
    'Post Review': '작성 완료',
    'Thank you for your comment': '리뷰를 작성해주셔서 감사합니다',
    'Continue Shopping': '쇼핑 계속하기',
    'Wrong sns': 'SNS 주소가 잘못되었습니다. 확인해 주세요.',
    'Maximum of MAX photos': '{{max}}장 이하로 올려주세요.',
    'Agree to terms of review': '리뷰 활용 약관 동의',
    view: '보기',
    'The status will be changed to Delivered': "리뷰를 작성하시면 '배송완료'로 변경됩니다.",
    'Discontinued product': '판매 보류 상품',
    'No reviews have been written.': '리뷰가 없습니다.',
    'No Photo review.': '사진 리뷰가 없습니다.',
    'There are no reviews available to write.': '작성 가능한 리뷰가 없습니다.',
    '리뷰 응원 완료': '리뷰 응원 완료',
    '리뷰 작성 완료': '리뷰 작성 완료',
    '리뷰 응원이 완료된 상품입니다.': '리뷰 응원이 완료된 상품입니다.',
  },

  order: {
    'Sold Out': '품절되었거나 개편된 상품입니다.',
    'Checking deposit': '입금 확인중',
    'Order Placed': '제작 준비중',
    Processing: '제작중',
    'Preparing to Ship': '배송준비중',
    Shipped: '배송중',
    Delivered: '배송완료',
    Delivered2: '구매확정',
    Cancelled: '주문취소',
    'Confirming Cancellation': '주문 취소 확인 중',
    'Order details': '구매내역 상세',
    'Order Number': '주문 번호',
    Tracking: '배송조회',
    'Cancel Order': '주문 취소',
    'Ordered Items': '주문 상품 정보',
    'Product Details': '상품정보',
    'Size/Quantity': '사이즈/수량',
    Price: '금액',
    'Payment Info': '결제정보',
    Method: '결제 수단',
    'Paid at': '승인 시간',
    Paid: '결제 금액',
    Amount: '결제 예정 금액',
    Confirming: '입금 확인 중',
    'Change Payment Method': '결제 수단 변경',
    Receipt: '증빙',
    'Request Receipt': '증빙 신청',
    cancel_req_err: '다시 시도해도 동작하지 않을 경우에는 고객센터로 문의해 주세요.',
    재구매: '재구매',
    '배송지 수정': '배송지 수정',
    배송지: '배송지',
    '배송 메모': '배송 메모',
    '결제 정보': '결제 정보',
    마플샵배송: '마플샵배송',
    셀러배송: '크리에이터 배송',
    디지털상품: '디지털 상품',
    주문번호: '주문번호',
    외몇개: '외 {{count}}개',
    주문일시: '주문일시',
    구매불가: '구매불가',
    '배송지 수정이 완료되었습니다.': '배송지 수정이 완료되었습니다.',
    invoiceCopy: '운송장 번호를 클립보드에 복사했습니다.',
    permissionDeny: '권한이 없습니다.',
    '까지 다운로드 가능': '<span>{{date}}</span> 까지 다운로드 가능',
    '도착 예정': '<span>{{date}}</span> 도착 예정',
    다운로드: '다운로드',
    '다운로드 만료': '다운로드 만료',
    '구매내역으로 이동': '구매내역으로 이동',
    '총 상품 금액': '총 상품 금액',
    '총 배송비': '총 배송비',
    '쿠폰 할인': '쿠폰 할인',
    '포인트 사용': '포인트 사용',
    '총 주문 금액': '총 주문 금액',
    연락처: '연락처',
  },

  order_list: {
    'Order Status': '구매내역',
    Products: '상품정보',
    Total: '금액',
    Status: '진행상태',
    VIEW: '상세보기',
    'No orders yet': '주문하신 상품이 없어요.',
    '마음에 드는 상품을 찾아볼까요?': '마음에 드는 상품을 찾아볼까요?',
    '상품 보러 가기': '상품 보러 가기',
  },

  my_info: {
    'Personal Information Modify': '개인정보',
    Email: '이메일',
    Password: '비밀번호',
    'Change Password': '비밀번호 변경',
    'This email address is not available.':
      '이메일 형식이 잘못되었습니다. 정상적인 이메일로 변경 후 이용해 주세요.',
    'Nick Name': '닉네임',
    BirthDay: '생년월일',
    Name: '이름',
    Phone: '휴대전화번호',
    'Only Numbers': '- 없이 숫자만 입력해 주세요.',
    '스토어 url': '스토어 url',
    Gender: '성별',
    MALE: '남자',
    FEMALE: '여자',
    'SMS News': '문자 소식 받기',
    'Email News': '이메일 마케팅 수신동의 (선택)',
    YES: '예',
    NO: '아니오',
    Modify: '변경',
    Withdrawal: '회원 탈퇴',
    탈퇴하기: '탈퇴하기',
    'It has not changed. please try again.': '변경되지 않았습니다. 다시 시도해 주세요.',
    Changed: '변경되었습니다.',
    'There is no user information. Please log in again after login.':
      '유저 정보가 없습니다. 로그인 후 다시 이용해 주세요.',
    'fail please try again.': '탈퇴가 되지 않았습니다. 다시 시도해 주세요.',
    'Do you really want to leave?': '정말 탈퇴하시겠습니까?',
    Success: '탈퇴되었습니다.',
    'ex) 19940203': '8자로 입력해 주세요. ex) 19940203',
    check_birthday: '올바른 생년월일을 입력해주세요.',
    'Only Numbers and Text': '문자와 숫자만 입력해 주세요.',

    '이메일 주소 (ID)': '이메일 주소 (ID)',
    '이메일 변경': '이메일 변경',
    'ⓘ 위 이메일로 주문 내역 메일이 전송됩니다.': '위 이메일로 주문 내역 메일이 전송됩니다.',
  },

  withdrawal: {
    '고객님 마플샵을 정말 탈퇴하시나요?': '고객님 마플샵을 <span class="m_br"></span>정말 탈퇴하시나요?',
    '회원 탈퇴 전 아래 사항을 숙지해 주세요.': '회원 탈퇴 전 아래 사항을 숙지해 주세요.',
    ol_1: '1. 일반 회원인 경우, 회원 탈퇴 후 재가입이 가능하지만 구매하신 컨텐츠 / 주문 내역 / 보유 포인트 등 회원 정보가 모두 삭제됩니다.',
    ol_2: `
2. 크리에이터인 경우, 진행 중인 거래 '0건', 잔여 수익 금액 '0원'이어야 하며, 판매 상품의 배송 출고 / CS /
수익 정산이 모두 완료된 뒤 탈퇴 신청이 가능합니다. 탈퇴 후에는 크리에이터샵과 상품 판매 / 정산 내역 등
회원 정보가 모두 삭제되며 복구할 수 없습니다. 자세한 사항은 마플샵 스튜디오에서 확인 부탁드리며 진행이
어려우신 경우, 고객 센터(<a href="tel:1566-5496">1566-5496</a> 또는
<a href="mailto:cs@marppleshop.com">cs@marppleshop.com</a>)로 문의해 주시면 안내해 드리겠습니다.`,
    ol_3: '3. 고객님의 개인 정보는 회원 탈퇴 후에도 상품 반품 및 A/S를 위하여 「전자상거래 등에서의 소비자 보호에 관한 법률」에 의거한 마플샵 고객 정보 보호 정책에 따라 관리됩니다.',
    '진행 중인 거래': '진행 중인 거래',
    '잔여 수익 금액': '잔여 수익 금액',
    '보유 포인트': '보유 포인트',
    몇건: '{{count}}건',
    몇원: '{{amount}}원',
    몇포인트: '{{point}}P',
    그동안:
      '그동안 마플샵을 이용해 주셔서 감사합니다.<br />보다 나은 서비스로 고객님을 다시 뵐 수 있도록 노력하겠습니다.',
    '회원 탈퇴가 완료되었습니다.': '회원 탈퇴가 완료되었습니다.',
    '회원 탈퇴를 완료할 수 없습니다.': '회원 탈퇴를 완료할 수 없습니다.',
    '진행 중인 거래 0건':
      "진행 중인 거래 '0건', 잔여 수익 금액 '0원'이어야만 회원 탈퇴가 가능합니다. 자세한 사항은 마플샵 스튜디오에서 확인 부탁드리며 진행이 어려우신 경우, 고객 센터로 문의해 주시면 안내해 드리겠습니다.",
    '평일 10:00~18:00': '평일 10:00~18:00',
  },

  creator_account_info: {
    Email: '이메일',
    'Save Changes': '이메일 변경',
    Password: '비밀번호',
    'Reset password': '비밀번호 변경',
    Name: '이름',
    BrandName: '브랜드 이름',
    Phone: '휴대전화번호',
    '스토어 url': '샵 URL',
    Modify: '변경',
    '유효한 도메인을 입력하세요.': '유효한 도메인을 입력하세요.',
  },

  phonecases: {
    'Personalize Your Own Phone case': '나만의 폰케이스 만들기',
    'Make it with your own photos':
      "나만의 사진, 아트워크 작품을<span class='br'></span>케이스로 만들어보세요.",
    'Upload your image': '내 사진 올리기',
    'Insert Text': '텍스트 넣기',
    'Free Designs': '무료 디자인 제공',
    'SELECT YOUR SMART PHONE': '내 스마트폰 선택하기',
    'SELECT brand': '브랜드 선택',
    'SELECT model': '모델 선택',
    'ex brand..': 'ex 아이폰, 갤럭시, LG',
    'ex model..': 'ex 아이폰 XS, 갤럭시 S10, LG V40...',
    'CUSTOM YOUR CASE': '폰케이스 보러 가기',
    'SHOP * CASES': '영상 속 네온샌드 케이스 보러가기',
    'please choose': '선택해 주세요',
    'Please select brand and model.': '브랜드와 모델을 선택해 주세요.',
  },

  ad_res: {
    '지금 마플샵 사전예약 신청하세요 !': '지금 마플샵 사전접수 신청하세요 !',
    '마플샵의 첫번째 셀러가 되어주세요':
      '마플샵의&nbsp;<span class="w_br m_br"></span>첫번째 크리에이터가&nbsp;<span class="w_br m_br"></span>되어주세요',
    '마플샵의 두번째 셀러가 되어주세요':
      '마플샵의&nbsp;2기<span class="w_br m_br"></span>크리에이터가&nbsp;<span class="w_br m_br"></span>되어주세요',
    '2020년 1월, 크리에이터 커머스 플랫폼 마플샵이 오픈합니다.':
      '2020년 1월,&nbsp;<span class="w_br m_br"></span>크리에이터 커머스 플랫폼&nbsp;<span class="w_br m_br"></span>마플샵이 오픈합니다.',
    '2020년 1월 28일 ~ 2020년 2월 20일 크리에이터 커머스 플랫폼 마플샵의 2기 셀러를 모집합니다.':
      '2020년 1월 28일 ~ 2020년 2월 20일 크리에이터 커머스 플랫폼 마플샵의 2기 크리에이터를 모집합니다.',
    '첫번째 셀러로 당첨되시는 분들에게는 상품제작 및 촬영 서비스등 다양한 혜택을 제공해 드립니다.':
      '첫번째 크리에이터로 당첨되시는 분들에게는 <span class="w_br m_br"></span>상품제작 및 촬영 서비스등 <span class="w_br m_br"></span>다양한 혜택을 제공해 드립니다.',
    '2기 셀러로 선정되시는 분들에게는 상품 제작 및 촬영 서비스 등 다양한 혜택을 제공해 드립니다.':
      '2기 크리에이터로 선정되시는 분들에게는 상품 제작 및 촬영 서비스 등 다양한 혜택을 제공해 드립니다.',
    '아래 간단한 질문에 대한 답변을 작성해주시면 검토 후 개별 연락 드릴 예정입니다.':
      '아래 간단한 질문에 대한 <span class="w_br m_br"></span>답변을 작성해주시면 <span class="w_br m_br"></span>검토 후 개별 연락 드릴 예정입니다.',
    '지금 신청하기 !': '지금 신청하기 !',
    '원하는 마플샵 웹 도메인명을 써주세요(영문/숫자 20자 이내)': `원하는 마플샵 웹 도메인명을 써주세요<span class='m_br'></span>(영문/숫자 2자 이상 20자 이내)`,
    '당첨 결과 통지를 위해 이메일 주소를 입력해 주세요': `당첨 결과 통지를 위해&nbsp;<span class='m_br'></span>이메일 주소를 입력해 주세요`,
    '당첨되시면 연락드릴 본인의 연락처를 입력해 주세요': `당첨되시면 연락드릴&nbsp;<span class='m_br'></span>본인의 연락처를 입력해 주세요<span class='m_select'>&nbsp;(선택)</span>`,
    '판매하게 될 콘텐츠와 관련하여 마플샵 입점심사과정에서 참고할 수 있는 자료나 웹사이트를 알려주세요': `판매하게 될 콘텐츠와 관련하여&nbsp;<span class='m_br'></span>마플샵 입점심사과정에서 참고할 수 있는&nbsp;<span class='m_br'></span>자료나 웹사이트를 알려주세요<span class='m_select'>&nbsp;(선택)</span>`,
    '판매하게 될 콘텐츠 또는 디자인과 관련하여 운영하고 계신 SNS 주소를 알려주세요': `판매하게 될 콘텐츠 또는&nbsp;<span class='m_br'></span>디자인과 관련하여 운영하고 계신&nbsp;<span class='m_br'></span>SNS 주소를 알려주세요<span class='m_select'>&nbsp;(선택)</span>`,
    '마지막이에요! 본인 또는 브랜드를 간략하게 소개해 주세요 (200자 이하)': `마지막이에요!&nbsp;<span class='m_br'></span>본인 또는 브랜드를&nbsp;<span class='m_br'></span>간략하게 소개해 주세요 (200자 이하)`,
    '마플샵 셀러 신청이 완료되었습니다': `마플샵&nbsp;<span class='m_br'></span>크리에이터 신청이&nbsp;<span class='w_br m_br'></span>완료되었습니다`,
    '저희와 함께 하고 싶으신가요?': `저희와&nbsp;<span class='m_br'></span>함께 하고&nbsp;<span class='w_br m_br'></span>싶으신가요?`,
    '기업 파트너쉽 문의는':
      '기업 파트너쉽 문의는&nbsp;<span class="w_br m_br"></span><a href="mailto:partner@marppleshop.com">partner@marppleshop.com</a>으로 연락주세요',
    '영문/숫자 20자 이내로 입력해 주세요': '영문/숫자 2자 이상 20자 이내로 입력해 주세요',
    '문자/숫자 20자 이내로 입력해 주세요': '문자/숫자 2자 이상 20자 이내로 입력해 주세요',
    'sample@sample.com': 'sample@sample.com',
    '엔터키를 누르면 다음으로 진행됩니다': '엔터키를 누르면 다음으로 진행됩니다',
    '버튼을 누르면 다음으로 진행됩니다': '버튼을 누르면 다음으로 진행됩니다',
    '010-0000-0000': '010-0000-0000',

    '올바른 연락처 형식을 입력해 주세요': '올바른 연락처 형식을 입력해 주세요',
    '올바른 URL 형식을 입력해 주세요': '올바른 URL 형식을 입력해 주세요',
    '200자 이하로 입력해 주세요': '200자 이하로 입력해 주세요',
    '닉네임 또는 브랜드 명을 입력하세요.': '닉네임 또는 브랜드 명을 입력하세요.',
    '브랜드 명을 입력하세요.': '브랜드 명을 입력하세요.',
    '도메인 명을 입력하세요.': '도메인 명을 입력하세요.',
    '간략하게 소개해 주세요': '간략하게 소개해 주세요',
    '참고 할 수 있는 사이트를 입력해 주세요.': '참고 할 수 있는 사이트를 입력해 주세요.',
    '버튼을 누르시면 사전예약이 완료됩니다 !': '버튼을 누르시면 사전예약이 완료됩니다 !',
    '사전신청이 완료되지 않았습니다.': `크리에이터 신청이 완료되지 않았습니다.<br>다시 시도해주시거나 <a href='mailto:partner@marppleshop.com' style='margin-left: 5px;'>partner@marppleshop.com</a>으로 문의해 주세요.`,
    '사전 신청은 PC에서 해주세요.': '사전 신청은 PC에서 해주세요.',
    '유효한 도메인을 입력하세요.': '유효한 도메인을 입력하세요.',
    '유효한 브랜드 명을 입력하세요.': '유효한 브랜드 명을 입력하세요.',
    '이미 사용중인 도메인입니다.': '이미 사용중인 도메인입니다.',
    '마플샵 사전신청 접수 기간은 2019.12.20 ~ 2020.1.20 까지 입니다. 검토후 선정되신 분들은 순차적으로 개별 연락 드리고 있습니다.': `마플샵 사전신청 접수 기간은&nbsp;<span class='m_br'></span>2019.12.20 ~ 2020.1.20 까지 입니다.&nbsp;<span class='w_br m_br'></span>검토후 선정되신 분들은 순차적으로&nbsp;<span class='m_br'></span>개별 연락 드리고 있습니다.`,
    '마플샵 사전신청 접수 기간은 2020.01.28 ~ 2020.02.20 까지 입니다. 검토후 선정되신 분들은 순차적으로 개별 연락 드리고 있습니다.': `마플샵 사전신청 접수 기간은&nbsp;<span class='m_br'></span>2020.01.28 ~ 2020.02.20 까지 입니다.&nbsp;<span class='w_br m_br'></span>검토후 선정되신 분들은 순차적으로&nbsp;<span class='m_br'></span>개별 연락 드리고 있습니다.`,
    '담당자가 제휴제안 내용을 검토하는 과정에서 추가 자료를 요청하거나 재문의할 수 있습니다. 제안 내용 및 관련자료는 제휴 검토 목적으로만 이용됩니다.': `담당자가 제휴제안 내용을 검토하는 과정에서&nbsp;<span class='m_br'></span>추가 자료를 요청하거나 재문의할 수 있습니다.&nbsp;<span class='w_br'></span>제안 내용 및 관련자료는 제휴 검토&nbsp;<span class='m_br'></span>목적으로만 이용됩니다.`,
    '30일 이내에 제안자가 담당자의 자료요청 또는 재문의에 회신을 하지 않는 경우 해당 제휴제안은 종료 처리됩니다.': `30일 이내에 제안자가 담당자의 자료요청 또는&nbsp;<span class='m_br'></span>재문의에 회신을 하지 않는 경우&nbsp;<span class='w_br m_br'></span>해당 제휴제안은 종료 처리됩니다.`,
    삭제: '삭제',
    '보내주실 디자인, 포트폴리오 등이 있다면 첨부해 주세요.':
      '보내주실 디자인, 포트폴리오 등이 있다면 첨부해 주세요.',
    '업로드 중...': '업로드 중...',
    '개인정보 수집’이용 동의에 관한 안내사항': '개인정보 수집&middot;이용 동의에 관한 안내사항',
    '- 수집’이용기관명:(주)마플코퍼레이션': `
<p>- 수집&middot;이용기관명: (주)마플코퍼레이션</p>
<p>- 수집항목: (필수항목) 성명, 연락처, 이메일</p>
<p>- 수집&middot;이용목적: 마플샵 크리에이터 신청 및 서비스 제공</p>
<p>- 보유 및 이용기간: 크리에이터 신청 문의 일로부터 1년</p>
`,
    '*개인정보 제공자는 개인정보의 수집 및 이용에 관한 동의를 거부할 권리가 있으며, 그러한 경우 크리에이터접수 안내가 불가능 합니다.':
      '* 개인정보 제공자는 개인정보의 수집 및 이용에 관한 동의를 거부할 권리가 있으며, 그러한 경우 크리에이터 접수 안내가 불가능 합니다.',
    '상기와 같이 개인정보의 수집 및 이용에 관하여 동의합니다.':
      '상기와 같이 개인정보의 수집 및 이용에 관하여 동의합니다.',
    '개인정보 수집 이용에 동의해 주세요': '개인정보 수집 이용에 동의해 주세요',
    참고사항: '참고사항',
    '- 판매하는 콘텐츠의': `
<p>- 판매하는 콘텐츠의 저작권은 판매주체인 크리에이터에게 있으며 사전 접수 신청시 저작권 소유자 확인을 인증한것으로 간주합니다.</p>
<p>- 저작권 등 타인의 권리를 침해하거나 명예를 훼손하는 이미지, 디자인 등의 게시에 대한 책임은 크리에이터에게 있습니다.</p>
<p>- 제공된 개인정보는 개인정보 제공자가 동의한 내용 외의 다른목적으로 활용하지 않으며, 보유기간 내에 제공된 개인정보의 이용을 거부하고자 할 때에 자유롭게 개인정보 관리책임자(전자우편:&nbsp;<a href='mailto:partner@marppleshop.com'>partner@marppleshop.com</a>, 고객센터&nbsp;<a href='tel:1566-5496'>1566-5496</a>)를 통해 열람, 정정, 삭제를 요구할 수 있습니다.
`,
    '파일 업로드 중입니다. 업로드 후에 다시 시도해 주세요.':
      '파일 업로드 중입니다. 업로드 후에 다시 시도해 주세요.',

    // 여기부터
    '당신만의 특별한 마플샵을 런칭해볼까요?': '당신만의 특별한 마플샵을 런칭해볼까요?',
    필수입력: '필수입력',
    브랜드명: '브랜드명',
    BRAND: 'BRAND',
    '영문/한글/숫자 조합하여 2자 이상 20자 이내로 입력해주세요.':
      '영문/한글/숫자 조합하여 2자 이상 20자 이내로 입력해주세요.',
    '영문/숫자 조합하여 2자 이상 20자 이내로 추후 마이페이지에서 수정이 가능합니다.':
      '영문/숫자 조합하여 2자 이상 20자 이내로 추후 마이페이지에서 수정이 가능합니다.',
    도메인명: '도메인명',
    '우리에게 당신의 숨겨져있는 가능성을 보여주세요!': '우리에게 당신의 숨겨져있는 가능성을 보여주세요!',
    '1개 이상 필수 입력': '1개 이상 필수 입력',
    '운영중인 SNS': '운영중인 SNS',
    '포트폴리오 첨부': '포트폴리오 첨부',
    '파일 업로드': '파일 업로드',
    '첨부파일은 jpg':
      '첨부파일은 jpg, png, ppt, keynote, pdf 형식으로 50MB이하만 가능 (최대 3개 업로드 가능)',
    '심사에서 참고할 사이트': '심사에서 참고할 사이트',
    이메일: '이메일',
    '이메일 입력': '이메일 입력',
    휴대전화번호: '휴대전화번호',
    '휴대전화번호(-없이 숫자만 입력)': '휴대전화번호(-없이 숫자만 입력)',
    '마지막으로 본인이나 브랜드에 대해 깊은 인상을 남겨주세요!':
      '마지막으로 본인이나 브랜드에 대해 깊은 인상을 남겨주세요!',
    '본인 또는 브랜드 소개': '본인 또는 브랜드 소개',
    '개인정보 수집•이용 동의에 관한 안내사항을 확인해보세요.':
      '개인정보 수집•이용 동의에 관한 안내사항을 확인해보세요.',
    '전체 항목에 동의합니다.': '전체 항목에 동의합니다.',
    '개인정보의 수집 및 이용에 관하여 동의합니다.': '개인정보의 수집 및 이용에 관하여 동의합니다.',
    '크리에이터로 선정되면 어디로 연락 드릴까요?': '크리에이터로 선정되면 어디로 연락 드릴까요?',
    본문보기: '본문보기',
    '만 14세 이상입니다.': '만 14세 이상입니다.',
    '꼭 읽어주세요!': '꼭 읽어주세요!',
    '판매하는 콘텐츠의 저작권은':
      '판매하는 콘텐츠의 저작권은 판매 주체인 크리에이터에게 있으며 사전 접수 신청시 저작권 소유자 확인을 인증한 것으로 간주합니다.',
    '저작권 등 타인의 권리를':
      '저작권 등 타인의 권리를 침해하거나 명예를 훼손하는 이미지, 디자인등의 게시에 대한 책임은 크리에이터에게 있습니다.',
    '제공된 개인정보는': `제공된 개인정보는 개인정보 제공자가 동의한 내용 외의 다른 목적으로 활용하지 않으며, 보유 기간 내에 제공된 개인정보의 '이용을 거부하고자 할 때에 자유롭게 개인정보 관리 책임자 (partner@marppleshop.com), 고객센터 (1566-5496)을 통해 '열람, 정정, 삭제를 요구할 수 있습니다.'`,
    '신청 완료하기': '신청 완료하기',
    '개인정보 수집 및 이용에 관한 동의': '개인정보 수집 및 이용에 관한 동의',
    '수집•이용기관명 : (주)마플코퍼레이션': '수집•이용기관명 : (주)마플코퍼레이션',
    '- 수집항목 : (필수항목) 성명, 연락처, 이메일': '- 수집항목 : (필수항목) 성명, 연락처, 이메일',
    '- 수집•이용목적 : 마플샵 셀러 신청 및 서비스 제공':
      '- 수집•이용목적 : 마플샵 크리에이터 신청 및 서비스 제공',
    '- 보유 및 이용기간 : 셀러 신청 문의 일로부터 1년':
      '- 보유 및 이용기간 : 크리에이터 신청 문의 일로부터 1년',
    '* 개인정보 제공자는 개인정보의 수집 및 이용에 관한 동의를 거부할 권리가 있으며, 그러한 경우 셀러접수 안내가 불가능 합니다.':
      '* 개인정보 제공자는 개인정보의 수집 및 이용에 관한 동의를 거부할 권리가 있으며, 그러한 경우 크리에이터접수 안내가 불가능 합니다.',
    '최대 3개의 파일만 선택 가능합니다.': '최대 3개의 파일만 선택 가능합니다.',
    '파일 용량이 너무 큽니다.': '파일 용량이 너무 큽니다.<br>50MB 이하로 업로드해 주세요.',
    '파일업로드가 실패했습니다.':
      '파일업로드가 실패했습니다.<br>다시 시도해주시거나<br><a href="mailto:partner@marppleshop.com">partner@marppleshop.com</a>에 문의해 주세요.',
    '올바른 이메일 형식을 입력해 주세요': '올바른 이메일 형식을 입력해 주세요',
    '중복된 이메일입니다': '중복된 이메일입니다',
    '만 14세 이상 동의해 주세요.': '만 14세 이상 동의해 주세요.',
    '셀러 신청 완료': '크리에이터 신청 완료',
    '마플샵 생성 링크가 이메일로 전송되었습니다.': '마플샵 생성 링크가 이메일로 전송되었습니다.',
    '마플샵 메인 가기': '마플샵 메인 가기',
    '심사에 참고할 자료가 부족하거나':
      '심사에 참고할 자료가 부족하거나, 부적절한 소재의 내용이 포함되어있는 경우 신청이 거절 될 수 있는 점 미리 양해 부탁드립니다.',
    '기업 파트너십 문의':
      '기업 파트너십 문의 <a href="mailto:partner@marppleshop.com">partner@marppleshop.com</a>',
    '셀러신청이 완료되지 않았습니다.':
      "크리에이터 신청이 완료되지 않았습니다.<br>다시 시도해주시거나 <a href='mailto:partner@marppleshop.com' style='margin-left: 5px;'>partner@marppleshop.com</a>으로 문의해 주세요.",
    '같은 이메일로 이미 신청되었습니다.': `같은 이메일로 이미 신청되었습니다. 메일이 안오셨다면 스팸함을 확인해봐주세요. 그래도 없다면 <a href='mailto:partner@marppleshop.com'>partner@marppleshop.com</a> 으로 문의해주세요.`,
    '마플샵 생성 링크를 전송했습니다.': '{{email}}으로 마플샵 생성 링크를 전송했습니다.',
    '전송된 링크를 클릭하여 나만의 마플샵을 만들고 지금 바로 상품도 만들어보세요.':
      '전송된 링크를 클릭하여 나만의 마플샵을 만들고 지금 바로 상품도 만들어보세요.',
    '2자 이상 20자 이내로 입력해 주세요': '2자 이상 20자 이내로 입력해 주세요',
    '메일이 오지 않았다면 스팸함을 확인해 주시고, 그래도 없다면 cs@marppleshop.com 으로 문의해 주세요.':
      '메일이 오지 않았다면 스팸함을 확인해 주시고, 그래도 없다면&nbsp;<a href="mailto:cs@marppleshop.com">cs@marppleshop.com</a> 으로 문의해 주세요.',
    '유튜브, 인스타그램, 트위터 URL 등': '유튜브, 인스타그램, 트위터 URL 등',
    '개인 웹사이트, 노트폴리오, 비핸스 URL 등': '개인 웹사이트, 노트폴리오, 비핸스 URL 등',
  },

  error: {
    SORRY: '존재하지 않는 페이지입니다.',
    "We couldn't find that page.": '링크가 잘못되었거나 페이지가 삭제되었습니다.',
    'Go home': '홈으로',
    'This product is out of stock.': '품절되었거나 개편된 상품입니다.',
  },

  shop_home: {
    'Go to Sign in': '로그인 하러가기',
    'card1-maintitle': '안녕, 마플샵은 처음이지?',
    'card2-maintitle': `3분이면 뚝딱, 나만의 샵`,
    'card3-maintitle': `ARE YOU 크리에이터, 아티스트, 크리에이터?`,
    'card4-maintitle': `판매부터 마케팅까지 한 번에`,
    'card5-maintitle': `상품제작-배송-CS까지 모-두 책임지는`,
    'Set the price for this t-shirt': `티셔츠 판매 가격을 <br /> 설정해 주세요`,
    "Here's how much you'll get!": `판매했을 때 얻을 수 있는 <br /> 수익입니다`,
    'card1-mobile1':
      'https://s3.marpple.co/files/u_1165777/2021/10/original/a75fcd1f79a6efddb908d194e46bf82419f691b61.png',
    'card1-title1':
      'https://s3.marpple.co/files/u_1165777/2021/6/original/cfdc83990fd4d0a7d05dd2f75991555b3539aeb03.png',
    'card1-detail1':
      '나만의 브랜드를 만들 수 있다고? <br /> 굿즈를 만들어서 판매할 수 있다고? <br /><br /> 이젠 상품을 직접 만들지 않아도 <br /> 내가 디자인한 굿즈를&nbsp<span class="m_br"></span>판매할 수 있습니다. <br />  <br />크리에이터, 인플루언서, 아티스트 <br /> 핫한 당신을 위해 <br /> <br />상품 제작, 배송, CS, 재고 관리까지 <br />모-두 다 마플샵이 해드립니다.',
    'card1-title2': '<p>단, 3분이면</p><p>뚝딱</p>',
    'card1-detail2':
      '단, 3분이면 누구나 쉽게 <br /> 상품을 만들고 판매할 수 있어요! <br /> <br /> ONE OF A KIND - <br /> 상상만 했던 아이디어가&nbsp<span class="m_br"></span> 600가지 상품에 <br/> 나만의 굿즈로. <br /> <br /> 수천 개의 상품을 <br /> 판매할 수 있습니다',
    'card1-title3': '<p> 내 맘대로 </p><p> 정하는 가격 </p>',
    'card1-detail3':
      '오늘은 얼마나 팔렸을까? <br />  또 어떤 상품을 만들어 볼까? <br /> 두근두근 <br/> </br/> 가격도 내 맘대로 <br/> 수익도 내 맘대로 <br /> 내가 정한 대로 Flex',
    'card1-title4': '<p> 당신의 </p><p> 채널에서 </p>',
    'card1-detail4':
      '당신의 팬들과 소통하듯 재밌게 <br /> 내가 만든 상품을 DROP 해보세요 <br /> <br /> 나만의 OO샵을 <br /> 유튜브, 페이스북, 인스타그램에 <br /> 쉽게 연동할 수 있어요.',
    'card1-youtube': `유튜브 채널에서 <br /> 나의 굿즈 출시를 알려보세요`,
    'card1-instagram': `인스타그램 피드에 <br/> 쇼핑 태그를 설정하여 <br /> 나의 굿즈를 판매할 수 있습니다.`,
    'card1-facebook': `페이스북 비즈니스 페이지와 <br /> 연동할 수 있습니다.`,
    'card1-title5': '<p> 알아서 </p><p> 팔아주는 </p>',
    'card1-detail5': `'아이디어'만 있으면 됩니다. <br /> 주문 확인, 재고 관리,&nbsp<span class='m_br'></span>고객 응대, 배송까지 <br /> <span is_mobile='true'> <br/> </span> 모-두 다 마플샵이&nbsp<span class='m_br'></span>알아서 해드립니다`,
    'card1-step1': `REAL 판매`,
    'card1-step1-detail': `와우! OO샵으로 주문이 들어왔네요.`,
    'card1-step2': `제작-배송-ing`,
    'card1-step2-detail': `마플샵에서 한 땀 한 땀-척척 제작해서 전국으로-전 세계로 배송`,
    'card1-step3': `Oh My Money!`,
    'card1-step3-detail': `제품이 팔릴 때마다 내 통장에 차곡차곡!`,
    'card2-title1': `<p> 3분이면 뚝딱, </p> <p> 나만의 OO 샵 </p>`,
    'card2-detail1': ` 아직도 물건을 직접 만들어 판매하시나요? <br /> Oh, That’s No-No. <br /> <br /> 마플샵에서는 <br /> 상품을 고르고 디자인한 다음 <br /> 가격을 정하기만 하면 끝!`,
    'card2-title2': '상품을 고르고',
    'card2-detail2': `의류, 폰케이스, 모자, 쿠션, 가방 등 <br /> 600가지 상품으로`,
    'card2-design1': `이미지를 넣어보고`,
    'card2-design1-detail': `내 디자인을 상품에 넣어보고, 위치도 조절해봐요!`,
    'card2-design2': `상품 컬러도 바꿔봐요`,
    'card2-design2-detail': `제품마다 다양한 컬러를 선택할 수 있어요!`,
    'card2-design3': `멋진 문구도 넣어보고`,
    'card2-design3-detail': `다양한 서체와 컬러로 멋진 문구를 넣어볼 수 있어요!`,
    'card2-title3': '가격만 정하면',
    'card2-detail3': `내 상품의 가격을 정해보세요 <br /> 디자인도 가격도 내 맘대로 SWAG`,
    'card2-title4': '짠! <br/> 내가 만든 OO 샵',
    'card2-detail4': `지금까지 본 적 없는&nbsp<span class='m_br'></span>신개념 나의 쇼핑몰! <br/> 제품 NO! 재고 NO! 운영 NO! <br /> 상품 제작, 재고 사입, 운영 및 CS 아무것도 필요 없습니다`,
    'card3-title1': `<p> ARE YOU </p> <p> 크리에이터? </p> <p> 아티스트? </p>`,
    'card3-detail1': ` 마플샵은 크리에이터들의 굿즈 <br /> 그리고 세상 모든 아티스트들의&nbsp<span class='m_br'></span>작품을 <span class='d_br'></span> 사람들의 일상 속에 <span class='d_br'></span> 소개하기도 해요 <br /> <br /> 우리 모두는 크리에이터입니다 <br /> 내가 쓴 문구, 내가 찍은 사진,&nbsp<span class='m_br'></span>내가 그린 그림, <br /> 나의 모든 아이디어는 <span class='d_br'></span> 상품이 될 수 있습니다`,
    'card3-title2': `<p> 크리에이터들의 </p> <p> 굿즈 판매를 위한 </p>`,
    'card3-detail2': `마플샵은 크리에이터들의 <br /> 굿즈 판매를 위한 <br /> 커머스 플랫폼입니다 <br /> <br /> 상품을 만들고 팬들과 나누고 <br /> 알리기만 하면 됩니다 <br /> <br /> 상품 제작부터 배송-CS까지 <br /> 마플샵이 모-두 다 해드립니다`,
    'card3-design1': `마플샵에서 <br /> 쉽게 굿즈를 <br /> 만들고`,
    'card3-design1-detail': `단, 몇 분 안에 이미지를 넣거나 <br /> 문구를 넣어 쉽게 굿즈를 <br /> 만들 수 있어요`,
    'card3-design2': `개인 채널에 <br /> 굿즈를 <br /> 홍보하면`,
    'card3-design2-detail': `유튜브, 트위치, 인스타, 페이스북 등 <br /> 나의 굿즈를 다양한 채널에 <br /> 노출하고 판매할 수 있어요`,
    'card3-design3': `앉아만 있어도 <br /> 돈을 벌 수 <br /> 있어요`,
    'card3-design3-detail': `주문 확인, 재고 관리, 고객 응대, <br /> 배송까지 마플샵이 전부 <br /> 다- 해드립니다`,
    'card3-title3': `<p> 세상 모든&nbsp<span class='m_br'></span> 아티스트들의 </p><p> 성공을&nbsp<span class='m_br'></span> 응원합니다 </p>`,
    'card3-detail3': `당신의 작품이 <br /> 많은 사람들의 일상에, <br /> 의미 있는 순간에 <br /> 함께합니다. <br /> <br /> 수많은 사람들이 <br /> 당신의 멋진 작품을 <br /> 기다리고 있습니다`,
    'card3-design4': `당신의 <br /> 멋진 작품을 <br /> 선택하고`,
    'card3-design4-detail': `이미지, 사진 등 <br /> 다양한 작품을 올려서 <br/> 만들어보세요`,
    'card3-design5': `마플샵의 <br /> 상품을 <br /> 선택하면`,
    'card3-design5-detail': `당신의 작품을 공유할 수 있는 <br /> 상품이 마플샵에는 600가지가 <br /> 넘게 있습니다`,
    'card3-design6': `일상 속에서 <br /> 만나 볼 수 <br /> 있어요`,
    'card3-design6-detail': `당신의 작품이 많은 사람들의 <br /> 일상속에, 의미있는 순간에 <br /> 함께할 수 있어요`,
    'card3-title4': `<p> 우리 모두는 </p><p> 크리에이터입니다 </p>`,
    'card3-detail4': `우리 모두는 아이디어를&nbsp<span class='m_br'></span>판매 할 수 있습니다. <br /> <br /> 내가 쓴 문구 <br /> 내가 찍은 사진, 내가 그린 그림, <br/> 나의 모든 아이디어는&nbsp<span class='m_br'></span> 상품이 될 수 있습니다 <br /> <br /> 아이디어가 있나요? <br /> 지금 바로 마플샵에서&nbsp<span class='m_br'></span>시작해보세요`,
    'card4-title1': `<p> 판매부터 </p> <p> 마케팅까지 </p> <p> 한 번에 </p>`,
    'card4-detail1': `마플샵은 성공적인 상품 판매를&nbsp<span class='m_br'></span>위해 <span class='d_br'></span> 다양한 기능은 물론&nbsp<span class='m_br'></span>판매지원 서비스까지 <br /> 제공하고 있습니다 <br /> <br /> 지금 바로 마플샵과 함께 <br /> 성공적인 판매경험을&nbsp<span class='m_br'></span>시작하세요`,
    'card4-title2': `<p> 쉽고 간단한 </p><p> 샵 디자인 </p>`,
    'card4-detail2': `다양한 기능으로 당신의 샵을 <br /> 멋지게 꾸며보세요 <br /> <br /> 클릭 몇 번으로 PC는 물론 <br /> MOBILE까지 완성!`,
    'card4-title3': `<p> 통계 관리 </p><p> 서비스 </p>`,
    'card4-detail3': `실시간으로 판매 현황을 <br /> 확인할 수 있습니다 <br /> <br /> 매출, 수익, 방문자 수 등 <br /> 판매 관련 정보를 제공합니다`,
    tshirt: '티셔츠',
    cushion: '쿠션',
    cup: '머그컵',
    rank1: '1위',
    rank2: '2위',
    rank3: '3위',
    count: '개',
    'Cumulated Product Sales Number': '누적 판매 개수',
    'Cumulated Sales Amount': '누적 판매 금액',
    'Sales Status': '매출 현황',
    'card4-title4': `<p> 다양한 채널과의 </p><p> 손쉬운 연동 </p>`,
    'card4-detail4': `나의 채널에 <br /> 내가 만든 상품을 노출함으로써 <br /> <br /> 더 많은 팬들과 방문자들을 <br /> 나의 마플샵으로 초대하세요`,
    'card5-title1': `<p> 상품 제작 - 배송 </p><p> - CS 까지 </p><p> 모두 책임지는 </p>`,
    'card5-detail1': `네, 모-두 다 해드립니다. <br /> 주문 접수, 결제, 상품 제작, 배송, <br /> 재고관리, CS까지. <br /> <br /> 초기 비용, 재고 부담은 <br /> Don’t Worry`,
    'card5-shop1': `<p> 내가 만든 샵에서 </p><p> 상품이 팔리고 </p>`,
    'card5-shop1-detail': `고객이 상품 주문을 하면 <br /> 마플샵이 알아서 주문 확인 및 <br /> 주문 생산을 하게 됩니다`,
    'card5-shop2': `<p> 안전하게 고객에게 </p> <p> 배송되면 </p>`,
    'card5-shop2-detail': `상품 생산이 완료되면&nbsp<span class='m_br'></span> 마플샵이 상품을 안전하게 <br /> 고객에게 배송합니다`,
    'card5-shop3': `<p> 판매 수익금이 </p> <p> 내 통장에 차곡차곡 </p>`,
    'card5-shop3-detail': `판매 수익금이 미리 입력한 <br /> 내 통장에 입금됩니다`,
    'card5-shop4': `<p> CS 업무를 대신할 </p> <p> 고객센터 운영까지 </p>`,
    'card5-shop4-detail': `상품문의 및 상담 업무,&nbsp<span class='m_br'></span>반품, 교환 안내 등 <br /> 쇼핑몰 운영에 필요한&nbsp<span class='m_br'></span>모든 업무를 대신해드려요`,
    'card5-title2': `<p> 12년간의 </p><p> 상품 제작 노하우 </p>`,
    'card5-detail2': `디지털 프린팅, 실크스크린,&nbsp<span class='m_br'></span>UV 프린팅, <span class='d_br'></span> 원단 날염, 자수,&nbsp<span class='m_br'></span>전사 등 최첨단 프린팅&nbsp<span class='m_br'></span>기술을 바탕으로 <br /> 최고의 상품을 만듭니다 <br /> <br /> 국내 최고의&nbsp<span class='m_br'></span>Print-On-Demand&nbsp<span class='m_br'></span>(주문 제작 프린팅) 기술 및 <br /> 생산운영 시스템으로&nbsp<span class='m_br'></span>최고의 품질을 보장합니다`,
    'footer-customer': `상담가능시간 : 평일 오전 10시 ~ 오후 6시`,
    'footer-info': `
(주)마플코퍼레이션 대표 박혜윤 <br/> <span class='m_br'></span>
사업자등록번호 <span is_mobile='false'> : </span> <span is_mobile='true'> <br /> </span> 105-88-13322 <span class='m_br'></span>&nbsp;
<a href='http://www.ftc.go.kr/bizCommPop.do?wrkr_no=1058813322&amp;apv_perm_no=' class='ftc' target='_blank'>사업자정보 확인</a>&nbsp;통신판매업신고 <span is_mobile='false'> : </span> <span is_mobile='true'> <br /> </span> ${CreatorAddressConstantS.MPS_MAIL_ORDER_SALES} <br /> <span class='m_br'></span>
${CreatorAddressConstantS.MPS_ADDRESS} <br /> 고객센터 : 1566-5496 <span is_mobile='true'> <br /> </span>&nbsp;팩스 : 02-6486-9437`,
    'copyright-info': `마플 콘텐츠의 저작권은 저작권자 또는 제공처에 있으며, <span class='d_br'></span> 이를 무단 사용 및 도용하는 경우 저작권법 등에 따라 법적 책임을 질 수 있음을 알려드립니다.`,
    'Customer Center': '고객센터',
    '1to1chat': '1:1 채팅 상담하기',
    'Go to Customer Service': 'FAQ 바로가기',
  },

  store_header: {
    customer_service: '고객센터 바로가기',
  },

  store_footer: {
    privacy_policy: '개인정보처리방침',
    terms_and_conditions: '이용약관',
    faq: 'FAQ 바로가기',
    customer_service: 'FAQ 바로가기',
    about_us: '소개',
    seller_studio: 'Seller Studio',
    my_store: 'My Store',
    logout: '로그아웃',
    login: '로그인',
    company_name: '(주)마플코퍼레이션',
    detail_info: `
<p>
<span>대표 박혜윤</span>
<span>${CreatorAddressConstantS.MPS_ADDRESS}</span>
</p>
<p>
<span>사업자등록번호 105-88-13322</span>
<span><a
    href='http://www.ftc.go.kr/bizCommPop.do?wrkr_no=1058813322&apv_perm_no='
    class='ftc'
    target='_blank'
    >사업자정보 확인</a>&nbsp;통신판매업 신고 ${CreatorAddressConstantS.MPS_MAIL_ORDER_SALES}</span>
</p>
<p class='txt1'>
<span>이 사이트의 콘텐츠에 대한 모든 권리와 의무는 저작권자 또는 제공처에 있으므로</span>
<span>이를 무단 사용 및 도용할 경우 저작권법 등에 따라 법적 책임을 질 수 있으며 </span>
<span>크리에이터의 저작물(콘텐츠)에 대한 문제 발생 시 마플샵은 이에 대한 책임을 지지 않습니다.</span>
</p>
`,
  },

  store_help: {
    customer_service: 'FAQ',
  },

  marppleshop_designers_status: {
    ON_REQUEST: '신청',
    ACCEPTED: '승인',
    REJECTED: '거절',
  },

  marppleshop_designers_stores_status: {
    ON_REQUEST: '협업 요청',
    ACCEPTED: '협업중',
    REJECTED: '협업 거절',
    DISMISSED: '협업 중지',
  },

  crew_mall: {
    mo_creator: '총 크리에이터<br><strong class="shop-status__bold">{{ s_count }}</strong>명',
    mo_product: '판매중인 상품<br><strong class="shop-status__bold">{{ p_count }}</strong>개',
    pc_title:
      '마플샵에서는 지금 <span>{{s_count}}</span>명의 크리에이터·아티스트·뮤지션들이 <span>{{p_count}}</span>개의 굿즈를 판매하고 있어요 !',
    mo_title: '마플샵에서는 지금 크리에이터·아티스트·뮤지션들이<br>다양한 상품을 만들어 판매하고 있어요 !',
    sub_title:
      '지금 {{s_count}}명의 크리에이터, 아티스트, 뮤지션들이<br>{{p_count}}개의 오피셜 굿즈를 판매하고 있어요!',
    title: '<p>세상 모든 크리에이터를 위한</p><p>굿즈 커머스 마플샵</p>',
    '제품 퀄리티': '제품 퀄리티',
    '굿즈 탐색': '굿즈 탐색',
    '셀러 신청하기': '마플샵 소개 &middot; 크리에이터 신청',
    'HOT 셀러': 'HOT 크리에이터',
    '마플샵 리뷰': '마플샵 리뷰',
    '실시간 상품': '실시간 상품',
    'HOT 셀러로 선정된 마플샵 크리에이터를 소개합니다!':
      'HOT 크리에이터로 선정된 마플샵 크리에이터를 소개합니다!',
    '지금 마플샵에서 판매 중인 상품들을 탐색해보세요!': '지금 마플샵에서 판매 중인 상품들을 탐색해보세요!',
    '고객 서비스에 대한 엄격한 기준, 최상의 상품과 최고의 커스텀 프린팅':
      '고객 서비스에 대한 엄격한 기준,&nbsp<span>최상의 상품과 최고의 커스텀 프린팅</span>',
    FAQ: 'FAQ',
    고객센터: '고객센터',
    'No Store': '스토어가 없습니다.',
    '마플샵의 소식을 전달합니다!': '마플샵의 소식을 전달합니다!',
    플러스: '플러스',
  },

  Shopify: {
    footer: {
      terms: '이용약관',
      privacy: '개인정보처리방침',
      저작권:
        '마플샵 콘텐츠의 저작권은 저작권자 또는 제공처에 있으며, 이를 무단 사용 및 도용하는 경우 저작권법 등에 따라 법적 책임을 질 수 있음을 알려 드립니다.',
    },
    product: {
      '다음에 하기': '다음에 하기',
      등록하기: '등록하기',
      게시하기: '게시하기',
    },
    dashboard: {
      '단 3분이면 가능 !{{- sp}}지금 바로 상품을 만들어보세요.':
        '단 3분이면 가능 !{{- sp}}지금 바로 상품을 만들어보세요.', // TODO: 영문 채우기
      '상품 주문 시 사용할 카드를{{- sp}}추가/변경할 수 있습니다.':
        '상품 주문 시 사용할 카드를{{- sp}}추가/변경할 수 있습니다.', // TODO: 영문 채우기
      '내 마플샵 주문 내역은{{- sp}}이곳에서 확인하세요.':
        '내 마플샵 주문 내역은{{- sp}}이곳에서 확인하세요.',
      '궁금하신 내용을{{- sp}} 고객센터를 통해 해결해 드립니다.':
        '궁금하신 내용을{{- sp}} 고객센터를 통해 해결해 드립니다.',
      '내가 만드는 굿즈샵{{- sp}}마플샵을 소개합니다.': '내가 만드는 굿즈샵{{- sp}}마플샵을 소개합니다.',
      '비밀번호와 연락처 등을{{- sp}}수정할 수 있습니다.':
        '비밀번호와 연락처 등을{{- sp}}수정할 수 있습니다.',
    },
    user: {
      '회원가입이 완료되었습니다.': '회원가입이 완료되었습니다.',
      '이메일 주소를 인증해 주세요.': '이메일 주소를 인증해 주세요.',
      '이메일이 변경되었습니다.': '이메일이 변경되었습니다.',
    },
    change_email: {},
    signup: {
      '약관 전체 동의': '약관 전체 동의',
      '[필수] 만 19세 이상입니다.': '[필수] 만 19세 이상입니다.',
      '[필수] 서비스 이용약관 동의': '[필수] 서비스 이용약관 동의',
      '[필수] 개인정보수집 이용 동의': '[필수] 개인정보수집 이용 동의',
    },
    my_info: {
      이름: '이름',
      변경: '변경',
      비밀번호: '비밀번호',
      '비밀번호 변경': '비밀번호 변경',
      '휴대폰 번호': '휴대폰 번호',
      '휴대폰 번호 변경': '휴대폰 번호 변경',
    },
  },

  mypage: {
    마이페이지: '마이페이지',
    '크리에이터를 위한 마플샵을 만나보세요.': '크리에이터를 위한{{- br}}마플샵을 만나보세요.',
    버전정보: '버전정보',
    현재버전: '현재버전',
    '신고 접수 서비스': '신고 접수 서비스',
    '이메일 상담': '이메일 상담',
    '사이트 이용 시 유의사항': '사이트 이용 시 유의사항',
    쇼핑정보: '쇼핑정보',
    '내 샵 가기': '내 샵 가기',
    '셀러스튜디오 가기': '마플샵 스튜디오 가기',
    '플러스 어드민 보기': '마플샵 어드민 보기',
    '셀러 신청하기': '크리에이터 신청하기',
    설정: '설정',
    '계정 설정': '계정 설정',
    '최근 본 셀러': '최근 본 크리에이터',
    좋아요: '좋아요',
    '관심있는 셀러는 좋아요 해주세요.': '관심있는 크리에이터는 좋아요 해주세요.',
    '더 많은 셀러 보러 가기': '더 많은 크리에이터 보러 가기',
    '관심있는 상품은 좋아요 해주세요.': '관심있는 상품은 좋아요 해주세요.',
    '더 많은 상품 보러 가기': '더 많은 상품 보러 가기',
    '최근 본 셀러가 없어요.': '최근 본 크리에이터가 없어요.',
  },

  format: {
    date: {
      ym_locale: 'yyyy년 M월',
      ym_digit: 'yyyy-MM',
      short: 'M월 d일',
    },
  },

  seller_type: {
    유튜버: '유튜버',
    틱톡커: '틱톡커',
    스트리머: '스트리머',
    크리에이터: '크리에이터',
    인플루언서: '인플루언서',
    일러스트레이터: '일러스트레이터',
    타투이스트: '타투이스트',
    포토그래퍼: '포토그래퍼',
    '캐릭터 디자이너': '캐릭터 디자이너',
    '웹툰/만화 작가': '웹툰/만화 작가',
    뮤지션: '뮤지션',
    아티스트: '아티스트',
    브랜드: '브랜드',
    '마플샵 플러스': '마플샵 플러스',
    가나다: '가나다',
  },

  mshop: {
    Shopping: '쇼핑',
    About: '어바웃',
    Review: '리뷰',
    Live: '실시간',
    Community: '리뷰',
    PhotoReview: '포토 리뷰',
    Search: '검색',
    Mypage: '마이페이지',
    bnb_coupon: '넥슨캐시 (3,000원권)',
    'welcome text': '{{- name}}님, 반가워요',
    Login: '로그인',
    Please: '{{- login}} 해주세요',
    Cart: '장바구니',
    Cart2: '장바구니 담기',
    '인기 셀러': '인기 크리에이터',
    '인기 상품': '인기 상품',
    'Go to Seller Studio': '마플샵 스튜디오 가기{{emoji}}',
    'Go to Apply Seller': '마플샵 크리에이터 신청하기',
    'Go to Seller Apply2': '크리에이터 신청하기',
    '댓글이 등록되었습니다.': '댓글이 등록되었습니다.',
    Close: '닫기',
    'Edit My Info': '내 정보 수정',
    'All Menu': '전체메뉴',
    신규: '신규',
    셀러: '크리에이터',
    스타일: '스타일',
    상품상세: '상품상세',
    '베스트 후기': '베스트 후기',
    Contents: '콘텐츠',
    'Content Detail': '콘텐츠 상세',
    'Related Contents': '관련 콘텐츠',
    '배경화면, 아이콘 등의 디지털 상품은 앱에서 결제할 수 없습니다. 장바구니에서 디지털 상품을 삭제한 후 결제를 다시 시도하시거나 PC에서 구매해 주세요.':
      '<h3>배경화면, 아이콘 등의 디지털 상품은 앱에서 결제할 수 없습니다.</h3><p>장바구니에서 디지털 상품을 삭제한 후 결제를 다시 시도하시거나 PC에서 구매해 주세요.</p>',
    '조건에 딱! 맞는 상품이 없어요.': '조건에 딱! 맞는 상품이 없어요.',
    '검색조건을 초기화하거나, 바꿔서 검색해 보세요.':
      '검색조건을 초기화 하거나,{{- br}}바꿔서 검색 해보세요.',
    '목록으로 돌아가기': '목록으로 돌아가기',
    '상품 준비 중입니다.': '상품 준비 중입니다.',
    '상품을 준비하는 동안 마플샵의 상품을 만나보세요!':
      '상품을 준비하는 동안{{- br}}마플샵의 상품을 만나보세요!',
    '마플샵 구경하기': '마플샵 구경하기',
    '주소가 잘못 입력되었거나, 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.':
      '주소가 잘못 입력되었거나, 변경 혹은 삭제되어<br>요청하신 페이지를 찾을 수 없습니다.',
    '페이지를 찾을 수 없습니다.': '페이지를 찾을 수 없습니다.',
    '잠시 후 다시 이용해 주세요. 서비스 이용에 불편을 드려 죄송합니다.':
      '잠시 후 다시 이용해 주세요.<br>서비스 이용에 불편을 드려 죄송합니다.',
    '서버 에러가 발생하였습니다.': '서버 에러가 발생하였습니다.',
    '이전 페이지': '이전 페이지',
    '메인 페이지': '메인 페이지',
    '#지금이_아니면_안돼_한정판': '#지금이_아니면_안돼_한정판',
    '#난리났네_난리났어_인기상품': '#난리났네_난리났어_인기상품',
    '#신상은_타이밍!': '#신상은_타이밍!',
    상품목록: '상품목록',
    '셀러명 혹은 상품명으로 검색해보세요.': '크리에이터명 혹은 상품명으로 검색해보세요.',
    '어떤 상품을 원하시나요?': '어떤 상품을 원하시나요?',
    '인기 검색어': '인기 검색어',
    '검색 결과가 없습니다.': '검색 결과가 없습니다.',
    '에 대한 검색 결과가 없습니다.': '<span class="keyword">{{q}}</span>에 대한 검색 결과가 없습니다.',
    '검색어의 철자나 띄어쓰기가 정확한지 확인해 주세요.':
      '검색어의 철자나 띄어쓰기가 정확한지{{- br}}확인해 주세요.',
    '최근 검색어': '최근 검색어',
    '최근 검색어 내역이 없습니다.': '최근 검색어 내역이 없습니다.',
    '등록된 상품이 없습니다.': '등록된 상품이 없습니다.',
    '카테고리 선택': '카테고리 선택',
    '스타일 필터': '스타일 필터',
    '게시물을 등록해 보세요!': '게시물을 등록해 보세요!',
    Apply: '확인',
    신규상품: '신규상품',
    '좋아요 목록에 추가되었습니다.': '좋아요 목록에 추가되었습니다.',
    '좋아요 목록에서 삭제되었습니다.': '좋아요 목록에서 삭제되었습니다.',
    인기순: '인기순',
    최신순: '최신순',
    '낮은 가격순': '낮은 가격순',
    '높은 가격순': '높은 가격순',
    '리뷰 많은 순': '리뷰 많은 순',
    '좋아요 순': '좋아요 순',
    '최근 팔린 순': '최근 팔린 순',
    '신규 등록 순': '신규 등록 순',
    '첫번째 글을 등록해 보세요!': '첫번째 글을 등록해 보세요!',
    '첫 번째 리뷰어가 될 기회': '첫 번째 리뷰어가 될 기회',
    '첫 번째 게시물이 될 기회': '첫 번째 게시물이 될 기회',
    '게시물을 첫번째로 작성하고 셀러의 댓글을 받아보세요!':
      '게시물을 첫번째로 작성하고 크리에이터의 댓글을 받아보세요!',
    더보기: '더보기',
    '마플샵 셀러 신청': '마플샵 크리에이터 신청',
    '상품을 구매하고 리뷰를 등록하시면 포인트를 드려요.':
      '상품을 구매하고 리뷰를 등록하시면 포인트를 드려요.',
    채널ON: '채널ON',
    '마플샵 바로가기': '{{name}} 마플샵',
    '관심있는 셀러는 좋아요 해주세요.': '관심있는 크리에이터는 좋아요 해주세요.',
    '더 많은 셀러 보러 가기': '더 많은 크리에이터 보러 가기',
    '관심있는 상품은 좋아요 해주세요.': '관심있는 상품은 좋아요 해주세요.',
    '더 많은 상품 보러 가기': '더 많은 상품 보러 가기',
    '세상 모든 크리에이터가 여기에!': '세상 모든 크리에이터가 여기에!',
    '이벤트 응모가 완료되었습니다!': '이벤트 응모가 완료되었습니다!',
    '팬심으로 보고, 사심으로 사는 공식굿즈': '팬심으로 보고, 사심으로 사는{{- br}}공식굿즈',
    '마플샵 팝업스토어': '마플샵 팝업스토어',
    '최애 아티스트와 브랜드의 공식굿즈까지!': '최애 아티스트와 브랜드의{{- br}}공식굿즈까지!',
    '지금 주목해야 할 크리에이터': '지금 주목해야 할 크리에이터',
    '성인 인증이 필요합니다.인증을 진행하시겠습니까?': '성인 인증이 필요합니다.<br>인증을 진행하시겠습니까?',
    'AGE VERIFICATION': 'AGE VERIFICATION',
    'You must be at least 18 years old to enter this shop!':
      'You must be at least 18 years old to enter this shop!',
    Age: 'Age',
    'I agree to the Marrple':
      'I agree to the Marpple&nbsp;<a href="/@/terms" class="go_terms" target="_blank">Terms &amp; Conditions.</a>',
    "I'll be held responsible for the information I provided.":
      "I'll be held responsible for the information I provided.",
    'I am 18+': 'I am 18+',
    'I am under 18': 'I am under 18',
    'Access Denied': '성인 인증 실패',
    'Users must be at least 18 to access age-restricted contents.':
      '성인 이상만 컨텐츠를 확인할 수 있습니다.',
    Return: '돌아가기',
    '본인 인증 되었습니다.': '본인 인증 되었습니다.',
    HomeTitle: {
      '지금 이 순간에도 새로운 상품이 업데이트 중': '지금 이 순간에도{{- br}}새로운 상품이 업데이트 중',
      '미리 미리 알아둬요, 언제 유명해질지 모르니까.': '미리 미리 알아둬요,{{- br}}언제 유명해질지 모르니까.',
      '당신의 마음을 훔치러 온 뉴페이스 👋': '당신의 마음을 훔치러 온 뉴페이스 👋',
      '읽다 보니 재밌다. 자세히 보니 후기다.': '읽다 보니 재밌다.{{- br}}자세히 보니 후기다.',
      '관심 있을 만한 걸 가져왔어요 💘': '관심 있을 만한 걸 가져왔어요 💘',
      '마음으로 낳아 지갑으로 길렀다.': '마음으로 낳아 지갑으로 길렀다.',
      '요즘은 이런 굿즈가 잘 나가요 😎': '요즘은 이런 굿즈가 잘 나가요 😎',
      '어차피 할 덕질, 행복하게 덕질하자!': '어차피 할 덕질, 행복하게 덕질하자!',
      '직접 구매한 사람들의 후기 ✏️': '직접 구매한 사람들의 후기 ✏️',
      '추천은 내가 할게, 사는건 누가 할래?': '추천은 내가 할게, 사는 건 누가 할래?',
      '늦기 전에 데려가세요! 🏃‍♂️': '늦기 전에 데려가세요! 🏃‍♂️',
      '크리에이터를 위한 마플샵을 만나봐': '크리에이터를 위한{{- br}}마플샵을 만나봐',
      '마플샵 셀러 신청하기': '마플샵 크리에이터 신청하기',
      '한정판 & 마감임박 상품': '한정판 & 마감임박 상품',
      '이런 신상 처음이야!': '이런 신상 처음이야!',
      '최애는 지금 영업 중 🕺': '최애는 지금 영업 중 🕺',
    },
    plusTitle: {
      '가장 많이 찾는 샵': '가장 많이 찾는 샵',
      '오늘의 브랜드 스토리': '오늘의 브랜드 스토리',
      '가장 사랑 받는 굿즈': '가장 사랑 받는 굿즈',
      '지금은 방송중': '지금은 방송중',
      '고객님의 소중한 리뷰': '고객님의 소중한 리뷰',
      '내일이면 못 살 굿즈': '내일이면 못 살 굿즈',
      '마음을 사로잡을 신상 굿즈': '마음을 사로잡을 신상 굿즈',
      '가장 많이 공감 받은 리뷰': '가장 많이 공감 받은 리뷰',
      '주목할 만한 새로운 샵': '주목할 만한 새로운 샵',
    },
    ProductBadge: {
      매진: '매진',
      한정: '한정',
      '??개남음': '{{c}}개 남음',
      '??시간전': '{{hour}}시간전',
      '??분전': '{{minute}}분전',
      출시예정: '출시예정',
      '출시??': '출시 {{d}}',
      마감임박: '마감임박',
      '마감??': '마감 {{d}}',
    },
    tag: {
      marpple_product: '마플샵 상품',
      seller_product: '크리에이터 상품',
      is_free_shipping_product: '무료배송',
      custom_description: '원하는 대로 커스터마이징하여 주문할 수 있는 상품',
      hand_made_description: '크리에이터가 직접 손으로 만드는 상품',
      print_on_demand_description: '주문 후 제작을 시작하는 상품',
      marpple_product_description: '마플샵에서 제작하여 배송되는 상품',
      seller_product_description: '크리에이터가 제작하거나 보유한 상품을 직접 발송하는 상품',
    },
    ProductStatus: {
      pb_me: '마플샵 공개',
      prohibited: '판매 보류',
      checking: '심사 중',
      public: '공개',
      private: '비공개',
      not_confirmed: '검수 요청',
      ready_confirm: '검수 미요청',
      not_use_product: '판매 중지 상품',
      // end_of_sale: '판매 기간 종료',
      end_of_sale: '{{start_at}} ~ {{end_at}}',
      until_close:
        '마감까지&nbsp;<span class="remain_time" data-start_at="{{start_at}}" data-end_at="{{end_at}}">{{remain_time}}</span>',
      until_open:
        '출시까지&nbsp;<span class="remain_time" data-start_at="{{start_at}}" data-end_at="{{end_at}}">{{remain_time}}</span>',
    },
    ProductStatusTooltip: {
      pb_me: '크리에이터샵과 마플샵에 공개되는 상품입니다.',
      prohibited: '저작권 등의 문제로 판매보류된 상품입니다.',
      checking: '디지털 상품은 심사 후 판매할 수 있습니다.',
      public: '크리에이터샵에서만 공개되는 상품입니다.',
      private: '크리에이터만 볼 수 있는 비공개 상품입니다.',
      not_confirmed: '판매 담당자에게 검수 요청한 상품입니다.',
      ready_confirm: '판매 담당자에게 검수를 요청해보세요.',
      not_use_product: '{{bp_name}} 제품이 단종되었습니다.',
      // end_of_sale: '판매 기간 종료',
    },
    ProductDetail: {
      tabs: {
        story: '스토리',
        review: '리뷰',
        bp: '상품 정보',
        purchaseInfo: '구매 안내',
        faq: 'FAQ',
      },
      purchaseInfo: {
        title: '배송 안내',
        desc: '모든 상품은 고객님의 주문에 맞춰 새 상품으로 제작하여 배송됩니다.',
        expect:
          '지금 주문하시면&nbsp;<span>{{start}}</span>부터 제작 프로세스가 시작되어&nbsp;<span>{{end}}</span> 이전에 출고될 예정입니다.',
        tpl_expect:
          '지금 주문하시면&nbsp;<span>{{start}}</span>부터&nbsp;<span>{{end}}</span>&nbsp;사이에 출고 예정입니다.<br />마플샵의 주문 제작 상품과 함께 주문하실 경우, 주문 제작 상품의 출고일에 맞춰 함께 배송됩니다.',
        producing_period: `평균&nbsp;<span>{{avg_days}}일</span>&nbsp;/&nbsp;최대 <span>{{max_days}}일</span>`,

        refund_title: '교환/환불 안내',
        refund_desc1:
          '모든 마플샵 배송 상품은 <span>주문 제작 방식</span>으로 제작되어 단순변심으로 인한 교환/환불이 불가합니다.',
        refund_desc2:
          '단, 수령하신 상품이 불량이거나 오배송된 경우에는 7일 이내 고객센터 또는 이메일, 일대일 문의하기로 연락주시면 교환 및 반품 환불이 가능합니다.',
        take_back_title: '반송지 정보',
        take_back_price: '반품 배송비',
        take_back_address: '보내실 곳',
        take_back_mobile: '문의 연락처',
        seller_info_title: '크리에이터 정보 안내',
        seller_name: '크리에이터명',
        seller_email: '이메일',
        seller_company_name: '상호명',
        seller_business_license_number: '사업자 번호',
        seller_company_tel: '연락처',
        cs_title: '고객센터',
        cs_num: '1566-5496',
        email_title: '이메일',
        email: 'cs@marppleshop.com',
        btn_guide1: '청약 철회 제한 요건 안내',
        btn_guide2: '품질 보증 기준 안내',
      },
      faq: {
        group1: '주문/배송',
        group2: '교환/환불',
        group3: '디지털 굿즈 구매 가이드',
        q1: '주문 후 상품 수령까지 얼마나 걸리나요?',
        a1: '마플샵 배송 상품은 주문 제작 방식으로 제작되어 주문 후 상품 수령까지 일정 기간이 소요됩니다.<br/>배송 예정일은 상품의 상세 페이지와 주문내역에서 확인하실 수 있습니다.',
        q2: '주문 상태는 어떻게 알 수 있나요?',
        a2: '로그인>마이페이지>구매내역에서 주문 상품의 제작 진행 상태 및 배송 일정을 확인하실 수 있습니다.<br/>상품을 주문하시면 아래 5단계를 거쳐 제작과 배송이 이뤄지며 제작준비중 상태에서만 주문 변경 및 취소가 가능합니다.<br/><br/>제작준비중 : 결제가 완료되어 상품 제작 전 준비단계입니다.<br/>제작중 : 주문하신 상품이 제작되고 있는 단계이며 이 단계부터는 주문 취소/변경/환불 불가합니다.<br/>배송준비중 : 제작이 완료되어 상품의 검수 및 포장을 진행합니다.<br/>배송중 : 주문하신 상품이 출고되어 택배사로 전달된 상태이며 운송장 번호는 문자로 발송됩니다. 출고된 다음날부터 송장번호 조회를 통한 배송 현황 확인이 가능합니다.<br/>배송완료 : 배송이 완료된 상태입니다.',
        q3: '비회원도 주문할 수 있나요?',
        a3: '비회원도 주문 가능하며 주문 완료 시 입력하신 이메일로 주문내역을 보내드립니다.<br/>이메일 주문내역을 통해 상세 주문 정보와 배송 정보를 확인하실 수 있습니다.<br/>기존 마플 회원은 동일한 아이디로 마플샵을 이용하실 수 있으며 마플샵 회원에게는 포인트 적립, 이벤트 등 다양한 혜택이 제공되오니 많은 이용 부탁드립니다.',
        q4: '주문 취소가 가능한가요?',
        a4: '주문 제작 오더메이드 상품 특성상 상품의 제작이 시작되면 주문 취소는 불가합니다.<br/>주문하신 후 <b>제작준비중</b> 상태에서만 주문 변경 및 취소가 가능하며 <b>제작중</b> 상태 이후부터는 취소가 불가하오니 제작이 시작되기 전에 취소해 주세요.<br/>주문 취소는 로그인>마이페이지>구매내역>상세보기에서 “주문취소" 버튼을 통해 가능합니다.<br/>마플샵은 주문된 상품만 생산을 진행하여 자원의 낭비를 막고 환경을 생각합니다. 신중한 구매 결정 부탁드립니다.',
        q5: '상품을 받았는데 교환이나 환불이 가능한가요?',
        a5: '주문 제작 오더메이드 상품 특성상 단순변심으로 인한 교환/환불 및 반품이 불가합니다.<br/>단, 수령하신 상품이 불량인 경우 “전자상거래등에서의 소비자 보호에 관한 법률"에 따라 교환/환불 및 반품이 가능하오니 마플샵 고객센터(1566-5496) 또는 1:1 문의하기를 통해 문의 부탁드립니다.<br/>수령하신 상품의 불량 부분이 보이는 사진 2장 이상을 첨부하여 문의해 주시면 빠르게 확인 도와드리겠습니다.<br/>주문하셨던 상품이 아닌 다른 상품으로의 교환은 불가하며 세탁 부주의로 인한 상품 변형 및 인쇄 손상에 따른 교환/환불은 불가하므로 상품 상세 페이지의 세탁 방법을 꼭 확인해 주세요.',
        q6: '디지털굿즈 구매 전, 꼭 확인해 주세요!',
        a6: '<img src="https://s3.marpple.co/files/u_1184090/2021/4/original/40992d192beed7c089fdaaa40136b2b78b1400021.jpg" alt="">',
        q7: '디지털굿즈 콘텐츠의 라이선스는 크리에이터에게 있습니다.',
        a7: '콘텐츠의 저작권을 포함한 권리는 모두 해당 ‘마플샵 크리에이터’에게 있습니다.<br/>회사는 콘텐츠 저작권 및 라이선스에 관한 어떠한 보증도 하지 않습니다.<br/>콘텐츠 구매만으로 구매자에게 저작권 및 소유권 권리가 이전되지 않습니다.<br/>영리 목적의 직/간접적인 콘텐츠 사용은 불가하며 광고 및 마케팅, 프로모션 콘텐츠 등 상업적 목적으로 사용이 불가합니다.<br/>위 모든 사항 위반 시 재산권 및 초상권의 문제가 발생할 수 있으며, 그로 인한 모든 책임은 구매자에게 있습니다.<br/>',
        q8: '구매한 콘텐츠는 개인 소장용으로만 사용 가능합니다.',
        a8: '한번 구매한 이미지는 기간 제한 없이 사용할 수 있습니다.<br/>개인 소장 및 1인(개인) 사용만을 원칙으로 하며, 비상업적 용도의 사용만 가능합니다.<br/>상업적인 용도로 무단 사용하실 경우 저작권법 등에 따라 법적 책임을 질 수 있습니다.<br/>확장 라이선스 구매를 원하는 경우 해당 크리에이터에게 문의하시기 바랍니다.',
        q9: '아이디 공유 금지 안내',
        a9: '구매한 콘텐츠는 하나의 계정당 1인(개인) 사용을 원칙으로 합니다.<br/>마플샵에서는 고객 분들의 개인정보 보호 및 무단 이미지 공유 이슈를 방지하기 위해 아이디 공유를 금지하고 있습니다.<br/>아이디 공유 이력이 확인될 경우, 마플샵 이용약관에 따라 서비스 이용이 제한될 수 있으며,<br/>저작권법 및 관련 법령에 따라 처벌될 수 있습니다.',
        q10: '구매한 디지털굿즈의 수정/편집/공유 및 재판매를 금합니다.',
        a10: '디지털굿즈를 구매한다는 것은 그 이미지의 저작권까지 구매하는 것이 아닌 이미지에 대한 이용권만을 구매하는 것이기 때문에 재배포 / 재분배 / 재판매를 할 수 없습니다.<br/>구매한 콘텐츠로 제품 패키지, 엽서, 액자 등과 같은 2차(인쇄) 저작물을 만들어 판매할 수 없습니다.<br/>구매한 콘텐츠를 원본 형태 그대로 SNS, 블로그, 카페, 웹하드 등에 재배포, 재분배 목적으로 업로드하거나 스크랩하는 것을 금합니다.<br/>이미지 사용 제한에 위반되는 사용이 발견되었을 시, 위반되는 사용으로 인해 회사 및 이미지의 저작권자에게 손해 및 피해가 발생한 경우,<br/>그로 인한 모든 책임은 구매자에게 있으며 그에 따른 보상을 해야 합니다.',
        q11: '디지털굿즈 환불은 불가합니다.',
        a11: '디지털 콘텐츠의 특성상, 구매 완료된 이미지에 대해서는 환불이 불가능합니다.<br/>디바이스별 이미지의 비율 또는 해상도의 차이에 따른 환불은 불가하기 때문에 정확한 확인 후 구매하실 것을 권장드립니다.<br/>콘텐츠의 저작권 및 초상권, 재산권, 사용범위 및 이미지 내 사용된 문구 등의 모든 정보는 해당 크리에이터가 제공하는 것이며, 당사가 그 정확성을 100% 보증할 수 없습니다.<br/>예외적으로 환불 발생 시에는 결제할 때 발생했던 수수료를 제외한 금액이 환불됩니다.',
      },
      tpl_faq: {
        a1: '본 상품은 주문 후 2주 이내에 출고됩니다. 배송 시작 시 별도의 문자 메시지로 안내드릴 예정이며,<br />예상치 못한 물류량 증가나 물류센터의 사정으로 배송이 지연될 수 있음을 양해해 주시기 바랍니다.',
        a2: '로그인 > 마이페이지 > 구매내역에서 상품의 주문 상태 및 배송 일정을 확인하실 수 있습니다.',
        a4: '주문접수 단계까지는 시스템 상에서 주문 취소 가능합니다.<br />주문 취소는 로그인 > 마이페이지 > 구매내역 > 상세보기에서 “주문취소" 버튼을 통해 가능합니다.',
        a5: '일반적인 상시 판매 상품은 구매 확정 이전에 교환 환불이 가능합니다. 교환과 환불에 따른 배송비는 마플샵 고객센터(1566-5496)에게 문의해주시면 감사하겠습니다.',
      },
      preview_by_device: '내 스마트폰에 적용하기',
      color: '색상',
      quantity: '수량',
      size: '사이즈',
      sold_out: '품절',
      sold_out_products: '상품이 매진되었어요 😢',
      sold_out_size: '사이즈가 일시 품절되었습니다.',
      device: '기종',
      check_size_guide: '가이드를 확인하세요!',
      max_purchase_per_user: '1인당 최대 {{max_purchase_per_user}}개 구매 가능한 상품이에요!',
      possible_quantity: '총 {{possible_quantity}}개 까지 담을 수 있어요!',
      already_possible_quantity_title: '1인당 구매 가능 수량 초과',
      already_possible_quantity: '이미 구매한 상품이거나 장바구니에 담겨있어요.',
      expect: `
    <span class="pd-guide__text" style="display: flex;">
        <strong style="color:#000; margin-right:8px;">배송 기간</strong>
        <div style="flex:1;">
          <span style="color:#828282;">지금 주문하면</span>&nbsp;<strong style="color:#002aff">{{start}} ~ {{end}}</strong>&nbsp;<span style="color:#828282;">사이에 출발해요!</span>
        </div>
      </span>
    `,
      producing_period:
        '<strong style="color:#000; margin-right:8px;">제작소요기간</strong><div style="color:#828282; flex:1;">평균&nbsp;{{avg_days}}일&nbsp;/&nbsp;최대{{max_days}}일</div>',
      shipping_period: `
      <span class="pd-guide__text" style="display: flex;">
        <strong style="color:#000; margin-right:8px;">배송정보</strong>
        <div style="flex:1;">
          <span style="color:#828282;"
            ><span>{{shipping_price}}</span>
            <span style="color:#3c89f9;" data-has_not_free_shipping_condition="{{has_not_free_shipping_condition}}">({{free_shipping_min_price}}원이상 무료 배송)</span></span
          ><br />
          <span style="color:#828282;"
            >제주/도서 지역 추가 {{additional_shipping_price}}원</span
          >
        </div>
      </span>`,
      total_count: '<span class="dynamic-value">{{count}}</span>개 상품 금액',
      left_count: '{{total}}개 중 {{left}}개 남았어요! 🤭',

      not_approve: '판매 승인 후 구매 가능합니다.',
      coming_soon: '두둥! 곧 구매 가능 🤩',
      end_sale: '판매가 종료되었어요 😢',
      only_pc: '디지털 굿즈는 PC에서만 구매가능 😉',
      purchased_dtg: '이미 구매한 상품이에요! 🛍',

      size_guide: '사이즈 가이드',
      product_base_info: '상품 기본 정보',
      check_cart_in: '장바구니에 담으시겠습니까?',
      opt_prod: '상품 옵션',
      preparing: '준비중입니다.',
      quality_modal: {
        title: '',
        p1: '본 상품은 철저한 품질관리와 공정관리를 거쳐 생산되었으며 외관, 규격, 물성검사에서 합격한 상품입니다.',
        p2: '본 상품의 수명을 연장하기 위하여 상품에 부착된 취급 주의사항과 세탁 방법을 필히 확인해 주시기 바랍니다.',
        p3: '본 상품의 품질에 이상이 있을 경우 고객센터로 연락해 주시기 바랍니다.',
        p4: '교환 또는 반품을 원하실 경우 수령일로부터 7일 이내에 접수하시기 바랍니다.',
      },
      retraction_modal: {
        p1: '착용 흔적, 세탁 흔적, 냄새, 오염, 임의 수선 흔적 등<br />상품이 훼손되었거나 불량상품 임에도 착용 또는 사용한 경우,<br />교환/반품이 불가합니다.',
        p2: '청약철회 제한 요건<br />(제17조 제2항 제6호 및 동법 시행령 제21조)',
        li1: '상세 사이즈는 측정 방법과 위치에 따라 1~3cm 오차가 발생할 수 있습니다. 기재된 상세 사이즈와 실측 오차(1~3cm)에 대한 불량처리는 어려우며 교환 및 환불 사유가 될 수 없습니다.',
        li2: '마플샵에서 판매되는 상품은 각 브랜드, 도매처에서 각각의 샘플링을 거쳐 공급되는 제품이므로, 특정 브랜드 또는 가지고 계신 상품과 상세 사이즈가 다를 수 있습니다. 실측구매 시 상세 사이즈를 반드시 확인 부탁드립니다.',
        li3: '사이즈 측정은 상품을 바닥에 펼친 후 끝 선과 끝 선까지 측정해야 하며, 신축성 있는 소재일 경우, 잡아당기지 않고 바닥에 펼쳐진 상태 그대로 측정해야 합니다. 오차범위 이외 잘못된 측정으로 인한 반품 및 교환은 어려운 점 양해 부탁드립니다.',
      },
    },
    Menu: {
      Home: '홈',
      Contents: '콘텐츠',
      Product: '상품',
      Creator: '크리에이터',
      작품: '작품',
      Official: '공식굿즈',
      All: '전체',
      가나다: '가나다 순',
      '마플샵 플러스': '마플샵 플러스',
      'Customer Center': '고객센터',
      'Order Status': '구매내역',
      Community: '리뷰',
    },
    Seller: {
      셀러: '크리에이터',
      기타: '기타',
      명: '{{- count}}명',
      건: '{{- count}}건',
    },
    Footer: {
      CompanyName: '(주)마플코퍼레이션',
      CEO: '대표',
      CeoName: '박혜윤',
      CustomerCenter: '고객센터',
      CustomerCenterNumber: '1566-5496',
      CustomerCenterInfo: '(평일 10:00 ~ 18:00)',
      CompanyRegistrationInfo: '사업자등록번호',
      CompanyRegistrationNumber: '105-88-13322',
      Address: '주소',
      AddressInfo: CreatorAddressConstantS.MPS_ADDRESS,
      Email: '이메일',
      EmailInfo: 'cs@marppleshop.com',
      ReportCenter: '신고센터',
      ApplySeller: '크리에이터 신청',
      TermsAndConditions: '이용약관',
      PrivacyPolicy: '개인정보처리방침',
      Instagram: '인스타그램',
      Youtube: '유튜브',
      Twitter: '트위터',
      Blog: '블로그',
      MarppleCorp: '마플코퍼레이션',
    },
    Hello: {
      '안녕, 마플샵!': '안녕, 마플샵',
      '3분 샵만들기': '3분 샵만들기',
      '아이디어가 있나요?': '아이디어가 있나요?',
      '마플샵이 다 해요!': '마플샵이 다 해요!',
      '당신은 디자인만!': '당신은 디자인만!',
    },
    live: {
      '지금 총알 쏘러 갑니다.': '지금 총알 쏘러 갑니다.',
      '라이브 방송중': '라이브 방송중',
      '제일 인기있는 셀러': '제일 인기있는 크리에이터',
      '제일 인기있는 셀러를 보여줘.': '제일 인기있는 크리에이터를 보여줘.',
      '지금 막! 팔렸어요.': '지금 막! 팔렸어요.',
      '제일 많이 팔린 상품을 보여줘.': '제일 많이 팔린 상품을 보여줘.',
      '월간 랭킹': '월간 랭킹',
      '내가 이 구역 리뷰왕!': '내가 이 구역 리뷰왕!',
      '50위까지 펼쳐보기': '50위까지 펼쳐보기',
      '30개까지 보기': '30개까지 보기',
    },
    '몇월 몇째주': '{{month}}월 {{weeks}}',
    '언제 기준': '<strong>{{date}}</strong> 기준',
    week1: '첫째주',
    week2: '둘째주',
    week3: '셋째주',
    week4: '넷째주',
    week5: '다섯째주',
    week6: '여섯째주',
    '1:1 채팅 문의는 PC 혹은 모바일 웹사이트에서 이용가능합니다. 주소창에 marpple.shop 을 입력해 주세요.':
      '1:1 채팅 문의는 PC 혹은{{- br}}모바일 웹사이트에서 이용가능합니다.{{- br}}주소창에 marpple.shop 을 입력해주세요.',
    report: {
      title: '신고하기',
      complete: '신고가 접수되었습니다.',
      limit: '100자까지 작성 가능합니다.',
      placeholder: '신고 접수 사유를 작성해주세요.',
      noticeTitle: '신고 전 확인해 주세요.',
      noticeDescription1:
        '해당 게시물에 단체 혹은 개인이 저작권 침해, 위조상품 판매, 마플샵 내부 규정 위반 등으로 권리 침해를 받으신 경우에는 PC 버전의 마플샵 > 신고센터에서 접수를 해주시기 바랍니다.',
      noticeDescription2: '본 신청하기는 신고를 목적으로 운영하며 별도의 답변은 제공되지 않습니다.',
      confirmMessage: '이 댓글을 신고 하시겠습니까?',
      reportItem1: '주제에 맞지 않아요.',
      reportItem2: '정보가 부정확해요.',
      reportItem3: '지나치게 광고를 해요.',
      reportItem4: '욕설이나 비방이 심해요.',
      reportItem5: '폭력 또는 혐오스러운 컨텐츠에요',
      reportItem6: '외설적인 내용이 포함돼있어요.',
      reportItem7: '이 사용자의 게시물을 보고 싶지 않아요.',
      reportItem8: '(선택하시면 사용자의 게시물 및 댓글이 노출되지 않습니다.)',
    },
    QuickMenu: {
      t1: '마플샵 크리에이터 신청',
      t2: '인기 크리에이터',
      t2_mo: '인기\n크리에이터',
      t3: '리얼 후기',
      t4: '실시간 랭킹',
      t5: '아트샵',
      t6: '공식 굿즈',
      t7: '옴뉴움 NFT',
      t8: '기업크리에이터 신청',
      t9: '크리에이터 신청하기',
      t10: '크리에이터스 클럽',
      t11: '유튜브 쇼핑',

      t12: '크리에이터\n신청',
      t13: '팝업스토어',
      t14: '오프라인 팝업 신청',
      t15: '마플샵 플러스 신청',
      t16: '특별한 고객 경험을 위한 오프라인 팝업스토어, 마플샵',
    },
    Styles: {
      t1: '스타일',
      t2: '굿즈 하나로 이런 스타일까지 ✨',
      t3: '내가 직접 굿즈 만들어서 판매해볼까?',
      t4: '지금 신청하면 5,000포인트 지급!',
    },
    meta_title: {
      mshop_home: '상상을 현실로, MAKE IT REAL | 마플샵',
      mshop_creator: '유튜버, 틱톡커, 스트리머 등 세상 모든 크리에이터 굿즈 | 마플샵',
      mshop_contents: '굿즈 제작부터 판매까지, 크리에이터를 위한 굿즈 트렌드 | 마플샵',
      mshop_products: '세상 모든 크리에이터 굿즈 판매 전문 굿즈샵 | 마플샵',
      review: '마플샵 리뷰',
      creator: '마플샵 크리에이터',
      contents: '마플샵 콘텐츠',
      products: '마플샵 상품',
      live: '마플샵 실시간 랭킹',
      search: '마플샵 검색',
      registration: '마플샵 크리에이터 신청',
    },
    auth: {
      signup: '회원가입',
      signup_email: '이메일',
      signup_id: '아이디',
      signup_nickname: '닉네임',
      signup_station: '방송국 이름',
    },
    signup: {
      signup_use_term: '이용약관 동의',
      signup_age_term: '만 14세 이상입니다.',
      agree_privacy: '개인정보수집 및 이용약관 동의',
      show_more: '보기',
      login: '로그인',
      signup_error: '오류가 발생했습니다.',
      agree_privacy_error: '개인정보수집 및 이용 약관에 동의해 주세요.',
      signup_use_term_error: '이용약관에 동의해 주세요.',
      signup_age_term_error: '만 14세 이상 가입이 가능합니다.',
      signup_use_extra_term: 'SOOP x (주)마플코퍼레이션 이용약관 동의',
      signup_use_extra_term_title: 'SOOP x (주)마플코퍼레이션 이용약관',
      signup_use_extra_term_error: 'SOOP x (주)마플코퍼레이션 이용약관에 동의해 주세요.',
      signup_agree_ad: '(선택) 이메일 마케팅 수신동의',
    },
  },

  report: {
    '신고 접수 서비스 안내': '신고 접수 서비스 안내',
    '서비스 안내': '서비스 안내',
    '신고 접수하기': '신고 접수하기',
    조회하기: '조회하기',
    '마플샵에서 판매 중인 상품이 제3자 저작물의':
      '마플샵에서 판매 중인 상품이 제3자 저작물의 무단 도용 또는 초상권을 침해했다고 판단되는 경우와 크리에이터가 마플샵 내부 규정 위반을 목격한 경우에 신고를 접수받는 서비스입니다.',
    '해당 상품이 실제로 제3자의 권리를 침해하였는지 여부는': `
해당 상품이 실제로 제3자의 권리를 침해하였는지 여부는
<strong>마플샵에서 임의로 판단할 수 없으며 적법한 자격을 갖춘 관련 기관을 통해 권리 침해 여부에 대한 판단 및 사후 조치에 대한 결정</strong>을 구해야 합니다.
마플샵은 관련 기관의 최종 판단 전까지 해당 상품을 임시 판매 중지함으로써 추가적인 권리 침해를 방지하기 위해 신고접수 서비스를 마련 해놓고 있습니다.`,
    '신고 접수 서비스는 고객님을 보호하기 위한 임시적인 방법': `
<strong>신고 접수 서비스는 고객님을 보호하기 위한 임시적인 방법</strong>일 뿐 궁극적인 해결은
<strong>관련 기관</strong>을 통한 법률 상담 및 구제 절차를 통해 진행하실 수 있습니다.`,
    '신속하고 정확한 신고접수 서비스 운영을 통해': `신속하고 정확한 신고접수 서비스 운영을 통해 마플샵은 커머스 플랫폼 서비스 제공자로서의 책임을 다하고자 노력하고 있으며, 당사자 간의 분쟁이 적법한 절차를 통해 합리적으로 해결될 때까지 관련 당사자 모두를 보호하고자 합니다.`,
    '관련기관 홈페이지': '관련기관 홈페이지',
    '이미지를 클릭 하면, 해당 홈페이지로 이동합니다.': '이미지를 클릭 하면, 해당 홈페이지로 이동합니다.',
    '신고 접수 서비스를 접수할 수 있는 대상': '신고 접수 서비스를 접수할 수 있는 대상',
    '① 저작권침해': `<strong>① 저작권침해</strong> 저작물에 대한 정당한 권리가 있는 당사자(개인 및 단체) 및 무단 도용된 상품에 대한 원본 제작 당사자`,
    '② 위조상품 판매': `<strong>② 위조상품 판매</strong> 판매 중인 상품이 상표권 또는 디자인권을 위반한 경우 해당 권리자 또는 권리자로부터 위임 받은 대리인`,
    '③ 마플샵 내부 규정 위반': `<strong>③ 마플샵 내부 규정 위반</strong> 만 14세 미만 미성년자 크리에이터, 저작권자가 아닌 타인의 명의로 운영 중인 크리에이터의 위반 사실을 입증할 수 있는 자`,
    '신고 접수 시 처리 과정': '신고 접수 시 처리 과정',
    '저작권 침해 / 위조 상품 판매 (상표권·디자인권 침해)':
      '저작권 침해 / 위조 상품 판매 (상표권·디자인권 침해)',
    '저작권 침해 신고 접수 시 유의사항': '저작권 침해 신고 접수 시 유의사항',
    '저작권침해 신고 접수 시 해당 상품을 판매 중인': `저작권침해 신고 접수 시 해당 상품을 판매 중인 크리에이터에게는 요청 사유(단체인 경우 ‘권리을 보유한 법인/단체명’으로, 개인인 경우 ‘해당 권리권자’로 통지됨)가 통지되며 <strong>신고 접수된 상품은 비공개 처리</strong> 됩니다.`,
    '셀러가 저작권자이거나 정당한 권리자임을 증빙': `크리에이터가 저작권자이거나 정당한 권리자임을 증빙할 수 있는 경우에만 재판매 요청이 가능합니다. (14일 이내 재판매 요청이 필요하며 미접수 시 판매가 중지됩니다.)`,
    '신고 접수 스레드는 요청자-셀러 간의 합의를 위한 공간': `<strong>신고 접수 스레드는 요청자-크리에이터 간의 합의를 위한 공간</strong>이며 마플샵은 저작권을 보유하고 있지 않기에 스레드 생성 외에 어떤 관여도 하지 않습니다.`,
    '저작권법 제 103조 제 3항 및 동법 시행령': `저작권법 제 103조 제 3항 및 동법 시행령 제 43조 2항의 규정에 따라 마플샵이 예정일 (재판매 요청 접수 후 7일)을 신고 접수자에게 통보한 후 재판매 예정일에 상품이 복원됩니다.`,
    '재판매된 상품에 대해서는 반복하여 신고 접수를 할 수 없으며': `재판매된 상품에 대해서는 반복하여 신고 접수를 할 수 없으며, 이후의 절차는 당사자 간 합의 또는 관계 행정 기관 심의판단이나 법원 판결을 통해 해결하실 수 있습니다.`,
    '위조상품판매 신고 접수 시 유의사항': '위조상품판매 신고 접수 시 유의사항',
    '위조상품에 대한 조치 요청은 상표법 제107조':
      '위조상품에 대한 조치 요청은 상표법 제107조 (권리침해에 대한 금지청구권 등) 및 디자인보호법 제113조(권리침해에 대한 금지청구권 등) 규정에 따라 권리자만이 가능합니다.',
    '상표법 제108조':
      '<strong>상표법 제108조</strong>(침해로 보는 행위) 및 <strong>디자인보호법 제114조</strong>(침해로 보는 행위) 에 해당하는 경우 <strong>즉시 해당 상품 판매 중지 조치</strong>됩니다.',
    '위조상품 판매로 인한 조치 시 해당 상품을':
      '위조상품 판매로 인한 조치 시 해당 상품을 판매 중인 크리에이터에게 권리권자(단체인 경우 ‘권리를 보유한 회사명’으로, 개인인 경우 ‘해당 권리권자’로 통지됨)와 침해 권리가 무엇인지 통지됩니다.',
    '셀러는 위조 상품이 아니라 정당한 상표 등의':
      '크리에이터는 위조 상품이 아니라 정당한 상표 등의 사용임을 증빙할 수 있는 경우에만 스레드에 답변 등록이 가능합니다.',
    '마플샵 내부 규정 위반': '마플샵 내부 규정 위반',
    '마플샵 내부 규정 위반 신고 접수 시 유의사항': '마플샵 내부 규정 위반 신고 접수 시 유의사항',
    '마플샵 내부 규정 위반 신고 접수 시 해당 상품을 판매 중인':
      '마플샵 내부 규정 위반 신고 접수 시 해당 상품을 판매 중인 크리에이터에게는 요청 사유가 통지되며 신고 접수 비공개 스레드가 생성됩니다.',
    '셀러가 내부 규정 위반 사실이 없음을 증명할 수 있는 자료를 첨부한':
      '크리에이터가 내부 규정 위반 사실이 없음을 증명할 수 있는 자료를 첨부한 답변 등록 시 내부 심사 후 신고 해결 처리가 됩니다.',
    '14일 이내 답변 등록이 되지 않은 경우와 심사 후 규정 위반':
      '14일 이내 답변 등록이 되지 않은 경우와 심사 후 규정 위반 확인된 경우 샵 운영이 중지됩니다.',

    본인인증: '본인인증',
    '신고 접수 서비스는 무분별한 상품 판매 정지 또는 샵의 운영 정지를 방지하고':
      '신고 접수 서비스는 무분별한 상품 판매 정지 또는 샵의 운영 정지를 방지하고, 본인의 의사에 따른 진행을 확인하기 위해 본인 확인을 필요로 합니다.',
    '본인 명의로 가입된 휴대전화를 통해 본인 인증을 할 수 있습니다.':
      '본인 명의로 가입된 휴대전화를 통해 본인 인증을 할 수 있습니다.',
    '신고 접수 조회': '신고 접수 조회',
    '고객님의 신고 접수 요청 내역이 확인되지 않습니다.': '고객님의 신고 접수 요청 내역이 확인되지 않습니다.',
    '1. 신고 접수 유형 선택': '1. 신고 접수 유형 선택',
    '유형 사유/유형 별 요청 서식이 다르므로 정확하게 선택하지 않은 경우, 신고 접수가 진행되지 않습니다.':
      '유형 사유/유형 별 요청 서식이 다르므로 정확하게 선택하지 않은 경우, 신고 접수가 진행되지 않습니다.',
    '저작권침해 (고객님의 작품, 콘텐츠 등을 침해한 상품 관련)':
      '저작권침해 (고객님의 작품, 콘텐츠 등을 침해한 상품 관련)',
    '위조상품 판매 (상표권/디자인권 침해 상품 관련)': '위조상품 판매 (상표권/디자인권 침해 상품 관련)',
    '마플샵 내부 규정 위반 (연령제한, 타인 명의로 운영 중인 샵 관련)':
      '마플샵 내부 규정 위반 (연령제한, 타인 명의로 운영 중인 샵 관련)',
    '2. 권리 침해를 받은 당사자 선택': '2. 권리 침해를 받은 당사자 선택',
    '단체/개인에 따라 필요 서류가 다르므로 정확하게 선택하지 않은 경우, 신고 접수가 되지 않습니다.':
      '단체/개인에 따라 필요 서류가 다르므로 정확하게 선택하지 않은 경우, 신고 접수가 되지 않습니다.',
    '단체 (기업, 사업체, 종교단체, 학교 등)': '단체 (기업, 사업체, 종교단체, 학교 등)',
    '개인 (본인, 가족, 친척, 지인 등)': '개인 (본인, 가족, 친척, 지인 등)',
    '3. 필요 서류 안내 및 구비 여부 선택': '3. 필요 서류 안내 및 구비 여부 선택',
    '신고 접수 시 접수 유형과 당사자 선택에 따라 필요 서류가 달라지며 서류 구비가 되지 않은 경우, 신고 접수가 진행되지 않습니다.':
      '신고 접수 시 접수 유형과 당사자 선택에 따라 필요 서류가 달라지며 서류 구비가 되지 않은 경우, 신고 접수가 진행되지 않습니다.',
    '① 저작권 침해 (고객님의 작품, 컨텐츠 등을 침해한 상품 관련)':
      '① 저작권 침해 (고객님의 작품, 컨텐츠 등을 침해한 상품 관련)',
    '해당 셀러의 저작권 침해 사실을 증빙할 수 있는 자료 첨부':
      '해당 크리에이터의 저작권 침해 사실을 증빙할 수 있는 자료 첨부',
    '기업/단체인 경우 사업자등록증, 법인등록증 사본 1부 첨부':
      '기업/단체인 경우 사업자등록증, 법인등록증 사본 1부 첨부',
    '접수자가 단체 대표자가 아닌 경우, 단체 직인이나 대표자의 서명이 날인된 위임장 1부 첨부':
      '접수자가 단체 대표자가 아닌 경우, 단체 직인이나 대표자의 서명이 날인된 위임장 1부 첨부',
    '② 위조 상품 판매 (상표권/디자인권 침해 상품 관련)': '② 위조 상품 판매 (상표권/디자인권 침해 상품 관련)',
    '상표권/디자인권 및 해당 셀러의 침해 사실을 증빙할 수 있는 자료 첨부':
      '상표권/디자인권 및 해당 크리에이터의 침해 사실을 증빙할 수 있는 자료 첨부',
    '③ 마플샵 내부 규정 위반 (연령 제한, 타인 명의로 운영 중인 샵 관련)':
      '③ 마플샵 내부 규정 위반 (연령 제한, 타인 명의로 운영 중인 샵 관련)',
    '해당 셀러가 마플샵 내부 규정을 위반했음을 증빙할 수 있는 자료 첨부':
      '해당 크리에이터가 마플샵 내부 규정을 위반했음을 증빙할 수 있는 자료 첨부',
    '서류 구비 완료': '서류 구비 완료',
    '서류 구비가 되지 않음': '서류 구비가 되지 않음',
    '본인 인증 후 신고 접수하기': '본인 인증 후 신고 접수하기',
    '본인 인증 후 신고 조회하기': '본인 인증 후 신고 조회하기',
    '요청 처리 불가': '요청 처리 불가',
    '신고 접수 유형을 선택해 주세요.': '신고 접수 유형을 선택해 주세요.',
    '권리 침해를 받은 당사자를 선택해 주세요.': '권리 침해를 받은 당사자를 선택해 주세요.',
    '서류 구비가 되지 않으면 요청을 처리할 수 없습니다.':
      '서류 구비가 되지 않으면 요청을 처리할 수 없습니다. 필요한 서류를 준비하신 후 다시 접수 부탁드립니다.',
    '신고 접수 유형': '신고 접수 유형',
    '권리 침해를 받은 당사자': '권리 침해를 받은 당사자',
    '신고 접수자': '신고 접수자',
    '상표권/디자인권 보유자': '상표권/디자인권 보유자',
    '개인, 법인명, 단체이름 등을 입력해 주세요.': '개인, 법인명, 단체이름 등을 입력해 주세요.',
    'ⓘ 위의 이메일과 연락처':
      'ⓘ 위의 이메일과 연락처로 신고 진행 과정이 전송됩니다. 반드시 정확하게 작성 부탁드립니다.',
    '해당 샵 URL': '해당 샵 URL',
    '해당 상품 URL': '해당 상품 URL',
    '존재하지 않는 주소입니다.': '존재하지 않는 주소입니다.',
    '마플샵 주소를 다시 확인해 주세요.': '마플샵 {{type}}주소를 다시 확인해 주세요.',
    '신고 사유': '신고 사유',
    '원활한 신고 접수를 위하여 내용을 구체적으로 적어주세요.':
      '원활한 신고 접수를 위하여 내용을 구체적으로 적어주세요.',
    '필요 서류': '필요 서류',
    'ⓘ 상표권 / 디자인권':
      'ⓘ 상표권 / 디자인권 및 해당 크리에이터의 침해 사실을 증빙할 수 있는 자료를 첨부 부탁드립니다. (파일크기: 10MB 이하)',
    '인증 문제가 있습니다. 다시 시도 부탁드립니다.': '인증 문제가 있습니다. 다시 시도 부탁드립니다.',
    '단체인 경우 사업자등록증':
      '단체인 경우 사업자등록증, 법인등록증 등 증명이 될 수 있는 사본 1부 첨부 부탁 드립니다.',
    '신고 접수 후 내용을 수정할 수 없습니다.':
      '신고 접수 후 내용을 수정할 수 없습니다.<br>계속 진행하시겠습니까?',
    '신고 접수가 완료되었습니다.': '신고 접수가 완료되었습니다.',
    '스레드 생성이 완료 되었습니다.':
      '스레드 생성이 완료 되었습니다. 신고 접수 관련 크리에이터의 답변 및 회신은 스레드에서만 가능합니다.',
    '비공개 스레드 바로가기': '비공개 스레드 바로가기',
    '내부 규정 위반 여부 확인':
      '내부 규정 위반 여부 확인 후 처리 결과를 등록하신 email로 안내드릴 예정입니다. 감사합니다.',
    '스레드 종료': '스레드 종료',
    '재요청 기간이 몇일 남았습니다.': '재요청 기간이 {{- days}}일 남았습니다.',
    '답변 기간이 몇일 남았습니다.': '답변 기간이 {{- days}}일 남았습니다.',
    '신고 대상 셀러': '신고 대상 크리에이터',
    '신고 상세 내용': '신고 상세 내용',
    '답변 대기': '답변 대기',
    '판매 재개': '판매 재개',
    '판매 중지': '판매 중지',
    '내부 규정 위반 답변완료': '내부 규정 위반 답변완료',
    '샵 정상 운영': '샵 정상 운영',
    '샵 운영 중지': '샵 운영 중지',
    답변완료: '답변완료',
    '판매 재개/샵 정상 운영': '판매 재개/샵 정상 운영',
    '판매 중지/샵 운영 중지': '판매 중지/샵 운영 중지',

    '경찰청 사이버안전국': '경찰청 사이버안전국',
    '생년 월일': '생년 월일',
    연락처: '연락처',
    이메일: '이메일',
    '이메일을 입력해 주세요.': '이메일을 입력해 주세요.',

    '파일 첨부': '파일 첨부',
    '단체 증명 자료': '단체 증명 자료',
    스토어: '스토어',
    상품: '상품',
    '신고 접수에 대한 답변이 완료되었습니다.': '신고 접수에 대한 답변이 완료되었습니다.',
    '해당 상품 판매가 재개되었습니다.': '해당 상품 판매가 재개되었습니다.',
    '이의신청 내용을 검토하여 알려드리겠습니다.': '이의신청 내용을 검토하여 알려드리겠습니다.',
    '저작권 침해': '저작권 침해',
    '위조상품 판매': '위조상품 판매',
    개인: '개인',
    단체: '단체',
    '신고 조회하기': '신고 조회하기',
    '신고 접수는 PC에서만 가능합니다.': '신고 접수는 PC에서만 가능합니다.',
    한국저작권위원회: '한국저작권위원회',
    '1. 저작권침해': '1. 저작권침해',
    '저작물에 대한 정당한 권리가 있는 당사자(개인 및 단체) 및 무단 도용된 상품에 대한 원본 제작 당사자':
      '저작물에 대한 정당한 권리가 있는 당사자(개인 및 단체) 및 무단 도용된 상품에 대한 원본 제작 당사자',
    '2. 위조상품 판매': '2. 위조상품 판매',
    '판매 중인 상품이 상표권 또는 디자인권을 위반한 경우 해당 권리자 또는 권리자로부터 위임 받은 대리인':
      '판매 중인 상품이 상표권 또는 디자인권을 위반한 경우 해당 권리자 또는 권리자로부터 위임 받은 대리인',
    '3. 마플샵 내부 규정 위반': '3. 마플샵 내부 규정 위반',
    '만 14세 미만 미성년자 셀러, 저작권자가 아닌 타인의 명의로 운영 중인 셀러의 위반 사실을 입증할 수 있는 자':
      '만 14세 미만 미성년자 크리에이터, 저작권자가 아닌 타인의 명의로 운영 중인 크리에이터의 위반 사실을 입증할 수 있는 자',
    '4. 본인 인증': '4. 본인 인증',
  },

  about: {
    갤러리: '갤러리',
    '게시글이 없어요.': '게시글이 없어요.',
    '님의 갤러리를 기다려주세요.': '{{- store_name}}님의 갤러리를 기다려주세요.',
    '님, 갤러리를 등록해 보세요!': '{{- store_name}}님, 갤러리를 등록해 보세요!',
  },

  common: {
    lang: {
      kr: '한국어',
      en: 'English',
      jp: '日本語',
    },
    unit: {
      krw: '원화',
      usd: '달러',
      jpy: '엔화',
    },
    action: {
      save: '저장',
      do_save: '저장하기',
      cancel: '취소',
      do_cancel: '취소하기',
      regist: '등록',
    },
    reveal_type: '공개 여부',
    public: '공개',
    private: '비공개',
    all_cate: '전체 카테고리',
    seller: '크리에이터',
    save: '저장',
  },

  s_about: {
    edit_modal_title: '프로필 설정',
    sns_label: 'SNS',
    sns_holder: 'SNS URL을 입력해 주세요.',
    desc_label: '소개',
    desc_holder: '소개 내용을 입력해 주세요.',
    rep_label: '대표 이미지/영상',
    rep_image: '이미지 등록',
    rep_video: '영상 URL 등록',
    rep_video_holder: '유튜브, 트위치, 아프리카 영상 주소',
    rep_tip: 'ⓘ 대표 이미지 또는 대표 영상 중 하나만 등록이 가능합니다.',
    copy_link: '링크복사',
    url_copied: 'URL이 클립보드에 복사되었습니다.',
    url_copied2: '주소가 복사되었습니다. 원하는 곳에 붙여넣기 해주세요.',
    url_failed: 'URL 복사에 실패했습니다.',
    edit_profile: '프로필 편집',
    write: '게시물 등록',
    completed: '수정 되었습니다.',
    failed: '저장에 실패 했습니다. 다시 시도해 주세요.',
    wrong_url: '잘못된 주소 입니다.',
  },

  studio: {
    bank_account_required_product: '계좌정보를 등록해야 상품을 생성할 수 있습니다.',
    title: '마플샵 스튜디오',
    marpple_seller: '마플샵 크리에이터',
    type_mobile: '계정정보에서 전화번호를 입력해 주세요.',
    '아쉽게도 이번에는 마플샵 셀러로 모시지 못하게 되었습니다.':
      '아쉽게도 이번에는 마플샵 크리에이터로 모시지 못하게 되었습니다.&nbsp;<span class="m_br"></span>동일한 이메일로 재신청이 가능하오니, 포트폴리오 보강 후 재신청 부탁드립니다.',
    need_allow:
      '샵을 꾸미고 상품을 만들어보세요.&nbsp;<span class="m_br"></span>판매 승인 심사는 평일 영업일 기준 2~5일 소요됩니다.',
    possible_device_change: '기종 변경 가능',
    select_category: '카테고리를 선택해 주세요.',
    shop_admin: {
      발급: '발급',
      'API Key 발급': 'API Key 발급',
      'Key 발급일시': 'Key 발급일시',
      '연동 컨설팅사': '연동 컨설팅사',
      '외부 서비스와의 연동을 관리하는 페이지 입니다.': '외부 서비스와의 연동을 관리하는 페이지 입니다.',
      '복사되었습니다.': '복사되었습니다.',
      '약관 동의': '약관 동의',
      '약관 보기': '약관 보기',
      'REST API Key 발급이 완료되었습니다.': 'REST API Key 발급이 완료되었습니다.',
      'REST API Key 안내':
        '고객님께서 가입하신 연동 컨설팅사에<br />발급된 REST API Key를 복사하여 서비스 연동을<br />등록해 주시기 바랍니다.',
      '이미 발급되었습니다.': '이미 발급되었습니다.',
      '이미 연동되었습니다.': '이미 연동되었습니다.',
      '외부 서비스 연동은 PC에서만 확인 가능합니다.': '외부 서비스 연동은 PC에서만 확인 가능합니다.',
      'API Key 값이 외부로 유출되지 않도록 각별한 주의 바랍니다.':
        'API Key 값이 외부로 유출되지 않도록 각별한 주의 바랍니다.',
      '연동 컨설팅사 해제': '연동 컨설팅사 해제',
      '연동 컨설팅사와 연동을 해제하시겠습니까?': '연동 컨설팅사와 연동을 해제하시겠습니까?',
    },
    menus: {
      make_products: '굿즈 제작',
      products: '굿즈 만들기',
      orders: '굿즈 주문 내역',
      seller_product: '상품 판매',
      real_pb_products: '상품 등록',
      inventory_management: '재고 관리',
      pb_orders: '주문 관리',
      pb_item_orders: '배송 관리',
      pb_refund_orders: '환불 관리',
      digital_product: '디지털 상품 판매',
      make_digital_products: '디지털 상품 등록',
      question_list: '고객 문의 관리',
      shop_management: '샵 관리',
      styles: '샵 디자인',
      arrangement: '상품 정렬',
      shop_info: '샵 정보',
      ecommerce_info: '크리에이터 정보',
      open_api: '외부서비스 연동{{- br}}(API Key 발급)',
      adjustments: '정산 관리',
      design_adjustments: '굿즈 정산',
      seller_adjustments: '상품 정산',
      tpl_adjustments: '입고 상품 정산',
      dtg_adjustments: '디지털 상품 정산',
      withdraws: '출금 신청',
      bank_account: '계좌 정보 등록',
      analytics: '통계',
      analytics_by_prod: '상품별 판매 분석',
      my_shop: '내 샵 바로가기',
      youtube: '유튜브에서 판매',
      seller_guide: `크리에이터 가이드`,
      seller_tip: '크리에이터 팁',
      faq: 'FAQ',
      solution: '신고 접수',
      invite: '친구 초대하기',
      creator_community_club: '크리에이터스 커뮤니티 클럽',
      update_note: '업데이트 노트',
      invite_info: '크리에이터 초대',
      account: '계정정보',
      marketing: '마케팅',
      help: '고객센터',
      contact_marppleshop: '마플샵에 문의하기',
      mp_shop: '마플샵',
      logout: '로그아웃',
      designer: '디자이너',
      adobe_illustrator: `일러스트레이터 다운로드`,
    },
    footer: {
      privacy: '개인정보 처리방침',
      terms: '이용약관',
      p: '마플샵 콘텐츠의 저작권은 저작권자 또는 제공처에 있으며, 이를 무단 사용 및 도용하는 경우 저작권법 등에 따라 법적 책임을 질 수 있음을 알려 드립니다.',
    },
    form: {
      title1: '옵션 이름',
      title2: '추가 금액 (선택) 원화/달러/엔화',
    },
    shop_info: {
      p1: '현재 운영중인 샵의 기본 정보들을 관리하는 페이지 입니다.',
    },
    ecommerce_info: {
      p1: '크리에이터 배송 상품 판매를 위한 정보를 입력하는 페이지 입니다.',
    },
    styles: {
      p1: '프로필·로고·배너 등을 변경하여 샵을 디자인해보세요.',
      btn1: '샵 디자인 변경',
    },
    products: {
      p1: '단 3분이면 가능해요!',
      p2: '오늘은 어떤 상품을 만들어 볼까요?',
    },
    real_pb_products: {
      p1: '나만의 상품 등록 시작!',
      p2: '나만의 브랜드 상품을<br>등록해 볼까요?',
    },
    digital_products: {
      p1: '다양한 디지털 상품을 쉽고 빠르게 판매해 보세요!',
      p2: '나만의 디지털 상품을<br>만들어 볼까요?',
      p3: '판매할 디지털 상품을 선택해 주세요!',
      p4: '마플샵에서 다양한 디지털 상품을 쉽고 빠르게 판매할 수 있습니다.',
      cate_wall_paper: '배경화면',
      cate_voice_pack: '보이스팩',
      cate_good_note: '굿노트',
      cate_digital_asset: '디지털 에셋',
      header: '상품 등록하기',
      header_modify: '상품 수정하기',
      required: '표시는 필수로 입력해야 할 항목입니다.',
      no_image_info_title: '등록된 썸네일 이미지가 없습니다.',
      no_image_info_contents: `
      상품 썸네일 이미지로 등록할 이미지를 업로드해주세요.
    `,
      image_upload_btn: '썸네일 이미지 업로드',
      set_first: '대표 이미지로 지정',
      recommend_size: `이미지 권장 사이즈 : 정사각형 비율의 740px ~ 1480px /<span class="mobile_br"></span> 등록 가능 형식 : jpg, png / 용량 5MB 이하`,
      delete_image: '이미지 삭제',
      btn_register: '상품 등록하기',
    },
    make: {
      p1: '상품을 고르고 디자인해보세요.',
      p2: '고급 브랜드 제품으로, 최적의 인쇄 방식을 사용합니다.',
      p3: '다양한 카테고리가 준비되어 있어요.',
      p4: '마플샵은 지속적으로 업데이트 하고 있습니다.',
    },
    invite: {
      p1: '마플샵이 필요한 친구에게 크리에이터 초대장을 보내보세요.',
      p2: '두 분 모두에게 샘플 구매시에 사용할 수 있는 5,000 포인트를 드립니다.',
      p3: '{{name}}님께 초대장을 받은 분께는 신청 과정 없이 바로 샵을 열 수 있는 혜택과 샘플 구매에 쓸 수 있는 5,000 포인트를 드립니다.',
      p4: '초대 받으신 분께서 상품을 만들고 활동을 시작하면 {{name}}님께 감사의 마음을 담아 5,000 포인트를 드립니다.',
      p5: '현재 남은 초대장은 총 <span class="count">{{count}}</span>장 입니다.',
      p6: '초대하실 분 이름이나 닉네임을 적어주세요.',
      b1: '초대 완료',
      t1: '이메일로 초대하기',
      t2: '초대 링크 생성',
      l1: '초대장 보내기',
      l2: '초대 링크 생성',
      l3: '링크복사',
      '이메일 주소를 확인해 주세요.': '이메일 주소를 확인해 주세요.',
      '이메일 주소가 맞는지 확인해 주세요': '이메일 주소가 맞는지 확인해 주세요. 초대장을 보내시겠습니까?',
      '초대 링크를 생성하시겠습니까?': '{{name}} 님을 위한 초대 링크를 생성하시겠습니까?',
    },
    invite_info: {
      p1: '{{name}}님께서 당신을 마플샵 크리에이터 크리에이터로 초대하셨어요!',
      p2: '샵을 만들고 상품을 만드시면 인쇄 적합도, 저작권 이슈 등의 간단한 검토 후 빠르게 승인해드립니다.',
      p3: '{{name}}님의 초대 혜택으로 샘플 구매시 사용할 수 있는 5,000 포인트도 지급해드립니다.',
      p4: '꼭 확인해 주세요!<br />초대 받은신 크리에이터의 본격적인 활동을 위해서는 판매 승인이 필요합니다.<br />판매 승인은 샵 디자인와 상품을 만들어주신 후 기다려주시면 포인트지급 안내와 함께 문자로 안내드립니다.<br />(판매 승인은 영업일 기준 2일 ~ 4일 소요됩니다)',
      t1: '마플샵이 궁금한가요?',
      t2: '다른 크리에이터들의 마플샵이 궁금하신가요?',
      t3: '마플샵 오픈을 위해 아래 정보를 입력해 주세요.',
      l1: '마플샵 홈 보러가기',
      la1: '샵 이름',
      la2: '휴대전화번호',
      la3: '마플샵 도메인명 (영문/숫자 2자 이상 20자 이내)',
      la4: '대표 SNS url (선택)',
    },
    marketing: {
      p1: '네이버 쇼핑 노출 동의',
      agree: '동의',
      not_agree: '미동의',
      modify: '변경',
    },
    account: {
      p1: '크리에이터님의 계정 정보는 암호화되어 안전하게 저장됩니다.',
      email: '이메일',
      save_changes: '이메일 변경',
      pw: '비밀번호',
      reset_pw: '비밀번호 변경',
      name: '이름',
      brand_name: '브랜드 이름',
      phone: '휴대전화번호',
      store_url: '샵 URL',
      modify: '변경',
      e1: '유효한 도메인을 입력하세요.',
      my_point: '보유 포인트',
    },
    orders: {
      p1: '한 주간 발생한 주문 내역을 확인하실 수 있습니다.',
      t1: '주간 상품별 판매 분석',
      empty: '앗, 아직 주문 내역이 없습니다.',
    },
    analytics: {
      p1: '내가 만든 상품들의 판매량 순위를 확인해 보세요.',
    },
    table: {
      th1: '주문 기간',
      th2: '주문 수',
      th3: '주문 취소 수',
      th4: '수량',
      th5: '취소 수량',
      th6: '수익',
      th7: '추가 수익',
      th8: '총 수익',
      th9: '크리에이터 수익',
      th10: '기업 수익',
      th11: '순위',
      th12: '상품명',
      th13: '판매량',
      th14: '공개 상태',
      th15: '정산일',
      th16: '정산 금액',
      th16_1: 'PG사 수수료',
      th16_2: '마플샵 수수료',
      th16_3: '판매 수수료',
      th16_4: '입고 수수료',
      th17: '진행 상태',
      th18: '합계',
      th19: '유형',
      th20: '날짜',
      th21: '상점',
      th22: '디자인 수익',
      th23: '디자인 추가 수익',
      th24: '디자인 총 수익',
      th25: '크리에이터 디자인 수익',
      th26: '기업 디자인 수익',
      th27: '기업 할인 수익',
      th28: '기업 총 수익',
      th29: '총 출금액',
      th30: '신청 일시',
      th31: '출금 신청 금액',
      th32: '실 입금액',
      th33: '입금 예정일',
      th34: '입금 일시',
      th35: '취소일시',
      th36: '사유',
      th37: '처리 상태',
      th38: '금액',
      th39: '계좌정보',
      th40: '처리일시',
      th41: '기능',
      th42: '이름',
      th43: '상태',
      th44: '소비자판매금액',
    },
    adjustments: {
      long_format: 'yyyy년 M월',
      short_format: 'yyyy-MM',
      p1: '마플샵 상품의 정산 내역은 출고된 날짜를 기준으로 월별 산정됩니다.',
      p2: '상품의 정산 내역은 구매확정일을 기준으로 월별 산정됩니다.',
      btn1: '날짜 변경',
      release: '출고',
      confirmed: '구매확정',
      change: '변경',
      shipping: '배송비',
      release_date: '출고 날짜',
    },
    withdraws: {
      p1: '한 달간 발생한 판매 수익을 출금하실 수 있습니다.',
      p1_1: '누적 정산 금액을 출금 신청할 수 있습니다.',
      p2: '출금 가능 금액',
      p3: '기업 출금 신청 금액을 확인하고, 출금 신청해 주세요.',
      p4: '크리에이터 수익에 따른 출금 신청 금액을 확인하고, 정산을 진행해 주세요.',
      t1: '크리에이터 출금',
      btn1: '출금 신청',
      btn2: '수정',
      btn3: '처리',
      btn4: '승인처리',
      btn5: '엑셀 다운로드',
      btn6: '날짜 변경',
      finish: '완료',
      approve: '승인',
      cancel: '취소',
      pending: '처리 중',
      tt_title: '실 입금액',
      lb1: '총 입금액',
      lb2: '잔액',
      opt1: '검증 여부',
      opt2: '검증된 출금',
      opt3: '검증되지 않은 출금',
      opt4: '계좌유형',
    },
    arrangement: {
      only_pc: `상품정렬 기능은<br>PC에서만 가능합니다.`,
      desc: `내 샵에서 판매 중인 상품을 드래그하여 원하는 대로 상품 정렬을 변경해 보세요.`,
      desc2: `이곳에 상품을 드래그하여 보관했다가 다시 왼쪽의 샵 영역에 옮겨 이동하면 손쉽게 정렬을 변경할 수 있습니다.`,
    },
    tooltip: {
      h2: `
<h4>굿즈 정산</h4><div>출고 날짜 기준으로 월별 산정됩니다.</div><br/>
<h4>상품 정산{{tpl_adjustments}} & 디지털 상품 정산</h4><div>구매확정일을 기준으로 월별 산정됩니다.</div>`,
      '출고 날짜': '출고 날짜',
      t1: '일주일간의 판매 수량에 따라 제작비를 할인해 추가 수익을 드립니다. 디자인 가격 설정 시, 해당 제품의 추가 수익금이 자동으로 산출됩니다.',

      t2: `<h4>굿즈 정산</h4><div>디자인 가격 + 추가 수익 - PG사 수수료(3.52%) = 정산금액<br/>사업자의 경우 세금계산서 발행 후에 출금 가능합니다.</div>`,
      t3: `수익 - 마플샵 수수료 - PG사 수수료(3.52%) = 정산 금액`,
      t3_1: `수익 - 입고 수수료 - PG사 수수료(3.52%) = 정산 금액`,
      t3_2: `수익 - 판매 수수료 - PG사 수수료(3.52%) = 정산 금액`,
      t4: `일주일간의 판매 수량에 따라 제작비가 할인됩니다.<br/>내 수익 설정 시 1개당 제작비 할인 금액과 내 수익이 자동으로 도표에 산출됩니다.`,
      t5: '상품을 한정적으로 판매하길 원하는 경우 설정합니다. 체크박스를 선택하지 않으면, 상시 판매가 됩니다.<br/>한정 수량과 기간을 설정할 수 있으며, 설정할 경우 한정판 제품 굿즈로서 상품 희소성을 높일 수 있습니다.',
      'PG사 수수료': 'PG사 수수료',
      'PG사 수수료 3.2%에 10% 부가가치세를 더한 가격입니다.':
        'PG사 수수료 3.2%에 10% 부가가치세를 더한 가격입니다.',
      '셀러 상품 정산': '상품 정산',
      t6: `<div>굿즈 계산 금액 + 상품 정산 금액 = 출금 예정 금액</div><br/><h4>굿즈 계산 금액이란?</h4><br/><div>- 개인 크리에이터<div>굿즈 정산 금액 - 부가세(10%) - 원천징수세(3.3%)<br />= 굿즈 계산 금액</div><br/>- 사업자 크리에이터<div>굿즈 정산 금액 = 굿즈 계산 금액<br/>※사업자의 경우 세금계산서 발행 후에 출금 가능합니다.</div></div>`,
    },
    youtube: {
      '유튜브에서 판매': '유튜브에서 판매',
      '유튜브에서도 마플샵 상품을 판매해 보세요.': '유튜브에서도 마플샵 상품을 판매해 보세요.',
      '유튜브 채널 ID를 입력해 주세요.': '유튜브 채널 ID를 입력해 주세요.',
      '이미 등록된 유튜브 채널 ID 입니다.': '이미 등록된 유튜브 채널 ID 입니다.',
      '올바른 채널 ID를 입력해 주세요.': '올바른 채널 ID를 입력해 주세요.',
      '새로고침 후 다시 시도해 주세요.': '새로고침 후 다시 시도해 주세요.',
      '유튜브 스튜디오에서 상품을 공개해 주세요.': '유튜브 스튜디오에서 상품을 공개해 주세요.',
      '채널 ID 확인': '채널 ID 확인',
      '채널 ID 삭제': '채널 ID 삭제',
      '저장되었습니다.': '저장되었습니다.',
      '유튜브 상품 기능 사용 조건을 확인해 주세요.': '유튜브 상품 기능 사용 조건을 확인해 주세요.',
      '만 18세 이상 & 구독자 수 1,000명 이상이어야 합니다.':
        '만 18세 이상 & 구독자 수 1,000명 이상이어야 합니다.',
      'delete-confirm': '1~3일 후 유튜브 계정 연결이 해제됩니다. 그래도 채널 ID를 삭제하시겠습니까?',
      '셀러 스튜디오에 공개 중인 상품만 연동 프로세스가 진행됩니다.':
        '마플샵 스튜디오에 공개 중인 상품만 연동 프로세스가 진행됩니다.',
      '유튜브 팀에서 1~3일간 1차 심사를 완료하면 유튜브 스튜디오에서 상품을 확인할 수 있습니다.':
        '유튜브 팀에서 1~3일간 1차 심사를 완료하면 유튜브 스튜디오에서 상품을 확인할 수 있습니다.',
      'info-2':
        '내 영상 하단, 스토어 탭, 실시간 방송 등에 상품을 게시할 수 있습니다.{{- br}}유튜브 팀에서 1~5일간의 2차 심사를 완료하면 상품이 시청자들에게 공개되고 판매가 시작됩니다.',
      'correct-info': '{{- check}}에서 확인하실 수 있습니다.',
      'YouTube 스튜디오 > 수익 창출 > 쇼핑에서 스토어 연결을 눌러 MarppleShop과 YouTube 계정을 연결해 주세요.': `'YouTube 스튜디오 > 수익 창출 > 쇼핑에서 '스토어 연결'을 눌러 MarppleShop과 YouTube 계정을 연결해 주세요.`,
      'YouTube 스튜디오에서 노출하고 싶은 상품을 선택해 주세요': `YouTube 스튜디오에서 노출하고 싶은 상품을 선택해 주세요`,
      '채널에 제품 표시에 있는 메뉴를 눌러서 채널에 표시하고 싶은 상품을 선택해 주세요': `'채널에 제품 표시' 있는 메뉴를 눌러서 채널에 표시하고 싶은 상품을 선택해 주세요`,
      '(설정에 따라 하단에 표시되는 메뉴가 다를 수 있습니다)':
        '(설정에 따라 하단에 표시되는 메뉴가 다를 수 있습니다)',
      '채널에 제품 표시에 있는 토글을 활성화 해주셔야 합니다.':
        '채널에 제품 표시에 있는 토글을 활성화 해주셔야 합니다.',
      '토글을 끄면 상품이 보이지 않습니다.': `토글을 끄면 상품이 보이지 않습니다.`,
      '왼쪽에서 표시하기를 원하는 상품을 골라 오른쪽으로 드래그 해주세요.': `왼쪽에서 표시하기를 원하는 상품을 골라 오른쪽으로 드래그 해주세요.`,
      '상품은 총 30개까지 채널에 노출할 수 있습니다.': `상품은 총 30개까지 채널에 노출할 수 있습니다.`,
      '채널 연동 후 1~3일 정도 지나면 YouTube 스튜디오 > 수익 창출 > 쇼핑에서 마플샵 상품을 확인하실 수 있습니다.': `채널 연동 후 1~3일 정도 지나면 YouTube 스튜디오 > 수익 창출 > 쇼핑에서 마플샵 상품을 확인하실 수 있습니다.`,
      '※ 유의사항': `※ 유의사항`,
      '유튜브 상품 연동을 위해서 마플샵 상품을 공개 상태로 설정해주세요.': `유튜브 상품 연동을 위해서 마플샵 상품을 공개 상태로 설정해주세요.`,
      '비공개 상품의 경우 유튜브 채널에 노출되지 않습니다.': `비공개 상품의 경우 유튜브 채널에 노출되지 않습니다.`,
      '상품이 품절이 되어도 유튜브 채널에 노출되지 않습니다.': `상품이 품절이 되어도 유튜브 채널에 노출되지 않습니다.`,
      '영상 별로 노출하고 싶은 상품을 고를 수 있습니다.': `영상 별로 노출하고 싶은 상품을 고를 수 있습니다.`,
      'YouTube 영상을 업로드 할 때 YouTube 스튜디오→콘텐츠→세부 정보→수익창출에서 노출하고 싶은 상품을 선택할 수 있습니다.': `YouTube 영상을 업로드 할 때 YouTube 스튜디오→콘텐츠→세부 정보→수익창출에서 노출하고 싶은 상품을 선택할 수 있습니다.`,
      '먼저 채널 콘텐츠의 수익 창출에서 쇼핑 탭을 눌러주세요. 이곳에서 어떤 상품을 노출할지 선택할 수 있습니다.': `먼저 채널 콘텐츠의 수익 창출에서 쇼핑 탭을 눌러주세요. 이곳에서 어떤 상품을 노출할지 선택할 수 있습니다.`,
      'a. 맞춤 설정 : 영상 별로 노출하고 싶은 상품을 고르고 싶을 때 사용합니다.': `a. 맞춤 설정 : 영상 별로 노출하고 싶은 상품을 고르고 싶을 때 사용합니다.`,
      'b. 채널 선택사항 사용 : 채널에서 노출하고 있는 상품 설정 그대로 사용합니다.': `b. 채널 선택사항 사용 : 채널에서 노출하고 있는 상품 설정 그대로 사용합니다.`,
      'c. 제품 세션 토글 : 토글을 끄면 해당 영상에서만 상품이 보이지 않습니다.': `c. 제품 세션 토글 : 토글을 끄면 해당 영상에서만 상품이 보이지 않습니다.`,
      'YouTube Shopping 기능 자세히 알아보기': `YouTube Shopping 기능 자세히 알아보기`,
      '이미 등록 되어 있는 채널입니다. 연결된 샵에서 연동 해제 후 다시 등록해주세요.':
        '이미 등록 되어 있는 채널입니다. 연결된 샵에서 연동 해제 후 다시 등록해주세요.',
    },
    pc_info: {
      '셀러 스튜디오를 PC에서 이용해 보세요': '마플샵 스튜디오를 PC에서 이용해 보세요 !',
      '스마트폰뿐 아니라 PC에서도 손쉽게 이용 가능해요 !':
        '스마트폰뿐 아니라 PC에서도<br/>손쉽게 이용 가능해요 !',
    },
    seller_info: {
      certification: '본인 인증',
      seller_info_01: '개인 크리에이터 정보',
    },
  },

  unsupported_browser: {
    title_for_ie: 'Internet Explorer<br/>지원 중단 안내',
    description_for_ie:
      'Microsoft에서 인터넷 익스플로러의 보안 업데이트 지원을 중단함에 따라 악성코드 및 내 PC의 중요 정보 노출 등 보안에 취약해질 수 있어요. 빠르고 안전한 최신 브라우저를 사용해 보세요.',
    description_for_another_browser:
      'Internet Explorer를 사용하고 있지 않음에도 이 페이지를 보신다면 브라우저를 최신 버전으로 업데이트해 주세요.',
    recommended_pc_browser: 'PC 권장 브라우저',
    recommended_mobile_browser: '스마트폰에서 바로 이용해 보세요.',
    description_for_mobile_phone: {
      creator:
        "크롬,사파리 등 모바일에서 '마플샵’으로 검색하거나 주소창에 https://marpple.shop/ 을 입력해 주세요.",
      mp: "크롬,사파리 등 모바일에서 '마플’으로 검색하거나 주소창에 https://www.marpple.com/ 을 입력해 주세요.",
      line: "크롬,사파리 등 모바일에서 '라인프렌즈 크리에이터’으로 검색하거나 주소창에 https://creator.linefriends.com/ 을 입력해 주세요.",
    },
    description_to_using_app: {
      creator: '크리에이터 굿즈샵,마플샵 앱을 이용해 보세요.',
      mp: '내 손안의 프린팅, 마플 앱을 이용해 보세요.',
    },
  },

  mp_app: {
    android_fb_login:
      '안드로이드 앱에서 "페이스북 로그인"이 일시적으로 지원되지 않습니다.<br>웹브라우저에서 "페이스북 로그인"을 시도해주세요.',
  },

  maker_text_editor: {
    '문자 간격': '문자 간격',
    '텍스트 입력': '텍스트 입력',
    텍스트: '텍스트',
    '서체 선택': '서체 선택',
    '글씨 색상': '글씨 색상',
    폰트: '폰트',
    '텍스트 편집': '텍스트 편집',
  },

  maker_color_editor: {
    색상: '색상',
  },
  maker_path_editor: {
    패스: '패스',
    '패스 편집': '패스 편집',
    '도형 편집': '도형 편집',
  },
  maker_border_editor: {
    두께: '두께',
    테두리: '테두리',
    '테두리 색상': '테두리 색상',
    점선: '점선',
  },

  maker_background_color_editor: {
    '배경 색상': '배경 색상',
  },

  maker_error_alert: {
    '사진 권한 엑세스':
      '어플리케이션 설정에서 사진 접근을 허용해주세요.<br>같은 문제가 계속 발생시, 웹 브라우저를 이용해주세요.',
    '문제가 발생했습니다. 다시 시도해 주세요.': '문제가 발생했습니다. 다시 시도해 주세요.',
    '문제가 발생했습니다. 디자인을 다시 확인해주세요.': '문제가 발생했습니다. 디자인을 다시 확인해주세요.',
    'PNG 이미지를 해석할 수 없습니다.': 'PNG 이미지를 해석할 수 없습니다.',
    'SVG 파일을 해석할 수 없습니다.': 'SVG 파일을 해석할 수 없습니다.',
    'SVG 객체를 찾을 수 없습니다.': 'SVG 객체를 찾을 수 없습니다.',
    '이미지를 그리는 데 실패했습니다.': '이미지를 그리는 데 실패했습니다.',
    fail_upload_img: '이미지를 업로드하는 데 실패했습니다. 다시 시도해 주세요.',
    too_large_file: '파일 용량이 너무 큽니다.<br>{{mb}}MB 이하로 업로드해 주세요.',
    too_small_img: '좋은 품질로 인쇄하기 위해서는<br>최소 {{px}}px 이상의 이미지가 필요합니다.',
    too_large_img: '{{px}}px 이하의 이미지로 변경하여 업로드해 주세요.',
    too_large_img2: '가로 * 세로가 {{px}}px 이하의 이미지로 변경하여 업로드해 주세요.',
    '앗! 죄송합니다. 다시 시도해 주세요.': '앗! 죄송합니다. 다시 시도해 주세요.',
    '이 기능이 지원되지 않는 상품입니다.': '이 기능이 지원되지 않는 상품입니다.',
    '앗! 제품을 만들지 않으셨어요.': '앗! 제품을 만들지 않으셨어요. 😂',
    '임시 저장된 디자인을 불러오겠습니까?': '임시 저장된 디자인을 불러오겠습니까?',
    '크기에 맞춰 가격이 책정되었어요.': '크기에 맞춰 가격이 책정되었어요.',
    '선택된 색상으로 패턴을 만들수 없습니다.': '선택된 색상으로 패턴을 만들수 없습니다.',
    '확인했습니다.': '확인했습니다.',
    '점선 밖으로 디자인을 위치 시킬수 없습니다.': '점선 밖으로 디자인을 위치 시킬수 없습니다.',
    '패치 위에 다른 다지인을 올릴 수 없습니다.': '패치 위에 다른 다지인을 올릴 수 없습니다.',
    '특수 색상 글씨 위에 다른 다지인을 올릴 수 없습니다.':
      '특수 색상 글씨 위에 다른 다지인을 올릴 수 없습니다.',
    'This is a temporary error. please try again.': '일시적인 오류입니다.<br />다시 시도해주세요.',
    'We are very sorry, but there is a problem with loading the chosen design. It would be appreciated if you redesign the item and order again.':
      '문제가 발생했습니다. 다시 디자인하여 주문해 주시면 감사겠습니다.',
    'The selected background color is removed.': '선택된 배경색은 지워집니다.',
    'Pattern is not supported for this product. Pattern image is automatically removed.':
      '이 제품에는 패턴 기능을 사용 할수 없습니다.<br/>패턴 기능이 사용된 이미지는 지워집니다.',
    '시스템 글꼴이 만들기툴에 영향을 줌': `고객님 브라우저의 글꼴이 제품의 폰트 디자인에 영향을 줍니다.<br>폰트의 경우 화면과 다르게 제작 될 수 있으니, {{browsers}}을 이용해 주세요.`,
    '시스템 글꼴이 마플샵 고객 뷰에 영향을 줌': `이 상품은 {{browsers}}에서만 구매할 수 있습니다.<br>{{browsers}}에서 확인 후 구매 해주세요.`,
    '시스템 글꼴이 마플 고객 뷰에 영향을 줌': `고객님 브라우저의 글꼴이 폰트 디자인에 영향을 줍니다.<br>폰트의 경우 화면과 다르게 제작 될 수 있으니, {{browsers}}을 이용하여 다시 디자인 해주세요.`,
    '품절되었습니다.': '품절되었습니다.',
    '해당 상품은 태블릿에서 모바일 화면으로만 작업이 가능합니다.':
      '해당 상품은 태블릿에서 모바일 화면으로만 작업이 가능합니다.<br>모바일 화면으로 전환됩니다.',
    'PC 화면으로 전환됩니다.': 'PC 화면으로 전환됩니다.',
    '정보가 변경되어 새로고침 합니다.': '정보가 변경되어 새로고침 합니다.',
    '해당 글자를 제공하지 않는 폰트입니다.': '해당 글자를 제공하지 않는 폰트입니다.',
    '자수 글자수 제한': '이 상품은 최대 {{char_length_in_line}}자,<br>두 줄까지 자수인쇄가 가능합니다.',
    '화면을 크게해서 다시 시도 해주세요.': '화면을 크게해서 다시 시도 해주세요.',
  },
  error_alert: {
    reload: '문제가 발생하여 새로고침됩니다.<br>계속 발생시 고객센터로 문의 주세요.',
  },
  maker_upload_type: {
    '만들기 툴로 쉽게 제작': '<div>만들기 툴로 쉽게 제작</div>',
    '디자인 파일 업로드': '<div>디자인 파일 업로드</div>',
    '내 디자인 업로드하기': '<div>내 디자인</div><div>업로드하기</div>',
    '디자인 업로드': '디자인 업로드',
    '내 디자인 파일로 만드는 방법': '내 디자인 파일로 만드는 방법',
    '전문가용 제작하기': '전문가용 제작하기',
    '템플릿 다운로드': '템플릿 다운로드',
    '템플릿 다운로드 / 도안 업로드': '템플릿 다운로드 / 도안 업로드',
    '도안 업로드': '도안 업로드',
    '일러스트레이터 버전': '일러스트레이터 2021 버전 이상에서만 사용 가능',
    '첨부 파일 형식: SVG (10MB 이하)': '첨부 파일 형식: SVG (10MB 이하)',
    '위 템플릿 파일의 가이드를 준수하여 디자인 작업해 주세요.':
      '위 템플릿 파일의 가이드를 준수하여 디자인 작업해 주세요.',
    '템플릿을 다운로드하시고 도안을 제작해 나만의 아크릴 키링을 만들어 보세요.': `템플릿을 다운로드하시고<br />
    도안을 제작해 나만의 아크릴 키링을 만들어 보세요.`,
    '템플릿을 다운로드하시고 도안을 제작해 나만의 아크릴 스탠드를 만들어 보세요.': `템플릿을 다운로드하시고<br />
    도안을 제작해 나만의 아크릴 스탠드를 만들어 보세요.`,
    '커스텀하는 방법': '커스텀하는 방법',
    '전문가용 에디터를 활용해 제작한 도안은 PC에서 확인하실 수 있어요.':
      '전문가용 에디터를 활용해 제작한 도안은 PC에서 확인하실 수 있어요.',
    '템플릿을 다운로드하시고 직접 나만의 아크릴 키링을 만들어 보세요.': `템플릿을 다운로드하시고<br />
    직접 나만의 아크릴 키링을 만들어 보세요.`,
    '템플릿을 다운로드하시고 직접 나만의 아크릴 스탠드를 만들어 보세요.': `템플릿을 다운로드하시고<br />
    직접 나만의 아크릴 스탠드를 만들어 보세요.`,
    maker_upload_type_01: '전문가용 에디터로 만든 상품은<br>PC에서 확인해 주세요.',
    maker_upload_type_02: '해당 상품은 전문가용 에디터로 만든 상품입니다.<br>PC에서 수정하실 수 있습니다.',
    '템플릿은 일러스트레이터 CS6 이상에서만 사용 가능합니다.':
      '템플릿은 일러스트레이터 CS6 이상에서만 사용 가능합니다.',
    '첨부 파일 형식 : PDF (100MB 이하)': '첨부 파일 형식 : PDF (100MB 이하)',
    '템플릿 파일의 가이드를 준수하여 디자인 작업해 주세요.':
      '템플릿 파일의 가이드를 준수하여 디자인 작업해 주세요.',
    '만들기 툴은 곧 제공 예정이에요!': '',
  },
  maker_basic_word: {
    사이즈: '사이즈',
    가격: '가격',
    '스탠드 포함': '스탠드 포함',
  },

  maker_unlock_editor: {
    Unlock: 'Unlock',
  },

  maker_upload_image_editor: {
    '이미지 업로드': '이미지 업로드',
    '업로드 가능한 이미지 파일은 JPEG 또는 PNG, SVG 파일이며 25MB 이하입니다.':
      '업로드 가능한 이미지 파일은 JPEG 또는 PNG, SVG 파일이며 25MB 이하입니다.',
    '제품의 색상과 디자인 시안 색상은 모니터 사양이나 컬러모드 (CMYK/RGB)에 따라 차이가 발생할 수 있으며 시안과 실제 상품의 인쇄 위치 및 크기의 오차가 발생할 수 있습니다. 이미지를 업로드하면 저작원에 대한 모든 권리와 책임이 있음을 인정하는 것입니다. 타인의 저작권 또는 개인정보 보호 권한을 침해하지 않음을 확인합니다.':
      '제품의 색상과 디자인 시안 색상은 모니터 사양이나 컬러모드 (CMYK/RGB)에 따라 차이가 발생할 수 있으며 시안과 실제 상품의 인쇄 위치 및 크기의 오차가 발생할 수 있습니다. 이미지를 업로드하면 저작원에 대한 모든 권리와 책임이 있음을 인정하는 것입니다. 타인의 저작권 또는 개인정보 보호 권한을 침해하지 않음을 확인합니다.',
    '이미지 올리기': '이미지 올리기',
    '내 이미지 보기': '내 이미지 보기',
  },

  marpple_shop_detail_alert: {
    '컬러 없음': '앗! 이 기종에는 선택하신 컬러가 없습니다. 😂',
  },

  marpple_shop_select_page: {
    'Please select at least one thumbnail.': '썸네일을 1개 이상 선택해주세요.',
    'Please select 5 or fewer thumbnails.': '썸네일은 5개까지 선택 가능합니다.',
    'Thumbnails are being created.': '썸네일 제작 중입니다.',
    'Please, wait for a moment.': '잠시만 기다려 주세요.',
  },

  tokenGate: {
    서명: '서명',
    tg001: '인증이 만료되었습니다.',
    tg002: '위 NFT가 모두 필요합니다.',
    tg003: '{{storeName}}으로 돌아가 상품을 구매해보세요!',
    tg004: '이 서명은 상품을 구매하기 위한 인증 절차이며,',
    tg005: '어떠한 비용도 발생하지 않습니다.',
    tg006: '위 NFT 중 1개 이상의 NFT 가 필요합니다.',
    tg007:
      '<strong>Android 설정 > 애플리케이션 > 기본 앱 선택 > 애플리케이션 > 링크 열기 > MetaMask</strong><br>1. 지원되는 링크 열기 on<br>2. 지원되는 웹주소(특히 metamask.app.link)를 선택합니다. 설정을 완료하였습니까?',

    tg009: 'NFT 인증하고 구매하기',
    tg010: 'NFT 인증하기',
    tg010_1: 'NFT 인증',
    tg011: '홀더만 구매 가능',
    tg012: '이미 등록된 주소입니다.',
    tg013: '주소를 확인해주세요.',
    tg014: '컨트랙트 주소를 추가하면 해당 NFT의 소유자(홀더)만 상품을 구매 할 수 있습니다.',
    tg015: '모두 소유',
    tg016: '하나만 소유',
    tg017: '토큰 게이팅 설정',
    tg018:
      'NFT 컨트랙트 주소를 입력하면 해당 NFT의 소유자(홀더)만 상품을 구매 할 수 있게 설정 할 수 있습니다.<br>현재 이더리움과 폴리곤 체인을 지원하고 있습니다.',
  },

  youtube: {
    '유튜브 쇼핑 공식 플랫폼 마플샵': '유튜브 쇼핑 공식 플랫폼, 마플샵',
    '마플샵과 함께 유튜브 쇼핑을 시작하세요. 유튜브 영상, 쇼츠, 라이브에서 내 상품 또는 브랜드 제품을 전시하고 판매할 수 있습니다. 상품 제작부터, 배송, CS까지 원스톱 커머스 솔루션을 제공합니다.':
      '마플샵과 함께 유튜브 쇼핑을 시작하세요. 유튜브 영상, 쇼츠, 라이브에서 내 상품 또는 브랜드 제품을 전시하고 판매할 수 있습니다. 상품 제작부터, 배송, CS까지 원스톱 커머스 솔루션을 제공합니다.',
    '유튜브 쇼핑 플랫폼 마플샵': '유튜브 쇼핑 플랫폼 마플샵',
    '마플샵과 유튜브 쇼핑을 연동하면 유튜브 영상, 쇼츠, 라이브 방송에 내 상품 또는 브랜드 제품을 노출할 수 있습니다. 크리에이터 굿즈 제작부터 배송, CS까지 쇼핑몰 운영의 모든 것을 도와드립니다.':
      '마플샵과 유튜브 쇼핑을 연동하면 유튜브 영상, 쇼츠, 라이브 방송에 내 상품 또는 브랜드 제품을 노출할 수 있습니다. 크리에이터 굿즈 제작부터 배송, CS까지 쇼핑몰 운영의 모든 것을 도와드립니다.',
    '유튜브 스튜디오에서 약관을 동의한 후<br />마플샵으로 돌아와 연동을 마무리해 주세요.':
      '유튜브 스튜디오에서 약관을 동의한 후<br />마플샵으로 돌아와 연동을 마무리해 주세요.',
    '자격 요건이 충족되어야 연동할 수 있어요.': '자격 요건이 충족되어야 연동할 수 있어요.',
    '약관 동의하러 가기': '약관 동의하러 가기',
  },

  cart_modal: {
    quantity: '수량',
    일시품절: '일시품절',
  },

  question: {
    '문의 접수 완료': '문의 접수 완료',
    '답변은 평일 영업일 기준 1일 ~ 3일 소요됩니다.': `답변은 평일 영업일 기준 1일 ~ 3일 소요됩니다.<br>작성한 문의 내용은 마이페이지 &gt; 문의 내역에서<br>확인 가능합니다.`,
    '문의 내역 바로가기': '문의 내역 바로가기',
  },
  checkout: {
    delivery_fee_error1_title: '상품 확인 필요',
    delivery_fee_error1: '배송 가능한 무게를 초과하였습니다.',
    delivery_fee_error2_title: '배송비 계산 불가',
    delivery_fee_error2: '고객센터로 문의해 주세요.',
    'Please enter the shipping address first.': 'Please enter the shipping address first.',
    Checkout: '',
  },
  checkout_placeholder: {
    'Postal Code': '',
  },
  global_port_one: {
    Failed: 'Payment has failed. {{error_msg}}',
    Expired: 'The payment time has expired. {{error_msg}}',
    EtcError: `Your payment request failed. {{ error_msg }}`,
    error_01:
      'The card information is not valid. Additionally, only credit cards (except those issued in Korea) are accepted.',
    error_02: 'The card you entered is either invalid or not supported.',
    error_03: '',
    error_04: '',
    error_05: '',
    error_06: '',
  },
  payment: {
    error1: '결제에 실패 했습니다.',
    error2: '이미 취소된 주문서입니다.',
  },
  refund_bank: {
    '01': '환불 받으실 계좌를 입력해 주세요.',
    '02': '환불 받으실 계좌 번호',
    '03': '예금주명을 정확히 입력해 주세요.',
    '04': '취소 요청 하기',
    '05': '환불 계좌 입력',
    '06': '항목을 모두 채워주세요.',
  },
  prompt_modal: {
    cancel: '취소',
    done: '완료',
  },
};
